import ZodForm from "react-zod-form"
import { coerce, z } from "zod"

import { POSIntegration } from "@/entities/pos/pos.types"
import { POSField, POSInfoFields, POSName } from "@/entities/pos/pos.types"

export const posInfoText: POSInfoFields = {
  Oracle: {
    posName: "The name of the POS system you are integrating with.",
    clientId: "The Client ID provided by Oracle POS. This is a unique identifier for your application to authenticate and interact with the Oracle POS API.",
    authUrl: "The URL endpoint used for authentication with Oracle POS. This URL is necessary to obtain access tokens required for API requests.",
    baseUrl: "The base URL of the Oracle POS API. All API requests will be appended to this URL to interact with the POS system.",
    username: "Your Oracle POS username. This is used along with the password to authenticate API requests.",
    password: "Your Oracle POS password. It is used with the username to securely access the POS API.",
    orgShortName: "The short name of your organization as registered with Oracle POS. This helps identify the organization within the POS system.",
    locRef: "The reference code for the specific location within your organization. This ensures that the API requests target the correct physical location.",
    rvcRef: "The reference code for the revenue center within the location. It is used to specify which part of the location's operations the data pertains to.",
    displayName: "The name that will be displayed for the POS system within your application. This should be user-friendly and easily recognizable.",
    displayPriceName: "The name to be used for displaying price-related information from the POS system. This should be clear and understandable for users interacting with the pricing data.",
  },
  TableNeeds: {
    posName: "The name of the POS system you are integrating with.",
    clientId: "The Client ID provided by TableNeeds POS. This unique identifier allows your application to authenticate and interact with the TableNeeds POS API.",
    url: "The URL endpoint of the TableNeeds POS API. This base URL is necessary for making API requests to the POS system.",
    token: "The API token provided by TableNeeds POS. This token is used to authenticate and authorize API requests to ensure secure interaction with the POS system.",
  },
  CBSNorthStar: {
    posName: "The name of the POS system you are integrating with.",
    clientId: "The Client ID provided by CBSNorthStar POS. This is a unique identifier that allows your application to authenticate and interact with the CBSNorthStar POS API.",
    access_key: "The access key provided by CBSNorthStar POS. This key is used to authenticate and authorize API requests, ensuring secure communication with the POS system.",
    site_id: "The Site ID provided by CBSNorthStar POS, which is the same as the Client ID. This identifier is used to specify the particular site or location within your organization.",
    base_url: "The base URL of the CBSNorthStar POS API. All API requests will be appended to this URL to interact with the POS system.",
  },
}

export const posFields: Record<POSField, (keyof typeof POSName)[]> = {
  authUrl: ["Oracle"],
  baseUrl: ["Oracle"],
  username: ["Oracle"],
  password: ["Oracle"],
  orgShortName: ["Oracle"],
  locRef: ["Oracle"],
  rvcRef: ["Oracle"],
  displayName: ["Oracle"],
  displayPriceName: ["Oracle"],
  url: ["TableNeeds"],
  token: ["TableNeeds"],
  access_key: ["CBSNorthStar"],
  site_id: ["CBSNorthStar"],
  base_url: ["CBSNorthStar"],
}

export const posFieldEntries = Object.entries(posFields) as [POSField, (keyof typeof POSName)[]][]
export const posFieldPlaceholders: Record<POSField, string> = {
  baseUrl: "POS Base URL",
  authUrl: "POS Authentication URL",
  username: "POS Username",
  password: "POS Password",
  orgShortName: "POS Organization Short Name",
  locRef: "POS Location Reference",
  rvcRef: "POS Revenue Center Reference",
  displayName: "POS Display Name",
  displayPriceName: "POS Display Price Name",
  url: "POS API Url",
  token: "POS API Token",
  access_key: "POS Access Key",
  site_id: "POS Site Id",
  base_url: "POS Base URL",
}

export function parsePosName(value: string | null): POSName | null {
  const matchKey = Object.keys(POSName).find((enumKey) => {
    return enumKey.toLowerCase() === value?.toLowerCase()
  })
  return matchKey ? POSName[matchKey as keyof typeof POSName] : null
}

export const form = new ZodForm({
  name: z.nativeEnum(POSName),
  clientId: coerce.string().min(1, "Must contain at least 1 character"),
  config: z.object({
    baseUrl: coerce.string().min(1, "Must contain at least 1 character").optional(),
    authUrl: coerce.string().min(1, "Must contain at least 1 character").optional(),
    username: coerce.string().min(1, "Must contain at least 1 character").optional(),
    password: coerce.string().min(1, "Must contain at least 1 character").optional(),
    orgShortName: coerce.string().min(1, "Must contain at least 1 character").optional(),
    locRef: coerce.string().min(1, "Must contain at least 1 character").optional(),
    rvcRef: coerce.string().min(1, "Must contain at least 1 character").optional(),
    url: coerce.string().min(1, "Must contain at least 1 character").optional(),
    token: coerce.string().min(1, "Must contain at least 1 character").optional(),
    displayName: coerce.string().min(1, "Must contain at least 1 character").optional(),
    displayPriceName: coerce.string().min(1, "Must contain at least 1 character").optional(),
    access_key: coerce.string().min(1, "Must contain at least 1 character").optional(),
    site_id: coerce.string().min(1, "Must contain at least 1 character").optional(),
    base_url: coerce.string().min(1, "Must contain at least 1 character").optional(),
  })
})
form.object._type satisfies POSIntegration
