import "./Tooltip.scss"

import { useState } from "react"

interface TooltipProps {
  text: string,
  boxSize?: number
  children: React.ReactNode
}

function Tooltip ({ text, children, boxSize }: TooltipProps) {
  const [visible, setVisible] = useState(false)

  const handleMouseEnter = () => {
    setVisible(true)
  }

  const handleMouseLeave = () => {
    setVisible(false)
  }

  return (
    <div className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {visible && (
        <div className="tooltip-content"
          style={{ width: boxSize ? `${boxSize}em` : "30em" }}
        >
          {text}
        </div>
      )}
    </div>
  )
}

export default Tooltip
