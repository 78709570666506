import { useState } from "react"
import { useSearchParams } from "react-router-dom"

import AuthBoundary from "@/app/boundaries/AuthBoundary"
import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import UserBoundary from "@/app/boundaries/UserBoundary"
import PageLayout from "@/app/layouts/PageLayout/PageLayout"
import { AuditLogContainer, AuditLogFilters } from "@/areas/audit-log"
import { AuditLogFiltersDTO } from "@/entities/audit-log/audit-log.dto"
import { UserRole } from "@/entities/user"
import Pagination from "@/shared/components/extrinsic/Pagination/Pagination"
import usePagedControl from "@/utils/hooks/usePagedControl"
import { assignSearchParams, objectFromSearchParams } from "@/utils/search-params"

function parseDateSearchParams() {
  const params = objectFromSearchParams();

  ["fromDate", "toDate"].forEach(key => {
    if (params[key]) {
      const dateValue = new Date(params[key] as string)
      if (!isNaN(dateValue.getTime())) {
        params[key] = dateValue
      } else {
        delete params[key] // Remove invalid date from the object
      }
    }
  })

  return params
}

function AuditLogPage() {
  const pagedControl = usePagedControl(1, 50)

  const [filters, setFilters] = useState<Partial<AuditLogFiltersDTO>>(parseDateSearchParams)
  const [searchParams, setSearchParams] = useSearchParams()

  function onFiltersChange(filters: Partial<AuditLogFiltersDTO>) {
    setFilters(filters)
    pagedControl.setPage(1)

    assignSearchParams(searchParams, filters)
    setSearchParams(searchParams)
  }

  return (
    <AuthBoundary>
      <UserBoundary minRole={UserRole.StreamManager} fallback="Not enough permission">
        <PageLayout>
          <h2>Audit Log</h2>
          <AuditLogFilters default={filters} onSubmit={onFiltersChange} />
          <Pagination control={pagedControl} />
          <DefaultBoundaries>
            <AuditLogContainer filters={filters} pagedControl={pagedControl} />
          </DefaultBoundaries>
          <Pagination control={pagedControl} />
        </PageLayout>
      </UserBoundary>
    </AuthBoundary>
  )
}

export default AuditLogPage
