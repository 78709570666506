import { FormEvent, useState } from "react"
import { useModalWindow } from "react-modal-global"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { coerce, z } from "zod"

import { CompanyUpdateDTO } from "@/entities/company/company.dto"
import { CompanyBase } from "@/entities/company/company.types"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import EditableAvatar from "@/shared/components/extrinsic/EditableAvatar/EditableAvatar"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Fields from "@/shared/layouts/Fields/Fields"
import useFormStatus from "@/utils/hooks/useFormStatus"
import Bytes from "@/utils/transform/Bytes"

const form = new ZodForm({
  companyName: coerce.string().min(1, "Must contain at least 1 character"),
  companyLogo: z.instanceof(File).optional()
})
form.object._type satisfies CompanyUpdateDTO

interface PopupEditCompanyProps {
  company: CompanyBase

  onSubmit?(fields: CompanyUpdateDTO): Promise<void> | void
}

function PopupEditCompany(props: PopupEditCompanyProps) {
  const modal = useModalWindow()
  const { fieldIssues } = useZodFormIssues(form)

  const formStatus = useFormStatus(form, onSubmit)

  const [isChanged, setIsChanged] = useState(false)
  const [oldComapnyData, setOldComapnyData] = useState(()=>({"logo":props.company.logo,"title": props.company.title}))

  function onChanges(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (fields == null) return
  
    const isCompanyNameChanged = fields.companyName ? fields.companyName !== oldComapnyData.title : false
    const isCompanyLogoChanged = fields.companyLogo ? fields.companyLogo.name !== oldComapnyData.logo : false
  
    setIsChanged(isCompanyNameChanged || isCompanyLogoChanged)
  }

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (fields == null) return

    await props.onSubmit?.(fields)
    setIsChanged(false)
    setOldComapnyData(
      (prevState)=>{
        return {...prevState,
          logo: fields.companyLogo?.name,
          title: fields.companyName
        }
      })

    modal.close()
  }

  return (
    <PopupLayout title="Updating the company" width="50em">
      <form onChange={onChanges} onSubmit={formStatus.submit}>
        <Fields>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <EditableAvatar contain name={form.fields.companyLogo} defaultImage={props.company.logo} maxSize={Bytes.MB(10)} />
          </div>
          <Field name={form.fields.companyName} placeholder="Company Name" defaultValue={props.company.title} customValidity={fieldIssues.companyName} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button type="submit" color="white" {...formStatus} disabled={!isChanged}>Update</Button>
          </div>
        </Fields>
      </form>
    </PopupLayout>
  )
}

export default PopupEditCompany
