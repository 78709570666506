import { StreamAPI } from "@/api/stable/APIStable"

import { FontsFindSeekDTO } from "./fonts.dto"
import { FontEntity } from "./types"

import DAO from "../dao"



const DEFAULT_PAGE_SIZE = -1 // All.

class FontsDAO extends DAO {
  async findAll(seek: FontsFindSeekDTO): Promise<FontEntity[]> {
    const page = seek?.page ?? 1
    const pageSize = seek?.pageSize ?? DEFAULT_PAGE_SIZE

    const response = await StreamAPI.fetch.GET["/font"]({
      queryParams: {
        page,
        limit: pageSize,

        name: seek?.family,
        companyId: seek.companyId
      }
    })

    const entities: FontEntity[] = response.payload.map(font => ({
      id: font._id,
      uniqueId: font._id,

      family: font.name,
      styles: font.subFonts.map(style => style.fontSubfamily),
      styleUrls: font.subFonts.reduce((result, style) => result.set(style.fontSubfamily, style.url.url), new Map)
    }))
    return entities
  }
}

export default FontsDAO
