import { UserDao } from "@/entities/user"

import ResetPasswordForm from "./ResetPasswordForm"

function ResetPasswordFormContainer() {
  async function onSubmit(email: string) {
    await UserDao.sendPasswordRecoveryEmail(email)
  }

  return (
    <ResetPasswordForm onSubmit={onSubmit} />
  )
}

export default ResetPasswordFormContainer
