import "./LocationViews.scss"

import { ReactNode } from "react"

interface LocationViewsProps {
  children: ReactNode
}

function LocationViews(props: LocationViewsProps) {
  return (
    <div className="location-views">{props.children}</div>
  )
}

export default LocationViews
