import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import { ProfileGeneralContainer, ProfilePeopleContainer, ProfileSettingsLayout } from "@/areas/profile"
import useParam from "@/utils/hooks/useParam"

function CompanySettings() {
  const companyId = useParam("id")

  return (
    <ProfileSettingsLayout title='Profile Settings'>
      <DefaultBoundaries>
        <ProfileGeneralContainer id={companyId} />
      </DefaultBoundaries>
      <DefaultBoundaries>
        <ProfilePeopleContainer companyId={companyId} title='People' />
      </DefaultBoundaries>
    </ProfileSettingsLayout>
  )
}

export default CompanySettings
