import { useState } from "react"

interface UseAsyncCallState {
  error: Error | null
  pending: boolean
}
type UseAsyncCallAction = (callback: () => void) => Promise<void>
type UseAsyncCallReturn = [UseAsyncCallState, UseAsyncCallAction]

/**
 * Put an async call into boundary by exposing `pending` and `error` state preventing side-effects.
 */
function useAsyncBoundary(): UseAsyncCallReturn {
  const [error, setError] = useState<Error | null>(null)
  const [pending, setPending] = useState(false)

  async function asyncBoundary(callback: () => void) {
    try {
      setPending(true)
      await callback()
    } catch (error) {
      if (error instanceof Error) {
        setError(error)
      }

      throw error
    } finally {
      setPending(false)
    }
  }

  return [{ error, pending }, asyncBoundary] as UseAsyncCallReturn
}

export default useAsyncBoundary
