import { MutableRefObject, Ref } from "react"

/**
 * - https://github.com/facebook/react/issues/17200
 * - https://github.com/smooth-code/react-merge-refs
 */
export function combineRefs<T>(refs: (Ref<T> | undefined | null)[]) {
  return (element: T) => {
    refs.forEach(ref => {
      if (ref == null) return
      if (typeof ref === "function") {
        return ref(element)
      }

      (ref as MutableRefObject<T>).current = element
    })
  }
}

export function triggerReactInput(input: HTMLInputElement | HTMLTextAreaElement, field: "value" | "files", value: unknown) {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, field)?.set
  nativeInputValueSetter?.call(input, value)

  input.dispatchEvent(new Event("change", { bubbles: true }))
}
