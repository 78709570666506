import "./FileSelector.scss"

import { ChangeEvent, useEffect, useRef, useState } from "react"

import { classWithModifiers } from "@/utils/common"
import { triggerReactInput } from "@/utils/react"

import { FileSelectorBaseProps } from "./types"

import Icon from "../Icon/Icon"

interface FileSelectorProps extends FileSelectorBaseProps {
  value?: File
  defaultValue?: File
  onChange?(value: File | undefined): void

  customValidity?: string
}

function FileSelector(props: FileSelectorProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | undefined>(props.defaultValue)

  useEffect(() => {
    if (props.value === file) return
    setFile(props.value)
  }, [props.value])

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const target = event.currentTarget

    const file = target.files?.[0]
    if (file == null) return

    setFile(file)
    props.onChange?.(file)
  }

  function onRemove() {
    setFile(undefined)
    props.onChange?.(undefined)

    if (inputRef.current == null) return
    triggerReactInput(inputRef.current, "value", "")
  }

  return (
    <div className={classWithModifiers("file-selector", props.disabled && "disabled")}>
      {props.label && (
        <div className="file-selector__label">{props.label}</div>
      )}
      <label className="file-selector__container">
        <div className={classWithModifiers("file-selector__appearance", !!file && "filled")}>{file?.name ?? props.placeholder ?? "Choose file"}</div>
        <input className="file-selector__input" type="file" accept={props.accept} name={props.name} onChange={onChange} ref={inputRef} />
        {props.clearable && (
          <button className="file-selector-empty" type="button" onClick={onRemove}>
            <Icon className="file-selector-empty__icon" name="trash-bin" />
          </button>
        )}
      </label>
      <span className="file-selector__validity">{props.customValidity}</span>
    </div>
  )
}

export default FileSelector
