import "./UserWidget.scss"

import { Link } from "@/app/AppRouter"
import { User } from "@/entities/user"
import ColoredLetter from "@/shared/components/extrinsic/ColoredLetter/ColoredLetter"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"

interface UserWidgetProps {
  user: User
  onExit?(): void
}

function UserWidget(props: UserWidgetProps) {
  return (
    <div className="user-widget">
      <div className="user-widget__profile">
        {props.user.avatar == null && (
          <div className="user-widget__letter">
            <ColoredLetter letter={props.user.firstName[0]} />
          </div>
        )}
        {props.user.avatar != null && (
          <img className="user-widget__avatar" src={props.user.avatar} alt="avatar" />
        )}
        <div className="user-widget__info">
          <div className="user-widget__name">{props.user.firstName} {props.user.lastName[0].toUpperCase()}.</div>
          <div className="user-widget__email">{props.user.email}</div>
        </div>
        <Link className="ghost" to="/user/profile/settings" />
      </div>
      <div className="user-widget__exit">
        <ButtonIcon color="transparent" name="exit" ariaLabel="Exit" onClick={props.onExit} />
      </div>
    </div>
  )
}

export default UserWidget
