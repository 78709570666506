import { Path, useLocation } from "react-router-dom"

function useRedirection(): { path: Path } | null {
  const location = useLocation()
  const redirect = location.state?.redirect
  if (redirect == null) return null

  return { path: redirect }
}

export default useRedirection
