import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import { ProfilePeopleContainer,ProfileSettingsLayout } from "@/areas/profile"

function StreamSettings() {
  return (
    <ProfileSettingsLayout title='Stream Settings' isStreamSettings>
      <DefaultBoundaries>
        <ProfilePeopleContainer title='Stream Memebers' />
      </DefaultBoundaries>

      {/* TODO: Tickets Notification */}
    </ProfileSettingsLayout>
  )
}

export default StreamSettings
