import "./Header.scss"

import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import { UserWidgetContainer } from "@/areas/auth"
import { CompanyHeadingContainer } from "@/areas/company"

import Logo from "../Logo/Logo"

interface HeaderCompanyProps {
  id: string
}

function HeaderCompany(props: HeaderCompanyProps) {
  return (
    <header className="header">
      <Logo />
      <DefaultBoundaries small white>
        <CompanyHeadingContainer id={props.id} />
      </DefaultBoundaries>
      <DefaultBoundaries small><UserWidgetContainer /></DefaultBoundaries>
    </header>
  )
}

export default HeaderCompany
