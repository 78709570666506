import { Buffer } from "buffer"
import { uniqBy } from "lodash"

import { isRecord } from "../common"
import { URLDataBase64 } from "../types"

export interface ImageResolution {
  width: number
  height: number
}

class FileUtils {
  static toURLData(file: Blob): Promise<URLDataBase64> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = () => {
        if (reader.result === null) {
          throw new Error("File reading resulted in null.")
        }

        if (reader.result instanceof ArrayBuffer) {
          throw new Error("ArrayBuffer suddenly appeared as a result of file reading.")
        }

        resolve(reader.result as URLDataBase64)
      }
      reader.onerror = reject
    })
  }

  static toFormData(file: File, fieldName: string): FormData {
    const formData = new FormData
    formData.append(fieldName, file)

    return formData
  }

  /**
   * https://stackoverflow.com/a/61321728/12468111
   */
  static parseDataURI(dataURI: string): File {
    const splitDataURI = dataURI.split(",")

    const mimeString = splitDataURI[0].split(":")[1].split(";")[0]

    const buffer = Buffer.from(splitDataURI[1], "base64")

    return new File([buffer], "file." + mimeString.split("/")[1], { type: mimeString })
  }


  public static async fromExternalURL(url: URL | string): Promise<File> {
    function getFileNameFromHeaders(headers: Headers): string | null {
      const header = headers.get("Content-Disposition")
      if (header == null) return null

      const matchArray = header.match(/filename="(.*?)"/)
      if (matchArray == null) return null

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_match, group1] = matchArray
      return group1
    }

    function getFileNameFromURL(url: string): string | null {
      const lastSlashIndex = url.lastIndexOf("/")
      if (lastSlashIndex < 0) return null

      return url.slice(lastSlashIndex + 1)
    }

    const fileResponse = await fetch(url)
    const fileBlob = await fileResponse.blob()

    const fileName = getFileNameFromHeaders(fileResponse.headers) ?? getFileNameFromURL(url.toString()) ?? "can't be inferred"
    const fileOptions: FilePropertyBag = {
      type: fileResponse.headers.get("Content-Type") ?? ""
    }

    const file = new File([fileBlob], fileName, fileOptions)
    return file
  }

  static toFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer()

    files.forEach(file => dataTransfer.items.add(file))

    return dataTransfer.files
  }

  static getMetaId(file: File): string {
    return `${file.lastModified}-${file.size}-${file.name}`
  }

  static dedupeByMeta(files: File[]): File[] {
    return uniqBy(files, FileUtils.getMetaId)
  }

  static async getImageResolution(file: File): Promise<ImageResolution> {
    return new Promise(resolve => {
      const filePreview = URL.createObjectURL(file)

      const imageElement = new Image
      imageElement.onload = () => {
        resolve({
          height: imageElement.height,
          width: imageElement.width
        })

        URL.revokeObjectURL(filePreview)
      }
      imageElement.src = filePreview
    })
  }

  /**
   * Create a new file with a name set to its checksum hash.
   */
  static async renameToHash(file: Blob): Promise<File> {
    const fileHash = await FileHash.toSHA1(file)
    return new File([file], fileHash, file)
  }

  static async resolveURLs<T>(data: unknown): Promise<T> {
    if (isRecord(data)) {
      return Object.fromEntries(await Promise.all(Object.entries(data).map(async ([key, value]) => [key, await FileUtils.resolveURLs(value)])))
    }
    if (data instanceof Array) return await Promise.all(data.map(FileUtils.resolveURLs)) as never
    if (typeof data === "string") {
      if (data.startsWith("//") || data.startsWith("http")) {
        try { new URL(data) } catch (_) { return data as never }
        return await FileUtils.fromExternalURL(data) as never
      }
    }

    return data as never
  }
}

export default FileUtils

export class FileHash {
  private static readonly cache: WeakMap<Blob, string> = new WeakMap

  /**
   * @see https://techoverflow.net/2021/11/26/how-to-compute-sha-hash-of-local-file-in-javascript-using-subtlecrypto-api/
   */
  public static async toSHA1(file: Blob): Promise<string> {
    const hashCached = FileHash.cache.get(file)
    if (hashCached != null) return hashCached

    const fileArrayBuffer = await file.arrayBuffer()
    const hashArrayBuffer = await crypto.subtle.digest("SHA-1", fileArrayBuffer)

    const hash = FileHash.bufferToHash(hashArrayBuffer)
    FileHash.cache.set(file, hash)

    return hash
  }

  public static async toSHA1Map<T extends Blob>(files: T[]): Promise<Map<string, T>> {
    const entries = files.map(async file => [await FileHash.toSHA1(file), file] as const)
    return new Map(await Promise.all(entries))
  }

  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
   */
  public static bufferToHash(hashArrayBuffer: ArrayBuffer): string {
    const hashArray = [...new Uint8Array(hashArrayBuffer)]
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("") // convert bytes to hex string

    return hashHex
  }
}
