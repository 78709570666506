import "./Expander.scss"

import { ReactNode, useEffect, useMemo, useRef, useState } from "react"

import { classMerge, classWithModifiers } from "@/utils/common"

interface ExpanderProps {
  className?: string
  noTransition?: boolean

  expanded: boolean
  children: ReactNode
}

function Expander(props: ExpanderProps) {
  const containerElementRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number | undefined>()
  const [transitionEndState, setTransitionEndState] = useState(false)

  /**
   * Defines if the `children` will be rendered in the DOM.
   */
  const visible = useMemo(() => props.expanded || transitionEndState, [props.expanded, transitionEndState])

  useEffect(() => {
    if (containerElementRef.current == null) return

    const minimalHeight = Math.min(containerElementRef.current.scrollHeight, containerElementRef.current.clientHeight, containerElementRef.current.offsetHeight)
    setHeight(minimalHeight)
  }, [props.expanded, props.children])

  function onTransitionEnd() {
    setTransitionEndState(props.expanded)
    // When it's expanded it's put to `true`, so it will be still visible after the transition ends;
    // When it's not expanded it's put to `false` so it will become invisible only after the transition ends.
  }

  return (
    <div className={classWithModifiers("expander", props.expanded && "expanded", props.noTransition && "no-transition")} aria-hidden={!props.expanded} style={{ "--height": height }} onTransitionEnd={onTransitionEnd}>
      <div className={classMerge("expander__inner", props.className)}>
        <div className="expander__container">
          <div ref={containerElementRef}>{visible && props.children}</div>
        </div>
      </div>
    </div>
  )
}

export default Expander
