import { ReactNode, Suspense } from "react"

import LoaderCover from "@/shared/components/extrinsic/Loader/LoaderCover"

import ErrorBoundary from "./ErrorBoundary/ErrorBoundary"

import { ClientErrorFallback } from "../errors"
import ClientErrorInlineFallback from "../errors/components/ClientErrorInline/ClientErrorInlineFallback"

interface DefaultBoundariesProps {
  children: ReactNode

  small?: boolean
  white?: boolean
  deps?: unknown[]
}

function DefaultBoundaries(props: DefaultBoundariesProps) {
  return (
    <Suspense fallback={<LoaderCover white={props.white} />}>
      <ErrorBoundary fallback={props.small ? ClientErrorInlineFallback : ClientErrorFallback} deps={props.deps}>
        {props.children}
      </ErrorBoundary>
    </Suspense>
  )
}

export default DefaultBoundaries
