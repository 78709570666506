import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query"
import { useMemo, useState } from "react"

import { Selectable } from "@/app/types"
import CompanyDao from "@/entities/company/company.dao"
import { CompanyPeopleCreateDTO, CompanyPeopleUpdateDTO } from "@/entities/company/people/company-people.dto"
import { UserDao } from "@/entities/user"
import { UserRole } from "@/entities/user"
import Modal from "@/services/Modal/Modal"
import Button from "@/shared/components/intrinsic/Button/Button"
import { useAppSelector } from "@/store/hooks"
import { addOrRemove } from "@/utils/common"
import { hierarchySet } from "@/utils/hierarchy"

import ProfilePeopleTable from "../components/ProfilePeopleTable/ProfilePeopleTable"
import ProfileSettingsGroup from "../components/ProfileSettingsGroup/ProfileSettingsGroup"
import PopupConfirmManagerDelete from "../modals/PopupConfirmManagerDelete"
import PopupInvitesManagement from "../modals/PopupInvitesManagement/PopupInvitesManagement"


interface ProfilePeopleContainerProps {
  companyId?: string;
  title: string;
}

function ProfilePeopleContainer(props: ProfilePeopleContainerProps) {
  const queryClient = useQueryClient()
  const user = useAppSelector((state) => state.user)

  const { data: people } = useSuspenseQuery({
    queryFn: () => CompanyDao.people.findAll(props.companyId ?? ""),
    queryKey: [CompanyDao.people.name, props.companyId ?? "", CompanyDao.people.findAll.name]
  })

  const { data: users } = useSuspenseQuery({
    queryFn: () => user.role > UserRole.CompanyOwner ? UserDao.findStreamMembers() : [],
    queryKey: [UserDao.name, UserDao.findStreamMembers.name]
  })

  const [{ selectedIds, selectedItems, allSelected, selectAll, unselectAll }, selectable] = useSelectable(props.companyId ? people : users)

  function invalidateQuery () {
    if (!props.companyId) {
      queryClient.invalidateQueries({ queryKey: [UserDao.name, UserDao.findStreamMembers.name] })
    } else {
      queryClient.invalidateQueries({ queryKey: [CompanyDao.people.name, props.companyId, CompanyDao.people.findAll.name] })
    }
  }

  async function onCreateBulk(dtos: CompanyPeopleCreateDTO[]) {
    if (!props.companyId) await UserDao.createBulk(dtos)
    else await CompanyDao.people.createBulk(props.companyId, dtos)

    invalidateQuery()
  }

  async function onUpdate(userId: string, dto: CompanyPeopleUpdateDTO) {
    await CompanyDao.people.updateById(userId, dto)
    invalidateQuery()
  }
  async function onDeleteSelected() {
    await CompanyDao.people.deleteBulk(selectedIds)
    invalidateQuery()
    unselectAll()
  }
  async function onRestore(userId: string) {
    await CompanyDao.people.restoreById(userId)
    invalidateQuery()
  }

  function onInvite() {
    Modal.open(PopupInvitesManagement, {
      onSubmit: onCreateBulk,
      isStreamSettings: !props.companyId,
      companyId: props.companyId
    })
  }

  const heading = (
    <>
      <h4>{props.title}</h4>
      {user.role > UserRole.CompanyManager && 
      <Button color="green" size="small" iconLeft="plus" onClick={onInvite}>Invite</Button>
      }
    </>
  )

  
  const orderedUsers = users.sort(hierarchySet)
  const orderedPeople = people.sort(hierarchySet)

  return (
    <ProfileSettingsGroup
      heading={heading}
      selectedAll={allSelected}
      onSelectAll={selectAll}
      onDeleteSelected={() => Modal.open(PopupConfirmManagerDelete, { people: selectedItems, onSubmit: onDeleteSelected })}
    >
      <ProfilePeopleTable people={props.companyId ? orderedPeople : orderedUsers} onRestore={onRestore} onUpdate={onUpdate} {...selectable} />
    </ProfileSettingsGroup>
  )
}

export default ProfilePeopleContainer


interface UseSelectableState<T extends { id: string }> {
  allSelected: boolean
  noSelected: boolean

  selectedIds: T["id"][]
  selectedItems: T[]
}

interface UseSelectableActions<T extends { id: string }> {
  select(id: T["id"]): void
  selectAll(): void
  unselectAll(): void
}

function useSelectable<T extends { id: string, role:UserRole }>(items: T[]): [UseSelectableState<T> & UseSelectableActions<T>, Selectable<T["id"]>] {
  const [selectedIds, setSelectedIds] = useState<T["id"][]>([])

  const user = useAppSelector(state => state.user)
  const selectedItems = useMemo(() => items.filter(item => selectedIds.includes(item.id) && user.role > item.role), [items, selectedIds])
  const allSelected = useMemo(() => items.length > 0 && items.length === selectedIds.length, [items, selectedIds])
  const noSelected = useMemo(() => items.length > 0 && selectedIds.length === 0, [items, selectedIds])

  function select(id: string) {
    setSelectedIds(addOrRemove(selectedIds, id))
  }
  function selectAll() {
    if (allSelected) {
      setSelectedIds([])
      return
    }

    setSelectedIds(items.map(invite => invite.id))
  }

  function unselectAll() {
    setSelectedIds([])
  }

  const state: UseSelectableState<T> = {
    allSelected,
    noSelected,

    selectedIds,
    selectedItems,
  }
  const actions = {
    select,
    selectAll,
    unselectAll
  }

  const selectable: Selectable<T["id"]> = {
    selectedIds,
    onSelect: setSelectedIds
  }

  return [{ ...state, ...actions }, selectable] as const
}
