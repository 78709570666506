import { useSuspenseQuery } from "@tanstack/react-query"

import CompanyDao from "@/entities/company/company.dao"

import CompanyHeading from "./CompanyHeading"

interface CompanyHeadingContainerProps {
  id: string
}

function CompanyHeadingContainer(props: CompanyHeadingContainerProps) {
  const { data: company } = useSuspenseQuery({
    queryFn: () => CompanyDao.find(props.id),
    queryKey: [CompanyDao.name, props.id]
  })

  return (
    <CompanyHeading {...company} />
  )
}

export default CompanyHeadingContainer
