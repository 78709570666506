import { DependencyList, useEffect, useState } from "react"
import { Observable } from "react-use/lib/useObservable"

function useResource<T>(factory: () => Disposable & Observable<T>, deps: DependencyList, initialValue: T): T {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    const observableDisposable = factory()
    const subscription = observableDisposable.subscribe(setValue)

    return () => {
      subscription.unsubscribe()
      observableDisposable[Symbol.dispose]()
    }
  }, deps)

  return value
}

export default useResource
