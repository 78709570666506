import { useModalWindow } from "react-modal-global"

import { CompanyPerson } from "@/entities/company/people/company-people.types"
import PopupConfirmLayout from "@/services/Modal/layouts/PopupConfirmLayout/PopupConfirmLayout"
import PersonList from "@/shared/components/extrinsic/PersonList/PersonList"
import Button from "@/shared/components/intrinsic/Button/Button"


interface PopupConfirmManagerDeleteProps {
  people: CompanyPerson[]
  onSubmit?(): Promise<void> | void
}

function PopupConfirmManagerDelete(props: PopupConfirmManagerDeleteProps) {
  const modal = useModalWindow()
  return (
    <PopupConfirmLayout
      title="Deleting a manager"
      description="Are you sure you want to delete the account of the manager:"
      button={<Button color="red" disabled={props.people.length === 0} await onClick={() => {
        props.onSubmit?.()
        modal.close()
      }}>Confirm deleting</Button>}
    >
      <PersonList people={props.people} />
    </PopupConfirmLayout>
  )
}

export default PopupConfirmManagerDelete
