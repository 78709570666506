import "./PasswordField.scss"

import { useState } from "react"

import { Themeable } from "@/app/types"
import { FieldProps } from "@/shared/components/intrinsic/Field/Field"
import { toggleState } from "@/utils/common"

import PasswordFieldView from "./PasswordFieldView"

interface PasswordFieldProps extends FieldProps, Themeable { }

function PasswordField(props: PasswordFieldProps) {
  const [hidden, setHidden] = useState(true)

  return (
    <PasswordFieldView {...props} hidden={hidden} onHiddenToggle={toggleState(setHidden)} />
  )
}

export default PasswordField
