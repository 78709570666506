import "./AuditLogActionLabel.scss"

import { AuditLogAction } from "@/entities/audit-log/audit-log.types"
import { classWithModifiers } from "@/utils/common"

interface AuditLogActionLabelProps {
  action: AuditLogAction
}

function AuditLogActionLabel(props: AuditLogActionLabelProps) {
  return (
    <div className={classWithModifiers("audit-log-action-label", props.action)}>{props.action}</div>
  )
}

export default AuditLogActionLabel
