import { UserDao } from "@/entities/user"
import { useAppSelector } from "@/store/hooks"
import useRedirect from "@/utils/hooks/useRedirect"

import UserWidget from "./UserWidget"

function UserWidgetContainer() {
  const user = useAppSelector(state => state.user)
  const redirect = useRedirect()

  async function onExit() {
    await UserDao.logOut()
    redirect("/user/login")
  }

  if (!user.signed) {
    return null
  }

  return (
    <UserWidget user={user} onExit={onExit} />
  )
}

export default UserWidgetContainer
