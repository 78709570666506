import { useSuspenseQuery } from "@tanstack/react-query"
import { pick } from "lodash"

import { MenuAppInstance } from "@/entities/app/menu/menu.types"
import DesignDAO from "@/entities/design/design.dao"
import { DesignFindFiltersDTO } from "@/entities/design/design.dto"
import Notice from "@/shared/components/extrinsic/Notice/Notice"
import useSearch from "@/utils/hooks/useSearch"

import MenuDesignPreviews, { MenuDesignPreviewsProps } from "./MenuDesignPreviews"



const designDao = new DesignDAO


interface MenuDesignPreviewsContainerProps extends Pick<MenuDesignPreviewsProps, "selected" | "onSelect"> {
  app: MenuAppInstance
  filters?: DesignFindFiltersDTO
}

function MenuDesignPreviewsContainer(props: MenuDesignPreviewsContainerProps) {
  const seek = { ...props.filters, ...pick(props.app, "locationId", "companyId") }
  const search = useSearch(seek.name)

  const { data: designs } = useSuspenseQuery({
    queryFn: () => designDao.findAll(seek),
    queryKey: [designDao, seek]
  })

  if (designs.length === 0) {
    return <Notice icon="info">You do not have any designs yet!</Notice>
  }

  return (
    <MenuDesignPreviews companyId={props.app.companyId} designs={designs} selected={props.selected} onSelect={props.onSelect} highlight={search.highlight} />
  )
}

export default MenuDesignPreviewsContainer
