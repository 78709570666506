import AuthBoundary from "@/app/boundaries/AuthBoundary"
import { AuthorizationForm } from "@/areas/auth"

export default function authorize() {
  return (
    <AuthBoundary redirect>
      <AuthorizationForm />
    </AuthBoundary>
  )
}
