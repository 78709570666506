import fonts from "@streamllc/shared/fonts"
import { Helmet } from "react-helmet"

import Selector from "@/shared/components/intrinsic/Selector/Selector"

interface FontFamilySelectorProps {
  name?: string
  width?: string

  value?: string
  defaultValue?: string

  onChange?(value: string): void
}

/**
 * Note that this component inflicts input blocking time until all fonts are loaded.
 *
 * [Font loading optimization](https://www.viget.com/articles/performance-loading-font-picker-previews/)
 */
function FontFamilySelector(props: FontFamilySelectorProps) {
  return (
    <>
      <Helmet>
        <link href={fontsSource} rel="stylesheet" />
      </Helmet>
      <Selector {...props} label="Font" placeholder="Please, select">
        {fonts.map((font, index) => (
          <option value={font} key={index}>
            <span style={{ fontFamily: font }}>{font}</span>
          </option>
        ))}
      </Selector>
    </>
  )
}

export default FontFamilySelector

const fontsSource = `https://fonts.googleapis.com/css2?family=${fonts.map(font => font.replace(" ", "+")).join("&family=")}&display=swap`
