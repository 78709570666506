import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Notice from "@/shared/components/extrinsic/Notice/Notice"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

import DesignsPublishPNG from "./designs-publish.png"

function WikiFigmaDesignPublish() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Figma Plugin | Design Publishing</h2>
      </TextBox>
      <Article>
        <p>By selecting one or several design frames, you can publish (save) them at once.</p>
        <img src={DesignsPublishPNG} />
        <p>The publishing has almost immediate and irreversible effect, it follows the sequence</p>
        <ol>
          <li><u>New</u> images are uploaded</li>
          <li>The designs are saved</li>
          <li>The designs changes are propagated to Apps that use them to update them on fly</li>
        </ol>
        <Notice icon="info">Preview the designs and make sure they display properly and contain appropriate content</Notice>
      </Article>
    </ArticleLayout>
  )
}

export default WikiFigmaDesignPublish
