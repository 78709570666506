import { FormEvent } from "react"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { coerce, z } from "zod"

import { OCBAppViews } from "@/entities/app/ocb/ocb.types"
import ColorPicker from "@/shared/components/extrinsic/ColorPicker/ColorPicker"
import Field from "@/shared/components/intrinsic/Field/Field"
import FileSelector from "@/shared/components/intrinsic/FileSelector/FileSelector"
import FileSelectorMultiple from "@/shared/components/intrinsic/FileSelector/FileSelectorMultiple"
import Selector from "@/shared/components/intrinsic/Selector/Selector"
import { TrueFalseOptions } from "@/shared/components/intrinsic/Selector/Selector.helpers"

import FontFamilySelector from "../components/FontFamilySelector"
import FontSizeSelector from "../components/FontSizeSelector"
import SettingsGroup from "../components/SettingsGroup/SettingsGroup"
import SettingsRow from "../components/SettingsRow/SettingsRow"
import useTabSettings from "../hooks/useTabSettings"



const form = new ZodForm({
  title: coerce.string().transform(value => value.substring(0, 100)),
  fontFamily: coerce.string(),
  fontSize: coerce.number(),
  fontColor: coerce.string(),
  backgrounds: z.array(z.instanceof(File)).default([]).transform(files => files.slice(0, 5)),
  video: z.instanceof(File).optional().refine(file => (file?.size ?? 0) <= 20 * 1024 * 1024, "Video must not exceed 20MB"),
  logo: z.instanceof(File).optional(),

  orderTimeout: coerce.number().transform(value => value > 150 ? 150 : value),
  totalScreenEnabled: coerce.boolean(),
  totalScreenTimeout: coerce.number().transform(value => value > 50 ? 50 : value),
})

form.object._type satisfies OCBAppViews["welcome"]


function WelcomeTabSettings() {
  const [welcome, setWelcome] = useTabSettings("welcome")
  const { fieldIssues } = useZodFormIssues(form)

  function onChange(event: FormEvent<HTMLFormElement>) {
    const field = form.parseCurrentField(event)
    if (field == null) {
      if (event.target instanceof HTMLInputElement === false) return

      setWelcome({ [event.target.name]: null })
      return
    }

    setWelcome({ [field.name]: field.value })
  }

  return (
    <form onChange={onChange}>
      <SettingsGroup title="TEXT">
        <Field placeholder="Title" value={welcome.title ?? ""} name={form.fields.title}>Title</Field>
        <FontFamilySelector width="100%" value={welcome.fontFamily} name={form.fields.fontFamily} />
        <SettingsRow>
          <FontSizeSelector start={14} end={132} step={2} value={welcome.fontSize} name={form.fields.fontSize} />
          <ColorPicker label="Color" value={welcome.fontColor} name={form.fields.fontColor} />
        </SettingsRow>
      </SettingsGroup>
      <SettingsGroup title="MEDIA">
        <FileSelectorMultiple
          label="Images"
          accept="image/*"
          clearable

          name={form.fields.backgrounds}
          value={welcome.backgrounds}
        />
        <FileSelector
          name={form.fields.video}
          value={welcome.video}
          defaultValue={welcome.video}
          customValidity={fieldIssues.video}
          label="Video"
          accept="video/*"
          clearable
        />
        <FileSelector
          name={form.fields.logo}
          value={welcome.logo ?? undefined}
          defaultValue={welcome.logo ?? undefined}
          label="Logo"
          accept="image/*"
          clearable
        />
      </SettingsGroup>
      <SettingsGroup title="ORDER">
        <Field value={welcome.orderTimeout} name={form.fields.orderTimeout}>Timeout</Field>
      </SettingsGroup>
      <SettingsGroup title="TOTAL">
        <SettingsRow>
          <Selector label="Enabled" value={welcome.totalScreenEnabled} name={form.fields.totalScreenEnabled}>{TrueFalseOptions}</Selector>
          {welcome.totalScreenEnabled && (
            <Field value={welcome.totalScreenTimeout} name={form.fields.totalScreenTimeout}>Timeout</Field>
          )}
        </SettingsRow>
      </SettingsGroup>
    </form>
  )
}

export default WelcomeTabSettings
