import "./Pagination.scss"

import { PagedControl } from "@/utils/hooks/usePagedControl"

import Button from "../../intrinsic/Button/Button"
import Selector from "../../intrinsic/Selector/Selector"


interface PaginationProps {
  control: PagedControl
}

const pageSizeOptions = ["10", "25", "50", "100"]

function Pagination(props: PaginationProps) {
  function onPageSizeChange(value: string){
    props.control.setSize(Number(value))
    props.control.setPage(1) 
  }

  const pages = [...Array(props.control.pageAmount)].map((_, index) => index + 1)
  let displayPages

  if (pages.length > 5) {
    if (props.control.page <= 3) {
      displayPages = [1, 2, 3, 4, "...", pages.length]
    } else if (props.control.page >= pages.length - 2) {
      displayPages = [1, "...", pages.length - 3, pages.length - 2, pages.length - 1, pages.length]
    } else {
      displayPages = [1, "...", props.control.page - 1, props.control.page, props.control.page + 1, "...", pages.length]
    }
  } else {
    displayPages = pages
  }

  return (
    <div className="pagination">
      <Button size="smaller" disabled={props.control.atFirstPage} onClick={props.control.prev}>Previous</Button>
      <div className="pagination__seek">
        <div className="pagination__comparison">
          <Selector defaultValue={props.control.size.toString()} value={props.control.size.toString()} onChange={onPageSizeChange}>
            {pageSizeOptions.map((pageSize, index) => (
              <option value={pageSize} key={index}>{pageSize}</option>
            ))}
          </Selector>
          <b>of</b>
          <span>{props.control.total}</span>
        </div>
        <div className="pagination__comparison">
          {displayPages.map((page, index) => {
            if (typeof page === "number") {
              return (
                <Button 
                  key={index}
                  size="smaller"
                  onClick={() => props.control.setPage(page)}
                  color={page === props.control.page ? "gray" : "white"}>
                  {page}
                </Button>
              )
            } else {
              return <span key={index}>...</span>
            }
          })}
        </div>
      </div>
      <Button size="smaller" disabled={props.control.atLastPage} onClick={props.control.next}>Next</Button>
    </div>
  )
}

export default Pagination
