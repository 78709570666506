type EventEmitterListener = (...args: never[]) => void

class EventEmitter<Events extends Record<EventName, EventEmitterListener>, EventName extends keyof Events = keyof Events> {
  private callbacks: Partial<Record<keyof never, Set<EventEmitterListener>>> = {}

  public on<Event extends keyof Events>(event: Event, callback: Events[Event]) {
    this.callbacks[event] ??= new Set
    this.callbacks[event]?.add(callback as EventEmitterListener)
  }
  public off<Event extends keyof Events>(event: Event, callback: Events[Event]) {
    this.callbacks[event]?.delete(callback as EventEmitterListener)
  }
  public emit<Event extends keyof Events>(event: Event, ...args: Parameters<Events[Event]>) {
    this.callbacks[event]?.forEach(callback => callback(...args))
  }
}

export default EventEmitter
