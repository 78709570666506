import FontsDAO from "./fonts.dao"
import { FontEntity } from "./types"


const fontsDao = new FontsDAO

export function injectFontEntities(fonts: FontEntity[]) {
  for (const font of fonts) {
    for (const fontStyleUrl of font.styleUrls.values()) {
      const fontFace = new FontFace(font.family, `url(${fontStyleUrl})`, { display: "swap" })
      document.fonts.add(fontFace)
    }
  }
}



export function loadGoogleFont(family: string) {
  const link = document.createElement("link")
  link.rel = "stylesheet"
  link.href = `https://fonts.googleapis.com/css2?family=${family}&display=swap`
  document.head.appendChild(link)
}

export async function loadPrivateFonts(companyId: string) {
  injectFontEntities(await fontsDao.findAll({ companyId }))
}
