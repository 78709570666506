import { StreamAPI } from "@/api/stable/APIStable"
import { mapDesign } from "@/api/stable/mappings/design"

import { DesignFindSeekDTO } from "./design.dto"
import { Design } from "./design.types"

import DAO from "../dao"
import ToastError from "../ToastError.decorator"



class DesignDAO extends DAO {
  async findAll(seek: DesignFindSeekDTO): Promise<Design[]> {
    const response = await StreamAPI.fetch.GET["/design"]({
      queryParams: {
        companyId: seek.companyId,
        locationId: seek.locationId,

        "name[in]": seek.name
      }
    })

    const designs = response.payload.map(mapDesign)
    return designs
  }

  async findById(id: string): Promise<Design> {
    const response = await StreamAPI.fetch.GET["/design/{id}"]({
      pathParams: { id }
    })

    return mapDesign(response.payload)
  }

  @ToastError("Updating design name", "Design name updated")
  async updateNameById(id: string, name: string): Promise<void> {
    await StreamAPI.fetch.PATCH["/design/{id}"]({
      pathParams: { id },
      body: { name }
    })
  }
}

export default DesignDAO
