import "./PopupPersonalChangePassword.scss"

import { FormEvent, useState } from "react"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { z } from "zod"

import { NewPasswordFields, PasswordField } from "@/areas/auth"
// eslint-disable-next-line no-restricted-imports
import { passwordPattern } from "@/areas/auth/helpers"
import { UserPasswordUpdateDTO } from "@/entities/user"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Button from "@/shared/components/intrinsic/Button/Button"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import Fields from "@/shared/layouts/Fields/Fields"
import Form from "@/shared/layouts/Form/Form"
import useFormStatus from "@/utils/hooks/useFormStatus"


const form = new ZodForm({
  oldPassword: z.coerce.string(),
  newPassword: z.coerce.string().regex(passwordPattern, "Password must contain at least 8 characters, including: upper case, lower case, number and a special character")
})
form.object._type satisfies UserPasswordUpdateDTO

interface PopupPersonalChangePasswordProps {
  onSubmit?(fields: UserPasswordUpdateDTO): Promise<void> | void
}

function PopupPersonalChangePassword(props: PopupPersonalChangePasswordProps) {
  const [passwordConfirmed, setPasswordConfirmed] = useState(false)

  const formStatus = useFormStatus(form, onSubmit, { disabled: !passwordConfirmed })
  const { fieldIssues, addIssues } = useZodFormIssues(form)

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (fields == null) return

    if (fields.newPassword === fields.oldPassword) {
      addIssues(["newPassword"], ["Your new password cannot be the same as the current one"])
      return
    }

    await props.onSubmit?.(fields)
  }

  return (
    <PopupLayout width="40em">
      <Icon className="popup-personal-settings__gear" name="gear" />
      <h5 className="popup-personal-settings__title">Change password</h5>
      <Form onChange={form.parseCurrentField.bind(form)} onSubmit={formStatus.submit}>
        <input style={{ display: "none" }} autoComplete="username" />
        <Fields width="max">
          <PasswordField
            name={form.fields.oldPassword}
            customValidity={fieldIssues.oldPassword}
            placeholder="Current password"
            autoComplete="off"
          />
          <NewPasswordFields
            name={form.fields.newPassword}
            customValidity={fieldIssues.newPassword}
            onConfirm={setPasswordConfirmed}
          />
        </Fields>
        <Button type="submit" color="white" className="popup-personal-settings__submit" {...formStatus}>Save changes</Button>
      </Form>
    </PopupLayout>
  )
}

export default PopupPersonalChangePassword
