import { ReactNode, useMemo } from "react"

function useSearch(value: string | null | undefined) {
  const valueNormalized = useMemo(() => value?.toLowerCase() ?? "", [value])

  function search(searchable: string | null | undefined): number {
    if (searchable == null) return -1

    return searchable.toLowerCase().search(valueNormalized)
  }

  function filter(searchable: string | null | undefined): boolean {
    return search(searchable) >= 0
  }

  function highlight(searchable: string | null | undefined): ReactNode {
    if (searchable == null) return null

    const index = search(searchable)
    if (index === -1) return searchable

    return (
      <>
        {searchable.slice(0, index)}
        <em>{searchable.slice(index, index + valueNormalized.length)}</em>
        {searchable.slice(index + valueNormalized.length)}
      </>
    )
  }

  return {
    filter,
    highlight
  }
}

export default useSearch
