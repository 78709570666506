import { Observable } from "react-use/lib/useObservable"

import StreamAPISocket from "@/api/stable/APISocket"

class OnlineOCBsSource implements Observable<string[]>, Disposable {
  private readonly source = new StreamAPISocket("AdminPanel")

  constructor(readonly companyId: string) { }

  subscribe(callback: (onlineIds: string[]) => void) {
    this.source.socket.emitWithAck("CONNECTED_OCBS", { companyId: this.companyId }).then(instances => {
      callback(instances.map(instance => instance.appId))
    })

    const unsubscribe = this.source.on("CONNECTED_OCB_APPS", next => {
      if (this.companyId !== next.companyId) return

      callback(next.connected.map(appId => appId.slice(appId.indexOf("-") + 1)))
    })
    return { unsubscribe }
  }

  [Symbol.dispose]() {
    this.source[Symbol.dispose]()
  }
}

export default OnlineOCBsSource
