import { MenuAppInstance, MenuAppType } from "@/entities/app/menu/menu.types"

import { APIDocsSwagger } from "../APIStable"

type Screen = {
  _id: string
  appId: string
  location: string
}

type MenuAppInstanceSchema1 = {
  _id: string
  screen: Screen
  type: MenuAppType
  events: { designId: string }[]
  company: string
}

type MenuAppInstanceSchema = Partial<typeof APIDocsSwagger.schemas.Screen._plain & typeof APIDocsSwagger.schemas.MenuApp._plain & MenuAppInstanceSchema1>

export function mapMenuAppInstance(schema: MenuAppInstanceSchema): MenuAppInstance {
  return {
    type: schema.type ?? MenuAppType.MENU,
    name: schema.appId ?? schema.screen?.appId ?? "unknown", // Baptized as "name" in MenuAppInstance

    id: schema._id ?? "unknown",
    designId: schema?.events?.[0]?.designId ?? null,

    companyId: schema.company ?? schema.screen?.company ?? schema.screen?.location?.company?._id ?? "unknown",
    locationId: schema.screen?.location?._id ?? schema.screen?.location ?? "unknown" 
  }
}
