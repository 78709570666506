import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"


function useSearchState(name: string) {
  const [searchParams, setSearchParams] = useSearchParams()
  const param = searchParams.get(name) ?? undefined

  function setParam(value: string) {
    setSearchParams(searchParams => {
      searchParams.set(name, value)
      return searchParams
    })
  }

  return useMemo(() => [param, setParam] as const, [param, setParam])
}

export default useSearchState
