import "./ProfileSettingsLayout.scss"

import { useQueryClient } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"

import { UserRole } from "@/entities/user"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import { useAppSelector } from "@/store/hooks"

interface ProfileSettingsLayoutProps {
  children: ReactNode
  title: string
  isStreamSettings?: boolean
}

function ProfileSettingsLayout(props: ProfileSettingsLayoutProps) {
  const user = useAppSelector(state => state.user)
  const location = useLocation()
  
  const companyId = location.pathname.split("/")[2]
  const url = props.isStreamSettings ? `/companies` : `/companies?company-id=${companyId}`

  const queryClient = useQueryClient()
  
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["audit-log"] })
  }, [queryClient])

  return (
    <div className="profile-settings-layout">
      <div className="profile-settings-layout__header">
        {
          user.role >= UserRole.StreamManager && (
            <Link className="profile-settings-layout__link" to={url}>
              <Icon name="chevron-left" />
              Back to company selection
            </Link>
          )
        }
        <h2 className="profile-settings-layout__title">{ props.title }</h2>
      </div>
      {props.children}
    </div>
  )
}

export default ProfileSettingsLayout
