import "./CompanyList.scss"

import { Company } from "@/entities/company/company.types"


interface CompanyListProps {
  companies: Omit<Company, "status">[]
}

function CompanyList(props: CompanyListProps) {
  return (
    <div className="company-list">
      {props.companies.map((company, index) => (
        <div className="company-list__item" key={index}>
          <img className="company-list__logo" src={company.logo} />
          <div className="company-list__text">
            <span>NAME:</span>
            <em>{company.title}</em>
          </div>
          <div className="company-list__text">
            <span>OWNER:</span>
            <em className="company-list__owner">
              <span>{company.owner.firstName} {company.owner.lastName}</span>
              <i>{company.owner.email}</i>
            </em>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CompanyList
