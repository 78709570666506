import { useQuery, useSuspenseQuery } from "@tanstack/react-query"
import { Location, useLocation } from "react-router-dom"

import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import AppEditLayout from "@/app/layouts/AppEditLayout/AppEditLayout"
import PageLayout from "@/app/layouts/PageLayout/PageLayout"
import { AppInstanceView } from "@/areas/app"
import { OCBNewAppModal } from "@/areas/company"
import { LocationContainer, LocationGrouped, LocationGroupedComponentProps } from "@/areas/location"
import { OCBSettingsContainer } from "@/areas/ocb"
import OCBAppDAO from "@/entities/app/ocb/ocb.dao"
import OnlineOCBsSource from "@/entities/app/ocb/ocb.online"
import { confirmAction } from "@/services/Modal/components/PopupConfirm"
import Modal from "@/services/Modal/Modal"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"
import useParam from "@/utils/hooks/useParam"
import useResource from "@/utils/hooks/useResource"
import useSearchState from "@/utils/hooks/useSearchState"

interface LocationState {
  dirty?: boolean
}

function OcbsPage() {
  const companyId = useParam("id")
  const [appName] = useSearchState("app")

  const location = useLocation() as Location<LocationState>
  location.state ??= {}

  const sidebar = (
    <DefaultBoundaries>
      {!!appName && <OCBSettingsContainer name={appName} onChange={() => location.state.dirty = true} />}
    </DefaultBoundaries>
  )

  return (
    <AppEditLayout sidebar={sidebar} sidebarActive={!!appName}>
      <PageLayout>
        <h2>OCBs</h2>
        <DefaultBoundaries>
          <LocationGrouped companyId={companyId} children={OCBAppsByLocation} />
        </DefaultBoundaries>
      </PageLayout>
    </AppEditLayout>
  )
}

export default OcbsPage


const ocbAppDao = new OCBAppDAO

function OCBAppsByLocation(props: LocationGroupedComponentProps) {
  const [appName] = useSearchState("app")

  const { data: ocb } = useQuery({
    queryFn: () => appName != null ? ocbAppDao.findByName(appName) : null,
    queryKey: [ocbAppDao.name, appName]
  })

  return (
    <LocationContainer {...props} defaultExpanded={props.location.id === ocb?.locationId}>
      <DefaultBoundaries>
        <OCBAppsContainer {...props} />
      </DefaultBoundaries>
    </LocationContainer>
  )
}

function OCBAppsContainer(props: LocationGroupedComponentProps) {
  const [appName, setAppName] = useSearchState("app")
  const location = useLocation() as Location<LocationState>

  const queryKey = [ocbAppDao.name, "location", props.location.id]
  const { data: ocbs } = useSuspenseQuery({
    queryFn: () => ocbAppDao.findByLocation(props.location.id),
    queryKey
  })

  const activeAppIds = useResource(() => new OnlineOCBsSource(props.companyId), [props.companyId], [])

  async function onEdit(value: string) {
    if (location.state?.dirty) {
      await confirmAction()
    }

    setAppName(value)
  }

  function onCreate() {
    Modal.open(OCBNewAppModal, { companyId: props.companyId, location: props.location })
  }

  return (
    <>
      <ButtonIcon size="small" name="plus" ariaLabel="Create new App" onClick={onCreate} />
      {ocbs.length === 0 && (
        <span>There are no OCBs in this location.</span>
      )}
      {ocbs.toReversed().map(ocb => (
        <AppInstanceView {...ocb} active={activeAppIds.includes(ocb.name)} editing={ocb.name === appName} onEdit={() => onEdit(ocb.name)} key={ocb.name} />
      ))}
    </>
  )
}
