import Field from "@/shared/components/intrinsic/Field/Field"
import TextBox from "@/shared/layouts/TextBox/TextBox"

function UI() {
  // return undefined
  return (
    <>
      <TextBox>
        <h2>UI Components</h2>
        <p>Components of UI that are used on the website.</p>
      </TextBox>
      <Field />
    </>
  )
}

export default UI
