import { AppInstance } from "../app.types"

export enum MenuAppType {
  MENU = "MENU",
  MENU1 = "MENU_1",
}
export interface MenuAppInstance extends AppInstance {
  type: MenuAppType

  id: string
  designId: string | null
  companyId: string
  locationId: string
}

export interface MenuAppGroup {
  appId: string
  instances: MenuAppInstance[]
}
export interface MenuClient {
  appId: string;
  companyId?: string;
  locationId?: string;
  designId?: string;
  appType?: MenuAppType;
}
