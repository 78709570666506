import { AuthLayout, NewPasswordFormContainer } from "@/areas/auth"

function PasswordNew() {
  return (
    <AuthLayout title="Set your password">
      <NewPasswordFormContainer />
    </AuthLayout>
  )
}

export default PasswordNew
