import "./PopupLayout.scss"

import { useQueryClient } from "@tanstack/react-query"
import { ReactNode } from "react"
import { useState } from "react"
import { useModalWindow } from "react-modal-global"

import LocationDao from "@/entities/location/location.dao"
import { QLocation } from "@/entities/location/location.types"
import POSDao from "@/entities/pos/pos.dao"
import Theme from "@/services/Theme/Theme"
import Button from "@/shared/components/intrinsic/Button/Button"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import Tooltip from "@/shared/components/intrinsic/Tooltip/Tooltip"
export interface PopupLayoutProps {
  width?: string
  title?: ReactNode
  location?: QLocation;
  children: ReactNode
}

function PopupLayout(props: PopupLayoutProps) {
  const modal = useModalWindow()
  const queryClient = useQueryClient()
  const [isDetaching, setIsDetaching] = useState(false)
  const [pending, setPending] = useState(false)

  async function onDelete() {
    if (!props.location?.id) {
      return
    }
  
    const dto = { id: props.location.pos.id }
    setPending(true)
    await POSDao.delete(dto)
    queryClient.invalidateQueries({ queryKey: [LocationDao.name] })
    setPending(false)
    modal.close()
  }

  return (
    <div className="popup-layout" style={{ width: props.width }}>
      <div className="popup-layout__close">
        <ButtonIcon name="cross" color="transparent" size="small" onClick={modal.close} ariaLabel="Close popup" />
      </div>
      <div className="popup-layout__container">
        {props.title && (
          <div>
            <div className='popup-layout__title_container'>
              <h3 className="popup-layout__title">{props.title}</h3>
              {props.location?.pos?.id && (
                <button type='button' onClick={() => setIsDetaching(true)}>
                  <Tooltip text="Detach POS" boxSize={8}>
                    <Icon name="detach" />
                  </Tooltip>
                </button>
              )}
            </div>
            {props.location?.title && (
              <p className="popup-layout__location-name">{props.location?.title}</p>
            )}
          </div>
        )}
        <Theme scheme="dark">
          {props.children}
        </Theme>
      </div>

      {isDetaching && (
        <div className='popup-layout__delete'>
          <h3>Are you sure you want to detach this POS?</h3>
          <p>This action will remove the POS integration from this location.</p>
          <p>All related data will be lost.</p>
          <div className='popup-layout__delete-buttons'>
            <Button color="red" size='small' onClick={() => setIsDetaching(false)}>Cancel</Button>
            <Button pending={pending} size='small' onClick={onDelete}>Detach</Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default PopupLayout
