import { InferActions } from "@/store/store.types"

import { Types } from "./user.actions"

import { USER_GUEST } from "../user.consts"
import { User } from "../user.types"

const initialState: User = { ...USER_GUEST }

export default (state = initialState, action: InferActions<Types>): User => {
  switch (action.type) {

    case "USER_UPDATE":
      return { ...state, ...action.payload }

    case "USER_RESET":
      return { ...initialState }

    default:
      return state
  }
}
