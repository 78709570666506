import { useNavigate } from "react-router-dom"
import { useSearchParam } from "react-use"

import { UserDao } from "@/entities/user"

import NewPasswordForm from "./NewPasswordForm"

function NewPasswordFormContainer() {
  const navigate = useNavigate()

  const recoveryToken = useSearchParam("recovery-token")
  const verificationToken = useSearchParam("verification-token")

  async function onSubmit(password: string) {
    if (recoveryToken != null && verificationToken != null) {
      throw new Error("There can't be both `recoveryToken` or `verificationToken`")
    }

    let response: unknown
    
    if (verificationToken != null) {
      response = await UserDao.verify({ token: verificationToken, password })
    }

    if (recoveryToken != null) {
      response = await UserDao.recoverPassword({ token: recoveryToken, password })
    }

    setTimeout(() => {
      if (response) navigate("/user/login") 
    }, 5000)

    return response ? true : false
  }

  return (
    <NewPasswordForm onSubmit={onSubmit} />
  )
}

export default NewPasswordFormContainer
