import { useModalWindow } from "react-modal-global"

import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Button from "@/shared/components/intrinsic/Button/Button"
import Buttons from "@/shared/layouts/Buttons/Buttons"

interface PopupAppInstanceDeletionConfirmProps {
  onConfirm?(): void
}

function PopupAppInstanceDeletionConfirm(props: PopupAppInstanceDeletionConfirmProps) {
  const modal = useModalWindow()
  return (
    <PopupLayout width="45em" title="Are you sure you want to delete “Name of the screen” screen?">
      <Buttons centered>
        <Button color="red" onClick={props.onConfirm}>Delete app instance</Button>
        <Button color="white" onClick={modal.close}>Cancel</Button>
      </Buttons>
    </PopupLayout>
  )
}

export default PopupAppInstanceDeletionConfirm
