import "./Logo.scss"

import { Link } from "@/app/AppRouter"

function Logo() {
  return (
    <div className="logo">
      <img src="/static/logo.png" alt="logo" />
      <Link className="ghost" to="/" />
    </div>
  )
}

export default Logo
