import { useState } from "react"
import toast from "react-hot-toast"
import { useModalWindow } from "react-modal-global"

import queryClient from "@/api/client"
import { AppInstance } from "@/entities/app/app.types"
import OCBAppDAO from "@/entities/app/ocb/ocb.dao"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Fields from "@/shared/layouts/Fields/Fields"
import { inputValue } from "@/utils/common"

interface OCBNewAppModalProps {
  companyId: string
  location: {
    id: string
  }
}

const ocbAppDao = new OCBAppDAO

function OCBNewAppModal(props: OCBNewAppModalProps) {
  const modal = useModalWindow()
  const [name, setName] = useState<string>("")
  const [pending, setPending] = useState<boolean>(false)


  async function onOCBCreate() {
    const queryKey = [ocbAppDao.name, "location", props.location.id]

    const ocbApp = await ocbAppDao.create({
      name,

      companyId: props.companyId,
      locationId: props.location.id
    })

    queryClient.setQueryData<AppInstance[]>(queryKey, apps => apps && [...apps, ocbApp])
  }

  async function onSubmit (event: React.FormEvent) {
    try {
      event.preventDefault()
      setPending(true)
      await onOCBCreate()
      modal.close()
      toast.success("OCB App instance has been created")
      setName("")
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message)
      }
    } finally {
      setPending(false)
    }
  }
  

  return (
    <PopupLayout title="Create OCB APP Instance">
      <form onSubmit={onSubmit}>
        <Fields>
          <Field type="text" value={name} placeholder='App ID' onChange={inputValue(setName)} />  
          <Button type="submit" pending={pending}>Create</Button>
        </Fields>
      </form>
    </PopupLayout>
  )
}

export default OCBNewAppModal
