import toast from "react-hot-toast"

import DAO from "./dao"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PromiseMethodDescriptor = TypedPropertyDescriptor<(...args: never[]) => Promise<any>>
function ToastResult<T extends DAO>(subject: string, action: string, finished: string) {
  return (target: T, propertyKey: keyof T, descriptor: PromiseMethodDescriptor): PromiseMethodDescriptor => {
    const method = target[propertyKey]
    if (method instanceof Function === false) {
      throw new TypeError("ToastResult is only applied to methods")
    }

    return {
      ...descriptor,
      async value(this: DAO, ...args) {
        if (DAO.NO_TOASTS in this) return await method.call(this, ...args)

        try {
          const result = await method.call(this.disableToasts(), ...args)
          toast.success(`${subject} ${finished.toLowerCase()}`, { duration: 2000 })
          return result
        } catch (error) {
          if (error instanceof Error === false) throw error
          if (import.meta.env.PROD === false) console.error("<DEVELOPMENT ONLY>", error)

          toast.error(`${subject} ${action.toLowerCase()} failed: ` + error.message, { duration: 10000 })
          throw error
        }
      },
    }
  }
}

export default ToastResult
