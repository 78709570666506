import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Table from "@/shared/components/intrinsic/Table/Table"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

function WikiSelectorPage() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Selector Usage Tips</h2>
      </TextBox>
      <Article>
        <p>Selector is a common component both in StreamEditor and the Figma Plugin, they all have these features</p>
        <Table>
          <thead>
            <tr>
              <th>Feature</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>List</td>
              <td>When clicking on the selector it will show a list of available options</td>
            </tr>
            <tr>
              <td>Search</td>
              <td>If you type in the selector field, it will filter the options</td>
            </tr>
            <tr>
              <td>Search</td>
              <td>Sometimes it will be possible to create a new option by typing in the search field and choosing {`"Create"`}</td>
            </tr>
            <tr>
              <td>Multiple selection</td>
              <td>In some cases, you will be allowed to choose multiple options, this is usually obvious</td>
            </tr>
          </tbody>
        </Table>
      </Article>
    </ArticleLayout>
  )
}

export default WikiSelectorPage
