import "./CompanyNavLayout.scss"

import { ReactNode } from "react"

interface CompanyNavLayoutProps {
  sidebar?: ReactNode
  children: ReactNode
}

function CompanyNavLayout(props: CompanyNavLayoutProps) {
  return (
    <div className="company-nav-layout">
      {props.sidebar && (
        <div className="company-nav-layout__sidebar">{props.sidebar}</div>
      )}
      <div className="company-nav-layout__container">{props.children}</div>
    </div>
  )
}

export default CompanyNavLayout
