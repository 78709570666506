import { FormEvent, useMemo, useState } from "react"
import { useModalWindow } from "react-modal-global"
import { useZodFormIssues } from "react-zod-form"

import queryClient from "@/api/client"
import { form, parsePosName, posFieldEntries, posFieldPlaceholders, posInfoText } from "@/constants/posPopupIntegration"
import LocationDao from "@/entities/location/location.dao"
import { QLocation } from "@/entities/location/location.types"
import POSDao from "@/entities/pos/pos.dao"
import { POSIntegrationEditDTO } from "@/entities/pos/pos.dto"
import { POSCBSNorthStarIntegrationConfig, POSIntegration, POSName } from "@/entities/pos/pos.types"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import Selector from "@/shared/components/intrinsic/Selector/Selector"
import { optionsFromEnum } from "@/shared/components/intrinsic/Selector/Selector.helpers"
import Tooltip from "@/shared/components/intrinsic/Tooltip/Tooltip"
import Fields from "@/shared/layouts/Fields/Fields"
import useFormStatus from "@/utils/hooks/useFormStatus"


interface PopupEditMenuIntegrationProps {
  location: QLocation;
  onSubmit(integration: POSIntegration): Promise<void> | void;
}

function PopupEditMenuIntegration({ location, onSubmit }: PopupEditMenuIntegrationProps) {
  const modal = useModalWindow()

  const [posName, setPosName] = useState<POSName | null>(() => parsePosName(location?.pos?.name))
  const [changedFields, setChangedFields] = useState<string[]>([])

  const { fieldIssues } = useZodFormIssues(form)
  const formStatus = useFormStatus(form, handleSubmit)
  const [configData, setConfigData] = useState(JSON.parse(location.pos?.configJson ?? "{}"))
  const formUpdated = useMemo(() => changedFields.length !== 0, [changedFields])

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (!fields) return

    if (posName === "CBSNorthStar") {
      fields.clientId = fields.config.site_id as string
    }

    await onSubmit(fields)

    setChangedFields([])
    setConfigData(fields.config)

    if (!location.pos?.name) {
      queryClient.invalidateQueries({ queryKey: [LocationDao.name] })
    }

    modal.close()
  }

  function onChange(event: FormEvent<HTMLFormElement>) {
    const field = form.parseCurrentField(event)
    if (!field) return

    const fieldName = field.name.split(".")[1]

    const isFieldChanged = configData[fieldName] !== field.value
    setChangedFields((fields) =>
      isFieldChanged
        ? fields.includes(fieldName)
          ? fields
          : [...fields, fieldName]
        : fields.filter((changedField) => changedField !== fieldName)
    )
  }

  function parseDefaultValue(fieldName: string) {
    return posName === parsePosName(location?.pos?.name)
      ? fieldName === "client"
        ? location?.pos?.client
        : configData[fieldName]
      : undefined
  }

  async function onFlushUpdates() {
    if (location.pos.configJson == null) {
      throw new TypeError("location.pos.configJson must not be null")
    }

    const config: POSCBSNorthStarIntegrationConfig = JSON.parse(location.pos.configJson)
    const dto: POSIntegrationEditDTO = {
      locationId: location.id,
      name: location.pos.name,
      clientId: location.pos.client,
      config,
    }

    await POSDao.put(dto, "REFRESH")
    modal.close()
  }


  return (
    <PopupLayout title={`${posName ? posName : "Create"} Integration`} location={location} width="50em">
      <form onChange={onChange} onSubmit={formStatus.submit}>
        <Fields>
          <div
            style={{
              display: !location?.pos ? "flex" : "none",
              alignItems: "center",
              gap: "1em"
            }}
          >
            {posName && (
              <Tooltip text={posInfoText[posName]?.posName ?? "Info not available"}>
                <Icon name="info" />
              </Tooltip>
            )}
            <div style={{ flexGrow: 1 }}>
              <Selector
                name={form.fields.name}
                creatable
                placeholder="POS Provider Name"
                defaultValue={posName}
                onCreate={async () => await new Promise((resolve) => setTimeout(resolve, 1000))}
                onChange={setPosName}
              >
                {optionsFromEnum(POSName)}
              </Selector>
            </div>
          </div>

          {posName != null && (
            <>
              {posName !== "CBSNorthStar" && (
                <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
                  <Tooltip text={posInfoText[posName]?.clientId ?? "Info not available"}>
                    <Icon name="info" />
                  </Tooltip>
                  <div style={{ flexGrow: 1 }}>
                    <Field
                      name={form.fields.clientId}
                      placeholder="POS Client ID"
                      customValidity={fieldIssues.clientId}
                      defaultValue={parseDefaultValue("client")}
                      key={posName}
                    />
                  </div>
                </div>
              )}

              {posFieldEntries.map(
                ([fieldName, posNames]) =>
                  posNames.includes(posName) && (
                    <div style={{ display: "flex", alignItems: "center", gap: "1em" }} key={fieldName}>
                      <Tooltip text={posInfoText[posName]?.[fieldName] ?? "Info not available"}>
                        <Icon name="info" />
                      </Tooltip>
                      <div style={{ flexGrow: 1 }}>
                        <Field
                          name={form.fields.config[fieldName]}
                          placeholder={posFieldPlaceholders[fieldName]}
                          customValidity={fieldIssues.config?.[fieldName]}
                          defaultValue={parseDefaultValue(fieldName)}
                        />
                      </div>
                    </div>
                  )
              )}
            </>
          )}

          <div style={{ display: "flex", justifyContent: "center", gap: 46, marginTop: "2.5rem" }}>
            {!location?.pos?.name && <Button color="red" onClick={modal.close}>Cancel</Button>}
            {location?.pos?.name && (
              <Button type="submit" color="white" await onClick={onFlushUpdates}>Refresh Data</Button>
            )}
            <Button type="submit" color="white" {...formStatus} disabled={formStatus.disabled || !posName || !formUpdated}>
              {location?.pos?.name ? "Update" : "Create"}
            </Button>
          </div>
        </Fields>
      </form>
    </PopupLayout>
  )
}

export default PopupEditMenuIntegration
