import { FormEvent } from "react"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { z } from "zod"

import { Link } from "@/app/AppRouter"
import { UserLoginDTO } from "@/entities/user"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Fields from "@/shared/layouts/Fields/Fields"
import useFormStatus from "@/utils/hooks/useFormStatus"

import AuthLayout from "../AuthLayout/AuthLayout"
import PasswordField from "../PasswordField/PasswordField"

const form = new ZodForm({
  email: z.coerce.string().email("Enter correct email"),
  password: z.coerce.string().max(64, "Password must contain at most 64 characters")
})
form.object._type satisfies UserLoginDTO

interface LoginFormProps {
  validity?: string

  onChange?(): void
  onSubmit?(dto: UserLoginDTO): void | Promise<void>
}

function LoginForm(props: LoginFormProps) {
  const formStatus = useFormStatus(form, onSubmit)
  const { fieldIssues } = useZodFormIssues(form)

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (fields == null) return

    await props.onSubmit?.(fields)
  }

  function onChange(event: FormEvent<HTMLFormElement>) {
    form.parseAllFields(event)
    props.onChange?.()
  }

  return (
    <AuthLayout title="Login">
      <form onBlur={onChange} onSubmit={formStatus.submit}>
        <Fields>
          <Field
            name={form.fields.email}
            customValidity={fieldIssues.email}
            placeholder="E-mail"
            autoComplete="username"
            theme="light"
          />
          <PasswordField
            name={form.fields.password}
            customValidity={fieldIssues.password ?? props.validity}
            placeholder="Password"
            autoComplete="current-password"
            theme="light"
          />
          <Link to="/user/password/reset">Forgot password?</Link>
        </Fields>
        <Button size="big" type="submit" {...formStatus}>Login</Button>
      </form >
    </AuthLayout >
  )
}

export default LoginForm
