import { StreamAPI } from "@/api/stable/APIStable"
import { mapAuditLogEntry } from "@/api/stable/mappings/log"

import { AuditLogSeekDTO } from "./audit-log.dto"
import { AuditLogEntry } from "./audit-log.types"

import DAO from "../dao"
import { Paginated } from "../types"

class AuditLogDAO extends DAO {
  async find(id: string): Promise<AuditLogEntry> {
    const response = await StreamAPI.fetch.GET["/logs/{id}"]({ pathParams: { id } })

    return mapAuditLogEntry(response.payload)
  }

  async findAll(seek?: Partial<AuditLogSeekDTO>): Promise<Paginated<AuditLogEntry>> {
    const response = await StreamAPI.fetch.GET["/logs"]({
      queryParams: {
        page: seek?.page,
        pageSize: seek?.pageSize,
        fromDate: seek?.fromDate?.toISOString(),
        toDate: seek?.toDate?.toISOString(),
        action: seek?.actions?.toString(),
        username: seek?.username,
        sortBy: seek?.sortBy,
        sortOrder: seek?.sortOrder,
      }
    })

    return {
      items: response.payload.logs.map(mapAuditLogEntry),
      total: response.payload.totalLogsCount,
    }
  }
}

const AuditLogDao = new AuditLogDAO()
export default AuditLogDao
