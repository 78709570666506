import "./OCBSettings.scss"

import "@streamllc/shared/views.css"

import { OCBView } from "@streamllc/shared/ocb"

import TabRoute from "@/services/TabRouter/TabRoute"
import TabRouter from "@/services/TabRouter/TabRouter"
import Button from "@/shared/components/intrinsic/Button/Button"
import TextBox from "@/shared/layouts/TextBox/TextBox"
import { classWithModifiers } from "@/utils/common"

import SettingsTabs from "../../components/SettingsTabs/SettingsTabs"
import OrderTabSettings from "../../containers/OrderTabSettings"
import WelcomeTabSettings from "../../containers/WelcomeTabSettings"

interface OCBSettingsProps {
  name: string
  disabled?: boolean

  onDelete?(): void | Promise<void>
  onPublish?(): void | Promise<void>
}

function OCBSettings(props: OCBSettingsProps) {
  return (
    <div className={classWithModifiers("ocb-settings", props.disabled && "disabled")}>
      <TextBox>
        <h2>Settings</h2>
        <h5>{props.name}</h5>
      </TextBox>
      <TabRouter defaultPath={OCBView.Welcome}>
        <SettingsTabs name={props.name} />
        <TabRoute path={OCBView.Welcome}><WelcomeTabSettings /></TabRoute>
        <TabRoute path={OCBView.Order}><OrderTabSettings /></TabRoute>
      </TabRouter>
      <div className="ocb-settings__buttons">
        {/* <ButtonIcon name="trash-bin" color="white" await onClick={props.onDelete} ariaLabel="Delete" /> */}
        <Button color="white" await onClick={props.onPublish}>Publish</Button>
      </div>
    </div>
  )
}

export default OCBSettings
