import "./MenuAppSettings.scss"

import { useState } from "react"

import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import { MenuAppUpdateDTO } from "@/entities/app/menu/menu.dto"
import { MenuAppInstance } from "@/entities/app/menu/menu.types"
import { Design } from "@/entities/design"
import TabLink from "@/services/TabRouter/TabLink"
import TabLinks from "@/services/TabRouter/TabLinks"
import TabRoute from "@/services/TabRouter/TabRoute"
import TabRouter from "@/services/TabRouter/TabRouter"
import Button from "@/shared/components/intrinsic/Button/Button"
import ButtonLink from "@/shared/components/intrinsic/Button/ButtonLink"
import Buttons from "@/shared/layouts/Buttons/Buttons"
import TextBox from "@/shared/layouts/TextBox/TextBox"

import MenuAppView from "../../containers/MenuAppView"
import MenuDesignSelector from "../MenuDesignSelector/MenuDesignSelector"



interface MenuAppSettingsProps {
  app: MenuAppInstance
  onSubmit(dto: MenuAppUpdateDTO): void | Promise<void>
}

function MenuAppSettings(props: MenuAppSettingsProps) {
  const [design, setDesign] = useState<Design | null>(null)

  const reviewDesignId = design?.id ?? props.app.designId

  return (
    <div className="menu-app-settings">
      <TextBox>
        <h3>{props.app.name}</h3>
        <p>{props.app.id}</p>
      </TextBox>
      <TabRouter defaultPath="design">
        <TabLinks>
          <TabLink to="design">Design</TabLink>
          {reviewDesignId != null && <TabLink to="review">Review</TabLink>}
        </TabLinks>
        <DefaultBoundaries>
          {reviewDesignId != null && (
            <TabRoute path="review">
              <MenuAppView id={reviewDesignId} companyId={props.app.companyId} />
              <Buttons spaced>
                <ButtonLink size="smaller" color="green" to={"/menu-preview/" + reviewDesignId + "?companyId=" + props.app.companyId}>Full screen</ButtonLink>
                {design?.id != null && design.id !== props.app.designId && (
                  <Button color="white" size="smaller" await onClick={() => props.onSubmit({ designId: design.id })}>Save</Button>
                )}
              </Buttons>
            </TabRoute>
          )}
          <TabRoute path="design">
            <MenuDesignSelector app={props.app} current={design} onChange={other => other === design ? setDesign(null) : setDesign(other)} />
          </TabRoute>
        </DefaultBoundaries>
      </TabRouter>
    </div>
  )
}

export default MenuAppSettings
