import useSafeContext from "@/utils/hooks/useSafeContext"

import ocbViewsContext from "../contexts/ocbViewsContext"
import { OCBViewCustomizations } from "../types"

function useSettings() {
  const [settings, setSettings] = useSafeContext(ocbViewsContext)

  function updateSettings(newSettings: Partial<OCBViewCustomizations>) {
    setSettings(settings => ({ ...settings, ...newSettings }))
  }

  function updateTabSettings<Tab extends keyof OCBViewCustomizations>(tab: Tab, tabSettings: Partial<OCBViewCustomizations[Tab]>) {
    setSettings(settings => ({ ...settings, [tab]: { ...settings[tab], ...tabSettings } }))
  }

  return { settings, updateSettings, updateTabSettings }
}

export default useSettings
