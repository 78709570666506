import { ReactNode } from "react"

import themeContext, { ThemeScheme } from "./themeContext"

interface ThemeProps {
  scheme: ThemeScheme
  children: ReactNode
}

function Theme(props: ThemeProps) {
  return (
    <themeContext.Provider value={props.scheme}>
      {props.children}
    </themeContext.Provider>
  )
}

export default Theme
