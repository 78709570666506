import EventEmitter from "./EventEmitter"

class WindowTab {
  static {
    setInterval(() => this.watchers.forEach(watcher => watcher()), 100)
  }
  private static readonly watchers: Set<() => void> = new Set

  /**
   * https://stackoverflow.com/questions/15769514/window-onclose-function
   */
  protected static watchForClosure(tab: WindowTab) {
    const watcher = () => {
      if (!tab.window?.closed) return

      tab.events.emit("close")
      this.watchers.delete(watcher)
    }

    this.watchers.add(watcher)
  }

  protected readonly events = new EventEmitter<{ close(): void }>()

  readonly window: ReturnType<typeof window.open>
  readonly url: URL

  constructor(url?: string | URL, target?: string, features?: string) {
    this.url = new URL(window.location.origin)
    if (url instanceof URL) this.url = url
    if (typeof url === "string") this.url = new URL(url, window.location.origin)

    this.window = window.open(url, target, features)

    WindowTab.watchForClosure(this)
  }

  replaceUrl(url: string | URL) {
    if (this.window == null) throw new TypeError("Can't replace url without `window`")

    this.url.href = new URL(url, this.url).toString()
    this.window.location.href = this.url.href
  }

  on(event: "close", callback: () => void) {
    this.events.on(event, callback)
  }
}

export default WindowTab
