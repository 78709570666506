import { User } from "../user.types"

export interface Types {
  USER_UPDATE: Partial<User>
  USER_RESET: void
}

export const update = (payload: Types["USER_UPDATE"]) => ({
  type: "USER_UPDATE",
  payload
}) as const

export const reset = () => ({
  type: "USER_RESET"
}) as const
