import "./AppInstance.scss"

import { KeyboardEvent, ReactNode, useRef, useState } from "react"

import { AppInstance } from "@/entities/app/app.types"
import Modal from "@/services/Modal/Modal"
import Expander from "@/shared/components/extrinsic/Expander/Expander"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"
import FieldAdaptive from "@/shared/components/intrinsic/Field/FieldAdaptive"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import { classWithModifiers, inputValue, toggleState } from "@/utils/common"

import PopupAppInstanceDeletionConfirm from "../../modals/PopupAppInstanceDeletionConfirm"


export interface MenuAppInstanceViewProps extends AppInstance {
  name: string
  onNameChange?(value: string): void | Promise<void>

  active: boolean
  onActiveChange?(active: boolean): void

  editing?: boolean
  onEdit?(): void

  onDelete?(): void

  children?: ReactNode

  onCreate(): void | Promise<void>
}

function MenuAppInstanceView(props: MenuAppInstanceViewProps) {
  const [expanded, setExpanded] = useState(true)

  function onDelete() {
    Modal.open(PopupAppInstanceDeletionConfirm, { onConfirm: props.onDelete })
  }

  return (
    <div className={classWithModifiers("app-instance", props.editing && "editing")}>
      <div className="app-instance__header">
        {props.children && <button type="button" onClick={toggleState(setExpanded)}><Icon name={expanded ? "chevron-up" : "chevron-down"} /></button>}
        <AppInstanceTitle value={props.name} onChange={props.onNameChange} />
        <div className="app-instance__actions">
          <div className="app-instance__toggle">
            {/* <Tumbler checked={props.active} readonly={props.onActiveChange == null} onChange={props.onActiveChange} /> */}
            {props.active && (
              <ButtonIcon size="smaller" name="plus" ariaLabel="Add menu app" await onClick={props.onCreate} />
            )}
          </div>
          <div className="app-instance__tools">
            {props.onEdit && (
              <button className="app-instance__button" type="button" onClick={props.onEdit}>Edit</button>
            )}
            {props.onDelete && (
              <ButtonIcon size="small" color="transparent" name="trash-bin" onClick={onDelete} ariaLabel="Delete" />
            )}
          </div>
        </div>
      </div>
      {props.children && <Expander expanded={expanded}><div className="app-instance__container">{props.children}</div></Expander>}
    </div>
  )
}

interface AppInstanceTitleProps {
  value?: string
  onChange?(value: string): void | Promise<void>
}

/**
 * Has optimistic update.
 */
function AppInstanceTitle(props: AppInstanceTitleProps) {
  const [value, setValue] = useState(props.value)
  const [editable, setEditable] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  async function onSubmit(value: string) {
    try {
      await props.onChange?.(value)
    } catch (error) {
      setValue(props.value)

      throw error
    }
  }

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (inputRef.current == null) return

    if (event.code === "Enter") {
      event.preventDefault()
      onSubmit(event.currentTarget.value)
      setEditable(false)

      return
    }

    if (event.code === "Escape") {
      event.preventDefault()
      setValue(props.value)
      setEditable(false)

      return
    }
  }

  return (
    <div className="app-instance-title">
      <FieldAdaptive className="app-instance-title__input" disabled={!editable} value={value} onChange={inputValue(setValue)} onKeyDown={onKeyDown} _ref={inputRef} />
      {/* <button className={classWithModifiers("app-instance-title__toggle", editable && "editable")} type="button" onClick={toggleState(setEditable)}>
        <Icon name="pen" />
      </button> */}
    </div>
  )
}

export default MenuAppInstanceView
