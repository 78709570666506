import "./Tumbler.scss"

import { useState } from "react"

import { classWithModifiers } from "@/utils/common"

interface TumblerProps {
  leftText?: string
  rightText?: string
  checked?: boolean
  defaultChecked?: boolean

  readonly?: boolean

  type?: string
  onChange?: (checked: boolean) => void
}

function Tumbler(props: TumblerProps) {
  const [checked, setChecked] = useState(props.checked ?? props.defaultChecked ?? false)
  function updateChecked(value: boolean) {
    if (props.readonly) return

    setChecked(value)
    props.onChange?.(value)
  }
  return (
    <div className={classWithModifiers("tumbler", props.type)}>
      <div className={classWithModifiers("tumbler-shift", (checked || props.checked) && "checked")} onClick={() => updateChecked(!checked)}>
        <div className="tumbler-shift__circle" />
      </div>
    </div>
  )
}

export default Tumbler
