import { Link } from "react-router-dom"

import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Notice from "@/shared/components/extrinsic/Notice/Notice"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

import DesignDropPNG from "./design-drop.png"
import DesignNewPNG from "./designs-new.png"

function WikiFigmaDesigns() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Figma Plugin | Designs Page</h2>
      </TextBox>
      <Article>
        <p>Designs page allows you to browse designs scoped to a chosen location.</p>
        <li>If you select <b><u>All</u></b> in location Selector, the designs will be labeled with their location, so you can see their relation</li>
        <li>You can add a new design by clicking chevron next to a designs tab in the navigation bar</li>
        <img src={DesignNewPNG} />
        <li>You can edit a design by Dragging & Dropping one into the Figma Canvas</li>
        <img src={DesignDropPNG} />
        <Notice icon="cross-circle">You can not delete designs in the Figma Plugin, this can only be done in StreamEditor</Notice>
        <Link to="/wiki/figma/designs/publish">Learn how to publish a new version</Link>
      </Article>
    </ArticleLayout>
  )
}

export default WikiFigmaDesigns
