class FilePreview {
  private static cache: WeakMap<Blob, string> = new WeakMap
  private static cleanup = new FinalizationRegistry(URL.revokeObjectURL)

  private static getObjectURL(file: Blob): string {
    const cachedUrl = FilePreview.cache.get(file)
    if (cachedUrl) return cachedUrl

    return URL.createObjectURL(file)
  }

  /**
   * Creates consistent preview url for given `file`, revokes it when `file` has no pointers.
   *
   * @throws `TypeError` if `file` is non-media.
   */
  public static getURL(file: Blob): string {
    const url = FilePreview.getObjectURL(file)

    FilePreview.cache.set(file, url)
    FilePreview.cleanup.register(file, url)

    return url
  }
}

export default FilePreview
