import "./CompanyHeading.scss"

import { Link } from "react-router-dom"

import { CompanyBase } from "@/entities/company/company.types"

interface CompanyHeadingProps extends CompanyBase { }

function CompanyHeading(props: CompanyHeadingProps) {
  return (
    <div className="company-heading">
      <div className="company-heading__text">Now editing:</div>
      <img src={props.logo} alt="company logo" className="company-heading__image" />
      <div className="company-heading__title">{props.title}</div>
      <Link className="ghost" to={"/companies/" + props.id} />
    </div>
  )
}

export default CompanyHeading
