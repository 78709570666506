import { useSuspenseQuery } from "@tanstack/react-query"

import AuditLogDao from "@/entities/audit-log/audit-log.dao"

import AuditLogDetails from "./AuditLogDetails"


interface AuditLogDetailsContainerProps {
  id: string
}

function AuditLogDetailsContainer(props: AuditLogDetailsContainerProps) {
  const { data: entry } = useSuspenseQuery({
    queryFn: () => AuditLogDao.find(props.id),
    queryKey: [AuditLogDao, props.id]
  })

  return (
    <AuditLogDetails {...entry} />
  )
}

export default AuditLogDetailsContainer
