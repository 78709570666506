import "./PageLayout.scss"

import { ReactNode } from "react"

interface PageLayoutProps {
  children: ReactNode
}

function PageLayout(props: PageLayoutProps) {
  return (
    <div className="page-layout">{props.children}</div>
  )
}

export default PageLayout
