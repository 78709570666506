import { QueryErrorResetBoundary } from "@tanstack/react-query"
import { Component, ErrorInfo } from "react"

import { ErrorBoundaryProps, ErrorBoundaryState } from "./ErrorBoundary.types"

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false
  }

  static getDerivedStateFromError(error: unknown): ErrorBoundaryState | null {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error instanceof Error ? error : undefined }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo)

    this.setState({ error, errorInfo })
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (this.props.deps?.toString() !== prevProps.deps?.toString()) {
      this.reset()
    }
  }

  reset = () => {
    this.setState({ hasError: false, error: undefined, errorInfo: undefined })
  }
  render() {
    return (
      <QueryErrorResetBoundary>
        {({ reset }) => {
          if (this.state.hasError) {
            if (typeof this.props.fallback === "function") {
              return this.props.fallback?.(() => { reset(); this.reset() }, this.state.error, this.state.errorInfo)
            }
            return this.props.fallback
          }
          return this.props.children
        }}
      </QueryErrorResetBoundary>
    )
  }
}

export default ErrorBoundary
