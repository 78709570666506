import { StreamAPI } from "@/api/stable/APIStable"

import { POSDetachDTO, POSIntegrationEditDTO } from "./pos.dto"
import { POSAction, POSName } from "./pos.types"

import DAO from "../dao"
import ToastResult from "../ToastResult.decorator"

class PosDAO extends DAO {
  @ToastResult("POS Provider", "Create", "Created")
  async put(dto: POSIntegrationEditDTO, action?: POSAction): Promise<void> {
    const oracleConfig = { ...dto.config, clientId: dto.clientId }

    await StreamAPI.fetch.POST["/menus-integration/initialize-menus/{locationId}"]({
      body: {
        posName: dto.name,
        clientId: dto.clientId,
        configJson: dto.name === POSName.Oracle ? JSON.stringify(oracleConfig) : JSON.stringify(dto.config)
      },
      pathParams: { locationId: dto.locationId },
      queryParams: { action }
    })
  }

  @ToastResult("POS Provider", "Detach", "Detached")
  async delete(dto: POSDetachDTO): Promise<void> {
    await StreamAPI.fetch.DELETE["/pos-provider/{id}"]({
      pathParams: { id: dto.id },
    })
  }
}

const POSDao = new PosDAO()
export default POSDao
