import "./CompanyNavigation.scss"

import { ReactNode } from "react"
import { Link } from "react-router-dom"
import { LiteralUnion } from "type-fest"

import { Path } from "@/app/AppRouter"

interface CompanyNavigationProps {
  companyId: string
}

function CompanyNavigation(props: CompanyNavigationProps) {
  const companyPath = `/companies/${props.companyId}`

  return (
    <div className="company-navigation">
      <CompanyNavigationCard title="OCB settings" to={companyPath + "/ocbs"} />
      <CompanyNavigationCard title="Menu settings" to={companyPath + "/menus"} />
      <CompanyNavigationCard title="Editor" to="/wiki/figma-editor" />
      <CompanyNavigationCard title="Settings" to={companyPath + "/settings"} />
    </div>
  )
}

interface CompanyNavigationCardProps {
  title: ReactNode
  to: LiteralUnion<Path, string>
}

function CompanyNavigationCard(props: CompanyNavigationCardProps) {
  return (
    <div className="company-navigation-card">
      <div className="company-navigation-card__title">{props.title}</div>

      <Link className="ghost" to={props.to} />
    </div>
  )
}

export default CompanyNavigation
