import AuthBoundary from "@/app/boundaries/AuthBoundary"
import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import UserBoundary from "@/app/boundaries/UserBoundary"
import HeaderOwner from "@/app/components/Header/HeaderOwner"
import CompanyNavLayout from "@/app/layouts/CompanyNavLayout/CompanyNavLayout"
import { CompanyNavigation, CompanyPreviewContainer, CompanySelectContainer } from "@/areas/company"
import { UserRole } from "@/entities/user"
import useSearchState from "@/utils/hooks/useSearchState"

function CompaniesPage() {
  const [companyId] = useSearchState("company-id")

  return (
    <AuthBoundary>
      <UserBoundary minRole={UserRole.StreamManager} fallback="Not enough permission">
        <CompanyNavLayout sidebar={(
          <DefaultBoundaries>
            <CompanySelectContainer />
          </DefaultBoundaries>
        )}>
          {companyId != null && (
            <>
              <div style={{ position: "absolute", top: "10em" }}>
                <DefaultBoundaries>
                  <CompanyPreviewContainer size="big" theme="light" id={companyId} />
                </DefaultBoundaries>
              </div>
              <CompanyNavigation companyId={companyId} />
            </>
          )}
        </CompanyNavLayout>
      </UserBoundary>
    </AuthBoundary>
  )
}

export default CompaniesPage

CompaniesPage.header = () => <HeaderOwner />
