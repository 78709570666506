import { useModalWindow } from "react-modal-global"

import { Company } from "@/entities/company/company.types"
import PopupConfirmLayout from "@/services/Modal/layouts/PopupConfirmLayout/PopupConfirmLayout"
import CompanyList from "@/shared/components/extrinsic/CompanyList/CompanyList"
import Button from "@/shared/components/intrinsic/Button/Button"


interface PopupConfirmCompanyRestoreProps {
  companies: Company[]
  onSubmit?(): Promise<void> | void
}

function PopupConfirmCompanyRestore(props: PopupConfirmCompanyRestoreProps) {
  const modal = useModalWindow()

  function onSubmit() {
    props.onSubmit?.()
    modal.close()
  }
  return (
    <PopupConfirmLayout
      title="Restoring a company"
      description="By clicking confirm you will be sending restoring request to STREAM:"
      button={<Button color="green" await onClick={onSubmit}>Confirm restoring</Button>}

      width="50em"
    >
      <CompanyList companies={props.companies} />
    </PopupConfirmLayout>
  )
}

export default PopupConfirmCompanyRestore
