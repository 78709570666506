import "./Header.scss"

import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import { UserWidgetContainer } from "@/areas/auth"

import Logo from "../Logo/Logo"

function Header() {
  return (
    <header className="header">
      <Logo />
      <DefaultBoundaries small><UserWidgetContainer /></DefaultBoundaries>
    </header>
  )
}

export default Header
