import { useSuspenseQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"

import { Pageable } from "@/app/types"
import AuditLogDao from "@/entities/audit-log/audit-log.dao"
import { AuditLogFiltersDTO } from "@/entities/audit-log/audit-log.dto"
import { AuditLogSortKey } from "@/entities/audit-log/audit-log.types"

import LogsTable from "../components/AuditLogTable/AuditLogTable"


interface AuditLogContainerProps extends Pageable {
  filters?: Partial<AuditLogFiltersDTO>
}

function AuditLogContainer(props: AuditLogContainerProps) {
  const [sortBy, setSortBy] = useState<AuditLogSortKey>("timestamp")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc")
  const { page, size: pageSize } = props.pagedControl

  const { data: auditLog } = useSuspenseQuery({
    queryFn: () => AuditLogDao.findAll({ ...props.filters, page, pageSize, sortBy, sortOrder }),
    queryKey: [AuditLogDao, page, pageSize, props.filters, sortBy, sortOrder]
  })

  useEffect(() => props.pagedControl.setTotal(auditLog.total), [props.pagedControl.setTotal, auditLog])

  function sort(key: AuditLogSortKey) {
    setSortBy(key)
    setSortOrder(order => {
      if (sortBy === key) {
        return order === "desc" ? "asc" : "desc"
      }
      return "asc"
    })
  }

  return (
    <LogsTable entries={auditLog.items} onSort={sort} sortBy={sortBy} sortOrder={sortOrder} />
  )
}

export default AuditLogContainer
