// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from "@generouted/react-router/client";

export type Path =
  | `/`
  | `/audit-log`
  | `/audit-log/:id`
  | `/companies`
  | `/companies/:id`
  | `/companies/:id/menus`
  | `/companies/:id/ocbs`
  | `/companies/:id/settings`
  | `/menu-preview/:designId`
  | `/ocb-preview/:app`
  | `/stream/settings`
  | `/ui`
  | `/user/authorize`
  | `/user/login`
  | `/user/password/new`
  | `/user/password/reset`
  | `/user/profile/change-password`
  | `/user/profile/settings`
  | `/wiki/figma-editor`
  | `/wiki/figma/actions`
  | `/wiki/figma/companies+locations`
  | `/wiki/figma/designs/page`
  | `/wiki/figma/designs/publish`
  | `/wiki/figma/designs/refresh`
  | `/wiki/figma/examples`
  | `/wiki/figma/fonts/install`
  | `/wiki/figma/fonts/page`
  | `/wiki/figma/menu-items/page`
  | `/wiki/figma/search`
  | `/wiki/figma/shortcuts`
  | `/wiki/pos/connect`
  | `/wiki/ui/selector`;

export type Params = {
  "/audit-log/:id": { id: string };
  "/companies/:id": { id: string };
  "/companies/:id/menus": { id: string };
  "/companies/:id/ocbs": { id: string };
  "/companies/:id/settings": { id: string };
  "/menu-preview/:designId": { designId: string };
  "/ocb-preview/:app": { app: string };
};

export type ModalPath = never;

export const { Link, Navigate } = components<Path, Params>();
export const { useModals, useNavigate, useParams } = hooks<
  Path,
  Params,
  ModalPath
>();
export const { redirect } = utils<Path, Params>();
