export interface POSIntegration {
  name: string
  clientId: string
  config?: Partial<POSIntegrationConfig>
}

export interface POSIntegrationConfig extends POSCBSNorthStarIntegrationConfig {
  baseUrl: string
  authUrl: string
  username: string
  password: string
  orgShortName: string
  locRef: string
  rvcRef: string
  displayName: string
  displayPriceName: string
  url: string
  token: string
}

export interface POSCBSNorthStarIntegrationConfig {
  access_key: string
  base_url: string
  site_id: string
}

export enum POSName {
  TableNeeds = "TableNeeds",
  Oracle = "Oracle",
  CBSNorthStar = "CBSNorthStar",
}

// POS Popup types

export type DefaultFields = {
  posName: string,
  clientId: string,
}

export type POSField =
  | "authUrl"
  | "baseUrl"
  | "username"
  | "password"
  | "orgShortName"
  | "locRef"
  | "rvcRef"
  | "url"
  | "token"
  | "displayName"
  | "displayPriceName"
  | "access_key"
  | "site_id"
  | "base_url";

export type POSInfoFields = {
  Oracle: DefaultFields & Partial<Record<POSField, string>>;
  TableNeeds: DefaultFields & Partial<Record<POSField, string>>;
  CBSNorthStar: DefaultFields & Partial<Record<POSField, string>>;
};

export type POSAction = "CREATE" | "UPDATE" | "REFRESH"
