import toast from "react-hot-toast"

import { StreamAPI } from "@/api/stable/APIStable"
import { mapUser } from "@/api/stable/mappings/user"
import LocalStorageKey from "@/services/LocalStorage/LocalStorageKey"
import store from "@/store/store"
import FileUtils from "@/utils/transform/file"
import JWT from "@/utils/transform/jwt"
import observableLocalStorage from "@/utils/transform/observableLocalStorage"

import { UserActions } from "./reducer"
import { UserCreateDTO, UserLoginDTO, UserNewPasswordDTO, UserPasswordUpdateDTO, UserUpdateDTO } from "./user.dto"
import { User } from "./user.types"

import { CompanyPeopleCreateDTO } from "../company/people/company-people.dto"
import DAO from "../dao"
import ToastResult from "../ToastResult.decorator"

class UserDAO extends DAO {
  @ToastResult("User", "Invite", "Invited")
  async create(dto: UserCreateDTO): Promise<User> {
    const response = await StreamAPI.fetch.POST["/users"]({
      body: {
        username: dto.email,
        firstName: dto.firstName,
        lastName: dto.lastName,
        role: dto.role + 1, // API enums start from 1.
        companyId: dto.companyId
      }
    })

    const user = mapUser(response.payload)
    return user
  }

  async findById(id: string): Promise<User> {
    const response = await StreamAPI.fetch.GET["/users"]({ queryParams: { _id: id } })

    const user = mapUser(response.payload)
    return user
  }
  async findAll(): Promise<User[]> {
    return []
  }

  async findStreamMembers(): Promise<User[]> {
    const response = await StreamAPI.fetch.GET["/users/stream"]({})

    const users = response.payload.map(mapUser)
    return users
  }

  @ToastResult("Your Profile", "Update", "Updated")
  async update(dto: Partial<UserUpdateDTO>): Promise<void> {
    const user = store.getState().user

    await this.updateById(user.id, dto)

    store.dispatch(UserActions.update(dto))
  }

  @ToastResult("User", "Update", "Updated")
  async updateById(id: string, dto: Partial<UserUpdateDTO>): Promise<void> {
    await StreamAPI.fetch.PATCH["/users"]({
      body: { ...dto, id }
    })
  }

  @ToastResult("Avatar", "Update", "Updated")
  async updateAvatar(file: File): Promise<void> {
    await StreamAPI.fetch.POST["/users/change-avatar"]({
      body: FileUtils.toFormData(file, "avatar"),
    })
    store.dispatch(UserActions.update({
      avatar: await FileUtils.toURLData(file)
    }))
  }

  @ToastResult("Password", "Update", "Updated")
  async updatePassword(dto: UserPasswordUpdateDTO): Promise<void> {
    await StreamAPI.fetch.POST["/users/change-password"]({ body: dto })
  }

  @ToastResult("Verification", "Process", "Passed")
  async verify(dto: UserNewPasswordDTO): Promise<unknown> {
    return await StreamAPI.fetch.POST["/users/confirm"]({ body: dto })
  }
  @ToastResult("Password Recovery", "Process", "Completed")
  async recoverPassword(dto: UserNewPasswordDTO): Promise<unknown> {
    return await StreamAPI.fetch.POST["/users/reset-password"]({ body: dto })
  }
  @ToastResult("Password Recovery Email", "Sending", "Sent")
  async sendPasswordRecoveryEmail(email: string): Promise<void> {
    await StreamAPI.fetch.POST["/users/forgot-password"]({ body: { email }, noSecurity: true })
  }

  @ToastResult("Login", "process", "was successful")
  async logIn(dto: UserLoginDTO): Promise<void> {
    const response = await StreamAPI.fetch.POST["/auth/login"]({
      body: {
        username: dto.email,
        password: dto.password,
      },
      noSecurity: true
    })

    const userToken = response.payload.accessToken!
    observableLocalStorage.setItem(LocalStorageKey.UserToken, userToken)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userTokenJWT = new JWT<any>(userToken)
    const userId = userTokenJWT.payload.userData.id
    const user = await this.findById(userId)

    store.dispatch(UserActions.update(user))
  }
  async logOut(): Promise<void> {
    const user = store.getState().user
    if (user.signed === false) return

    store.dispatch(UserActions.reset())
    toast.success("You've been logged out")
    observableLocalStorage.removeItem(LocalStorageKey.UserToken)
  }

  @ToastResult("Company Employees", "Invite", "Invited")
  async createBulk(dtos: CompanyPeopleCreateDTO[]): Promise<void> {
    await Promise.all(dtos.map(dto => {
      return StreamAPI.fetch.POST["/users"]({
        body: {
          username: dto.email,
          firstName: dto.firstName,
          lastName: dto.lastName,
          role: dto.role + 1,
        }
      })
    }))
  }
}

export default new UserDAO
