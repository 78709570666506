import { QueryClientError } from "@/api/QueryError"
import { StreamAPI } from "@/api/stable/APIStable"
import { mapMenuAppInstance } from "@/api/stable/mappings/menu"
import { HTTPStatus } from "@/api/types"
import DAO from "@/entities/dao"
import ToastError from "@/entities/ToastError.decorator"
import ToastResult from "@/entities/ToastResult.decorator"

import { MenuAppCreateDTO } from "./menu.dto"
import { MenuAppInstance } from "./menu.types"



class MenuAppDAO extends DAO {
  @ToastResult("Menu App", "creation", "created")
  async create(dto: MenuAppCreateDTO): Promise<MenuAppInstance> {
    const response = await StreamAPI.fetch.POST["/screens"]({
      body: {
        appId: dto.name,
        company: dto.companyId,
        location: dto.locationId,
        type: "MENU"
      }
    })


    const menu = mapMenuAppInstance(response.payload)
    return menu
  }

  async findById(id: string): Promise<MenuAppInstance> {
    const response = await StreamAPI.fetch.GET["/menu/{id}"]({ pathParams: { id } })

    const instance = mapMenuAppInstance(response.payload)
    return instance
  }
  async findByLocation(locationId: string): Promise<MenuAppInstance[]> {
    try {
      const response = await StreamAPI.fetch.GET["/menu/location/{locationId}"]({ pathParams: { locationId } })

      const instances = response.payload.map(mapMenuAppInstance)
      return instances
    } catch (error) {
      if (error instanceof QueryClientError) {
        if (error.status === HTTPStatus.NotFound) return []
      }

      throw error
    }
  }

  @ToastError("Menu App", "design assigning")
  async assignDesign(appId: string, designId: string): Promise<void> {
    await StreamAPI.fetch.POST["/menu/assign-design"]({ body: { id: appId, designId } })
  }
  @ToastError("Menu App", "creation")
  async delete(name: string): Promise<void> {
    await StreamAPI.fetch.DELETE["/screens/{appId}"]({ pathParams: { appId: name } })
  }
}

export default MenuAppDAO
