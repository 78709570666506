import { ErrorInfo } from "react"

import { ErrorBoundaryError, ErrorBoundaryReset } from "@/app/boundaries/ErrorBoundary/ErrorBoundary.types"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Modal from "@/services/Modal/Modal"
import Button from "@/shared/components/intrinsic/Button/Button"
import Buttons from "@/shared/layouts/Buttons/Buttons"

import ClientError from "./ClientError"


function ClientErrorFallback(reset: ErrorBoundaryReset, error?: ErrorBoundaryError, errorInfo?: ErrorInfo) {
  return (
    <ClientError title={error?.name} onReset={reset}>
      <p>{error?.message}</p>
      <Buttons>
        <Button onClick={() => Modal.open(() => (
          <PopupLayout>
            <h2>Component Stack</h2>
            <pre>{errorInfo?.componentStack}</pre>
          </PopupLayout>
        ))}>Show Details</Button>
        <Button onClick={reset}>Retry</Button>
      </Buttons>
    </ClientError>
  )
}

export default ClientErrorFallback
