import { useNavigate } from "react-router-dom"

import UserBoundary from "@/app/boundaries/UserBoundary"
import { Onboarding } from "@/areas/onboarding"
import { UserRole } from "@/entities/user"
import { useAppSelector } from "@/store/hooks"

export default function Home() {
  const navigate = useNavigate()

  const user = useAppSelector(state => state.user)
  if (user.role >= UserRole.StreamManager) {
    navigate("/companies", { replace: true })
    return null
  }

  if (user.role >= UserRole.CompanyManager) {
    navigate("/companies/" + user.companyId, { replace: true })
    return null
  }

  return (
    <UserBoundary>
      <Onboarding />
    </UserBoundary>
  )
}
