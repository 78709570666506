import { StreamAPI } from "@/api/stable/APIStable"
import { mapCompany } from "@/api/stable/mappings/company"
import FileUtils from "@/utils/transform/file"

import { CompanyCreateDTO, CompanyFiltersDTO, CompanyUpdateDTO } from "./company.dto"
import { Company } from "./company.types"
import CompanyPeopleDAO from "./people/company-people.dao"

import DAO from "../dao"
import ToastResult from "../ToastResult.decorator"
import { UserDao } from "../user"

class CompanyDAO extends DAO {
  people = new CompanyPeopleDAO

  private userDao = UserDao.disableToasts()

  private async createBase(dto: Omit<CompanyCreateDTO, "companyLogo">): Promise<{ id: string }> {
    const response = await StreamAPI.fetch.POST["/company"]({
      body: {
        name: dto.companyName,
        email: dto.owner?.email
      }
    })

    return {
      id: response.payload._id
    }
  }
  @ToastResult("Company", "Create", "Created")
  async create(dto: CompanyCreateDTO) {
    const company = await this.createBase(dto)

    if(dto.owner?.email)
    {
      await Promise.all([
        dto.companyLogo && this.updateLogo(company.id, dto.companyLogo),
        this.userDao.create({ ...dto.owner, companyId: company.id })])

      return
    }
    
    dto.companyLogo && await this.updateLogo(company.id, dto.companyLogo)
    
  }

  async find(id: string): Promise<Company> {
    const response = await StreamAPI.fetch.GET["/company/{_id}"]({
      pathParams: { _id: id }
    })

    const company = mapCompany(response.payload)
    return company
  }
  async findAll(filters?: Partial<CompanyFiltersDTO>): Promise<Company[]> {
    const response = await StreamAPI.fetch.GET["/company"]({
      queryParams: { name: filters?.name ?? "" }
    })

    const companies = response.payload.map(mapCompany)
    return companies
  }

  @ToastResult("Company", "Update", "Updated")
  async update(id: string, dto: Partial<CompanyUpdateDTO>): Promise<void> {
    await Promise.all([
      StreamAPI.fetch.PATCH["/company/{_id}"]({
        body: { name: dto.companyName },
        pathParams: { _id: id }
      }),
      dto.companyLogo && this.updateLogo(id, dto.companyLogo)
    ])
  }
  private async updateLogo(id: string, file: File): Promise<void> {
    await StreamAPI.fetch.POST["/company/change-logo"]({
      body: FileUtils.toFormData(file, "logo"),
      queryParams: { company_id: id }
    })
  }

  @ToastResult("Company", "Restore", "Restored")
  async restore(id: string): Promise<void> {
    await StreamAPI.fetch.POST["/user-company/restoreCompany/{companyId}"]({ pathParams: { companyId: id } })
  }
  @ToastResult("Company", "Delete", "Deleted")
  async delete(id: string): Promise<void> {
    await StreamAPI.fetch.DELETE["/user-company/deleteCompany/{companyId}"]({ pathParams: { companyId: id } })
  }
}

const CompanyDao = new CompanyDAO
export default CompanyDao
