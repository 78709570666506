import { clamp } from "lodash"
import { useState } from "react"

export interface PagedControl {
  page: number
  pageAmount: number
  size: number
  total: number

  atFirstPage: boolean
  atLastPage: boolean

  next(): void
  prev(): void

  setPage(page: number): void
  setSize(size: number): void
  setTotal(total: number): void
}

function usePagedControl(initPage: number, initPageSize: number): PagedControl {
  const [page, setPage] = useState(initPage)
  const [size, setSize] = useState(initPageSize)
  const [total, setTotal] = useState(initPageSize)

  function prev() {
    setPage(page - 1)
  }
  function next() {
    setPage(page + 1)
  }
  function $setPage(page: number) {
    setPage(clamp(page, 1, pageAmount))
  }

  function $setSize(value: number) {
    if (value < 1) {
      setSize(1)
      return
    }

    setSize(value)
  }

  const pageAmount = Math.ceil(total / size)

  const atFirstPage = page === 1
  const atLastPage = page >= pageAmount

  return { page, size, total, pageAmount, atFirstPage, atLastPage, prev, next, setPage: $setPage, setSize: $setSize, setTotal }
}

export default usePagedControl
