import "./TabLink.scss"

import { omit } from "lodash"
import { HTMLAttributes, useContext } from "react"

import { Themeable } from "@/app/types"
import { classWithModifiers } from "@/utils/common"

import tabRouterContext from "./tabRouterContext"

import useTheme from "../Theme/useTheme"

interface TabLinkProps extends HTMLAttributes<HTMLButtonElement>, Themeable {
  to: string | number
  verification?: () => (boolean | PromiseLike<boolean>)
}

function TabLink(props: TabLinkProps) {
  const [tab, setTab] = useContext(tabRouterContext)
  const theme = useTheme(props.theme)

  async function onClick() {
    if (props.verification) {
      if (await props.verification() === false) {
        return
      }
    }

    setTab(props.to)
  }

  const className = props.className ?? "tab-link"
  return (
    <button type="button" {...omit(props, "verification")} className={classWithModifiers(className, theme, props.to === tab && "active")} onClick={onClick}>
      {props.children}
    </button>
  )
}

export default TabLink
