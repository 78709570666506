import "./CompanySelect.scss"

import { useSuspenseQuery } from "@tanstack/react-query"

import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import CompanyDao from "@/entities/company/company.dao"
import { CompanyCreateDTO, CompanyFiltersDTO } from "@/entities/company/company.dto"
import { CompanyBase } from "@/entities/company/company.types"
import Modal from "@/services/Modal/Modal"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"
import Field from "@/shared/components/intrinsic/Field/Field"
import { inputValue } from "@/utils/common"
import useSearch from "@/utils/hooks/useSearch"
import useSearchState from "@/utils/hooks/useSearchState"

import PopupNewCompany from "../../modals/PopupNewCompany"
import CompanyPreview from "../CompanyPreview/CompanyPreview"

interface CompanySelectProps {
  onChange?(company: CompanyBase): void
  onCreate?(dto: CompanyCreateDTO): void
}

function CompanySelect(props: CompanySelectProps) {
  const [search, setSearch] = useSearchState("company-search")
  const [selectedId, setSelectedId] = useSearchState("company-id")

  function onSelect(company: CompanyBase) {
    setSelectedId(company.id)
    props.onChange?.(company)
  }

  function onCreate() {
    Modal.open(PopupNewCompany, { onSubmit: props.onCreate })
  }

  return (
    <div className="company-select">
      <h3 className="company-select__title">Please, choose the company:</h3>
      <div className="company-select__search">
        <Field iconLeft="loupe" placeholder="Search companies" defaultValue={search} onChange={inputValue(setSearch)} />
        <ButtonIcon color="white" outline name="plus" ariaLabel="Create new company" onClick={onCreate} />
      </div>
      <DefaultBoundaries deps={[search]}>
        <CompaniesContainer activeId={selectedId} filters={{ name: search }} onSelect={onSelect} />
      </DefaultBoundaries>
    </div>
  )
}

export default CompanySelect


interface CompaniesContainerProps {
  activeId?: string | null
  filters?: Partial<CompanyFiltersDTO>

  onSelect?(company: CompanyBase): void
}

function CompaniesContainer(props: CompaniesContainerProps) {
  const { data: companies } = useSuspenseQuery({
    queryFn: () => CompanyDao.findAll(props.filters),
    queryKey: [CompanyDao.name, "all", props.filters]
  })

  const searchName = props.filters?.name ?? ""
  const search = useSearch(searchName)
  companies.sort((a, b) => b.id.localeCompare(a.id))


  const hasCompanies = companies.length > 0

  return (
    <div className="company-select__companies">
      {companies.map(company => (
        <button onClick={() => props.onSelect?.(company)} key={company.id}>
          <CompanyPreview {...company} active={props.activeId === company.id} highlight={search.highlight} />
        </button>
      ))}
      {!hasCompanies && searchName.length === 0 && (
        <p>There are no companies here yet :(</p>
      )}
      {!hasCompanies && searchName.length > 0 && (
        <p>No companies were found</p>
      )}
    </div>
  )
}
