
import { QueryClientError } from "@/api/QueryError"
import { StreamAPI } from "@/api/stable/APIStable"
import { mapOCBAppInstance, mapOCBAppPreview } from "@/api/stable/mappings/ocb"
import { HTTPStatus } from "@/api/types"
import DAO from "@/entities/dao"
import ToastError from "@/entities/ToastError.decorator"
import FormDataEnhanced from "@/utils/transform/FormDataEnhanced"

import { OCBAppCreateDTO, OCBAppUpdateDTO } from "./ocb.dto"
import { OCBAppInstance, OCBAppPreview } from "./ocb.types"


class OCBAppDAO extends DAO {
  @ToastError("OCB App", "creation")
  async create(dto: OCBAppCreateDTO): Promise<OCBAppPreview> {
    const response = await StreamAPI.fetch.POST["/screens"]({
      body: {
        appId: dto.name,
        company: dto.companyId,
        location: dto.locationId,
        type: "OCB"
      }
    })


    const ocb = mapOCBAppPreview(response.payload)
    return ocb
  }

  async findByName(name: string): Promise<OCBAppInstance> {
    const response = await StreamAPI.fetch.GET["/ocbapp/{appId}"]({ pathParams: { appId: name } })

    const instance = await mapOCBAppInstance(response.payload)
    return instance
  }
  async findByLocation(locationId: string): Promise<OCBAppPreview[]> {
    try {
      const response = await StreamAPI.fetch.GET["/ocbapp/location/{locationId}"]({ pathParams: { locationId } })

      const instances = response.payload.map(mapOCBAppPreview)
      return instances
    } catch (error) {
      if (error instanceof QueryClientError) {
        if (error.status === HTTPStatus.NotFound) return []
      }

      throw error
    }
  }

  @ToastError("OCB App", "updating")
  async update(name: string, dto: OCBAppUpdateDTO): Promise<void> {
    const { order, welcome } = dto.views ?? {}

    const { backgrounds: welcomeBackgrounds, logo: welcomeLogo, video: welcomeVideo, ...welcomeRest } = welcome ?? {}
    const { backgrounds: orderBackgrounds, logo: orderLogo, ...orderRest } = order ?? {}

    const formData = new FormDataEnhanced({
      // General
      appId: name,

      // Welcome View
      welcomeScreen: welcomeRest,
      logo: welcomeLogo,
      video: welcomeVideo,
      welcome: welcomeBackgrounds,

      // Order View
      orderScreen: orderRest,
      orderLogo,
      order: orderBackgrounds,
      orderTimeout: orderRest.orderTimeout,
      totalScreenEnabled: orderRest.totalScreenEnabled,
      totalScreenTimeout: orderRest.totalScreenTimeout
    })

    await formData.renameFilesToHashes()
    await StreamAPI.fetch.POST["/ocbapp"]({ body: formData })
  }
  @ToastError("OCB App", "creation")
  async delete(name: string): Promise<void> {
    await StreamAPI.fetch.DELETE["/screens/{appId}"]({ pathParams: { appId: name } })
  }
}

export default OCBAppDAO
