import queryClient from "@/api/client"
import { StreamAPI } from "@/api/stable/APIStable"
import { mapUser } from "@/api/stable/mappings/user"
import DAO from "@/entities/dao"
import ToastResult from "@/entities/ToastResult.decorator"
import { UserDao } from "@/entities/user"

import { CompanyPeopleCreateDTO, CompanyPeopleUpdateDTO } from "./company-people.dto"
import { CompanyPerson } from "./company-people.types"


class CompanyPeopleDAO extends DAO {
  private userDao = UserDao.disableToasts()

  @ToastResult("Company Employees", "Invite", "Invited")
  async createBulk(companyId: string, dtos: CompanyPeopleCreateDTO[]): Promise<void> {
    await Promise.all(dtos.map(dto => {
      return StreamAPI.fetch.POST["/users"]({
        body: {
          username: dto.email,
          firstName: dto.firstName,
          lastName: dto.lastName,
          role: dto.role + 1,
          companyId
        }
      })
    }))
  }
  async findAll(companyId: string): Promise<CompanyPerson[]> {
    const response = await StreamAPI.fetch.GET["/users/company-people"]({ queryParams: { companyId } })
    return response.payload.map(mapUser)
  }
  @ToastResult("Company Employee", "Update", "Updated")
  async updateById(userId: string, dto: Partial<CompanyPeopleUpdateDTO>): Promise<void> {
    await this.userDao.updateById(userId, dto)
  }
  @ToastResult("Company Employees", "Delete", "Deleted")
  async deleteBulk(userIds: string[]): Promise<void> {
    await StreamAPI.fetch.DELETE["/user-company/deleteUsers"]({ body: { userIds } })
  }
  @ToastResult("Company Employee", "Restore", "Restored")
  async restoreById(userId: string): Promise<void> {
    await StreamAPI.fetch.POST["/user-company/restoreUsers"]({
      body: { userIds: [userId] }
    })
  }

  @ToastResult("Company Employee", "Reinvite", "Reinvited")
  async resendInvitation(dto: CompanyPerson): Promise<void> {
    await StreamAPI.fetch.POST["/users/resend-invitation-link"]({
      body: {
        id: dto.id,
        username: dto.email,
        role: dto.role + 1, // API enums start from 1.
        companyId: dto?.companyId
      }
    })
  }

  private updateUserQueryById(companyId: string, userId: string, newUser: Partial<CompanyPerson>) {
    queryClient.setQueryData<CompanyPerson[]>(
      [this.name, companyId, this.findAll.name],
      users => users?.map(user => user.id === userId ? { ...user, ...newUser } : user)
    )
  }
}

export default CompanyPeopleDAO
