import "@streamllc/shared/views.css"

import { DesignViewer, MenuDesign } from "@streamllc/figma-design-viewer"
import { useSuspenseQuery } from "@tanstack/react-query"
import { useEffect, useMemo } from "react"
import { useSearchParam } from "react-use"

import DesignDAO from "@/entities/design/design.dao"
import { loadGoogleFont, loadPrivateFonts } from "@/entities/fonts/fonts.helpers"
import useParam from "@/utils/hooks/useParam"



const designDao = new DesignDAO

function OCBPreview() {
  const designId = useParam("designId")
  const companyId = useSearchParam("companyId")

  if (companyId == null) {
    throw new TypeError("`companyId` must not be null")
  }

  const { data: design } = useSuspenseQuery({
    queryFn: () => designDao.findById(designId),
    queryKey: [designDao, designId]
  })

  const menuDesign = useMemo(() => new MenuDesign(JSON.parse(design.json)), [design])

  useEffect(() => {
    menuDesign.fonts.forEach(font => loadGoogleFont(font.family))
    void loadPrivateFonts(companyId)
  }, [menuDesign, companyId])

  function getMediaUrl(hash: string): string {
    const url = new URL(`designImage-${companyId}-${hash}`, import.meta.env.VITE_STORAGE_URL)
    return url.toString()
  }

  return (
    <DesignViewer
      design={menuDesign}
      resolvers={{
        imageHash: getMediaUrl,
        transformText: content => content,
        transformWidget() { }
      }}
      options={{ fit: "contain" }}
    />
  )
}

export default OCBPreview

OCBPreview.header = () => <></>
