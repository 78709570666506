import "react-modal-global/styles/modal.scss"
import "@/assets/scss/reset.scss"
import "@/assets/scss/base.scss"
import "@/services/Modal/Modal.scss"

import { Outlet } from "react-router-dom"

import { PageComponentProps } from "@/app/AppRoutes"
import AppServices from "@/app/AppServices"
import Header from "@/app/components/Header/Header"
import BaseLayout from "@/app/layouts/BaseLayout/BaseLayout"


export default function Base(props: PageComponentProps) {
  return (
    <BaseLayout>
      {props.Component?.header?.() || <Header />}
      <main>
        <Outlet />
      </main>

      <AppServices />
    </BaseLayout>
  )
}
