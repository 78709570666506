import "./PasswordField.scss"

import { omit } from "lodash"

import { Themeable } from "@/app/types"
import useTheme from "@/services/Theme/useTheme"
import Field, { FieldProps } from "@/shared/components/intrinsic/Field/Field"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import { classWithModifiers } from "@/utils/common"

export interface PasswordFieldViewProps extends FieldProps, Themeable {
  hidden: boolean
  onHiddenToggle(): void
}

function PasswordFieldView(props: PasswordFieldViewProps) {
  const theme = useTheme(props.theme)

  const iconButton = (
    <button type="button" onClick={props.onHiddenToggle} tabIndex={-1}>
      <Icon
        name={props.hidden ? "eye-crossed" : "eye"}
        className={classWithModifiers("password-field__icon", props.hidden && "hidden", props.customValidity && "black", theme)}
      />
    </button>
  )

  return (
    <div className="password-field">
      <Field
        {...omit(props, "hidden", "onHiddenToggle")}

        type={props.hidden ? "password" : "text"}
        iconRight={iconButton}
      />
    </div>
  )
}

export default PasswordFieldView
