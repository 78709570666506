import "./MenuAppsGroup.scss"

import { MenuAppInstance, MenuAppType, MenuClient } from "@/entities/app/menu/menu.types"
import Tumbler from "@/shared/components/extrinsic/Tumbler/Tumbler"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import { classWithModifiers } from "@/utils/common"


interface MenuAppsGroupProps {
  appId: string
  instances: MenuAppInstance[]

  active?: boolean
  instanceId?: string
  activeList: MenuClient[]
  onSelect?(id: string): void
}

function MenuAppsGroup(props: MenuAppsGroupProps) {

  return (
    <div className="menu-apps-group">
      {props.instances.map(instance => (
        <div key={instance.id} className="app-instance">
          <div className="app-instance__header">
            <div className="app-instance-title">{instance.type === MenuAppType.MENU ? "Menu App 1" : "Menu App 2"}</div>
            <div className="app-instance__actions">
              <div className="app-instance__toggle">
                <button
                  type="button"
                  className={classWithModifiers("menu-apps-group__instance", props.instanceId === instance.id && "active")}
                  onClick={() => props.onSelect?.(instance.id)}
                  key={instance.id}
                >
                  <Icon name="image" />
                </button>

                <Tumbler checked={props.activeList.some((app) => (app.appType === instance.type))} readonly={true} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MenuAppsGroup
