import { useNavigate } from "react-router-dom"

import { LoginFormContainer } from "@/areas/auth"
import { useAppSelector } from "@/store/hooks"
import useRedirection from "@/utils/hooks/useRedirection"

function UserLogin() {
  const navigate = useNavigate()
  const redirection = useRedirection()
  const user = useAppSelector(state => state.user)

  if (user.signed && redirection == null) {
    navigate("/", { replace: true })
    return null
  }

  return (
    <LoginFormContainer />
  )
}

export default UserLogin
