import "./MenuDesignPreviews.scss"

import { Highlightable } from "@/app/types"
import { Design } from "@/entities/design"

import MenuDesignPreviewContainer from "../MenuDesignPreview/MenuDesignPreviewContainer"



export interface MenuDesignPreviewsProps extends Partial<Highlightable> {
  designs: Design[]
  companyId: string

  selected?: Design | null
  onSelect?(design: Design): void
}

function MenuDesignPreviews(props: MenuDesignPreviewsProps) {
  return (
    <div className="menu-design-previews">
      {props.designs.map(design => (
        <MenuDesignPreviewContainer
          {...design}
          companyId={props.companyId}

          selected={props.selected?.id === design.id}
          onSelect={() => props.onSelect?.(design)}

          highlight={props.highlight}
          key={design.id}
        />
      ))}
    </div>
  )
}

export default MenuDesignPreviews
