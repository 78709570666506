import { OrderViewLayout } from "@streamllc/shared/ocb"
import { FormEvent, useEffect, useMemo } from "react"
import ZodForm from "react-zod-form"
import { coerce, z } from "zod"

import { OCBAppViews } from "@/entities/app/ocb/ocb.types"
import ColorPicker from "@/shared/components/extrinsic/ColorPicker/ColorPicker"
import FileSelector from "@/shared/components/intrinsic/FileSelector/FileSelector"
import FileSelectorMultiple from "@/shared/components/intrinsic/FileSelector/FileSelectorMultiple"
import Selector from "@/shared/components/intrinsic/Selector/Selector"
import { optionsFromEnum } from "@/shared/components/intrinsic/Selector/Selector.helpers"
import useLayoutConfig from "@/utils/hooks/useLayoutConfig"

import FontFamilySelector from "../components/FontFamilySelector"
import FontSizeSelector from "../components/FontSizeSelector"
import SettingsGroup from "../components/SettingsGroup/SettingsGroup"
import SettingsRow from "../components/SettingsRow/SettingsRow"
import useTabSettings from "../hooks/useTabSettings"

const form = new ZodForm({
  fontFamily: coerce.string(),
  primaryTextSize: coerce.number(),
  primaryTextColor: coerce.string(),
  secondaryTextSize: coerce.number(),
  secondaryTextColor: coerce.string(),
  logo: z.instanceof(File).optional(),
  backgrounds: z.array(z.instanceof(File)).default([]),
  layout: z.nativeEnum(OrderViewLayout).default(OrderViewLayout.Layout1),
  primaryColor: coerce.string(),
  secondaryColor: coerce.string(),
})

form.object._type satisfies OCBAppViews["order"]

function OrderTabSettings() {
  const [order, setOrder] = useTabSettings("order")
  const initialOrderConfig = useMemo(() => order, [])
  const config = useLayoutConfig(order.layout ?? OrderViewLayout.Layout1)

  useEffect(() => {
    if (order.layout !== initialOrderConfig.layout) {
      setOrder(DEFAULT_ORDER_STYLE)
      return
    }

    setOrder(initialOrderConfig)
  }, [order.layout])

  function onChange(event: FormEvent<HTMLFormElement>) {
    const field = form.parseCurrentField(event)
    if (field == null) return

    setOrder({ [field.name]: field.value })
  }

  return (
    <form onChange={onChange}>
      <SettingsRow title="Layout">
        <Selector placeholder="Layout" name={form.fields.layout} value={order.layout}>
          {optionsFromEnum(OrderViewLayout)}
        </Selector>
      </SettingsRow>
      <SettingsGroup title="TEXT">
        {config?.primaryFontFamily && (
          <FontFamilySelector name={form.fields.fontFamily} value={order.fontFamily} />
        )}
        <SettingsRow title="PRIMARY" bordered>
          {config?.primarySize && (
            <FontSizeSelector start={14} end={50} step={2} name={form.fields.primaryTextSize} value={order.primaryTextSize} />
          )}
          {config?.primaryColor && (
            <ColorPicker label="Color" name={form.fields.primaryTextColor} value={order.primaryTextColor} />
          )}
        </SettingsRow>
        <SettingsRow title="SECONDARY" bordered>
          {config?.secondarySize && (
            <FontSizeSelector start={12} end={50} step={2} name={form.fields.secondaryTextSize} value={order.secondaryTextSize} />
          )}
          {config?.secondaryColor && (
            <ColorPicker label="Color" name={form.fields.secondaryTextColor} value={order.secondaryTextColor} />
          )}
        </SettingsRow>
      </SettingsGroup>
      <SettingsGroup title="BACKGROUND">
        {config?.backgroundImages && (
          <FileSelectorMultiple
            label="Images"
            accept="image/*"
            clearable

            name={form.fields.backgrounds}
            value={order.backgrounds}
          />
        )}
        {config?.logo && (
          <FileSelector name={form.fields.logo} value={order.logo} defaultValue={order.logo} accept="image/*" clearable label="Logo" />
        )}
        <SettingsRow title="Colors" bordered>
          {config?.primaryBackgroundColor && (
            <ColorPicker name={form.fields.primaryColor} value={order.primaryColor} label="Primary" upwards />
          )}
          {config?.secondaryBackgroundColor && (
            <ColorPicker name={form.fields.secondaryColor} value={order.secondaryColor} label="Secondary" upwards />
          )}
        </SettingsRow>
      </SettingsGroup>
    </form>
  )
}

export default OrderTabSettings

const DEFAULT_ORDER_STYLE = {
  primaryTextSize: 20,
  secondaryTextSize: 12,
  fontFamily: "",
  backgrounds: [],
  logo: undefined,
  primaryTextColor: "black",
  secondaryTextColor: "gray",
  primaryColor: "white",
  secondaryColor: "white"
}
