import { castArray } from "lodash"

import { StreamAPI } from "@/api/stable/APIStable"
import { mapLocation } from "@/api/stable/mappings/location"

import { LocationSearchDTO, LocationUpdateDTO } from "./location.dto"
import { QLocation } from "./location.types"

import DAO from "../dao"
import ToastResult from "../ToastResult.decorator"

class LocationDAO extends DAO {
  async find(id: string): Promise<QLocation> {
    const response = await StreamAPI.fetch.GET["/location/{_id}"]({
      pathParams: { _id: id },
    })

    const location = mapLocation(response.payload)
    return location
  }
  async findAll(search?: LocationSearchDTO): Promise<QLocation[]> {
    const response = await StreamAPI.fetch.GET["/location"]({
      queryParams: { ...search }
    })

    const locations = response.payload.map(mapLocation)
    return locations
  }

  @ToastResult("Location", "Create", "Created")
  async create(name: string, companyId: string): Promise<QLocation> {
    const response = await StreamAPI.fetch.POST["/location"]({
      body: { name, companyId }
    })

    const location = mapLocation(response.payload)
    return location
  }

  @ToastResult("Location", "Update", "Updated")
  async update(id: string, dto: LocationUpdateDTO) {
    await StreamAPI.fetch.POST["/location/{_id}"]({
      pathParams: {
        _id: id
      },
      body: {
        name: dto.title
      },
    })
  }

  @ToastResult("Location", "Delete", "Deleted")
  async delete(id: string) {
    await StreamAPI.fetch.DELETE["/location/{_id}"]({
      pathParams: {
        _id: id
      },
    })
  }

  async getScreens(id: string) {
    const response = await StreamAPI.fetch.GET["/screens/location/{_id}"]({
      pathParams: {
        _id: id
      },
    })

    return response.payload
  }

  async getLocationScreens(locationId: string): Promise<any[]> {
    const screens = await LocationDao.getScreens(locationId)
    return castArray(screens)
  }
}

const LocationDao = new LocationDAO()
export default LocationDao
