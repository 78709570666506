import "./PasswordField.scss"

import { useMemo, useState } from "react"
import { useEffect } from "react"

import { Themeable } from "@/app/types"
import Fields from "@/shared/layouts/Fields/Fields"
import { inputValue, toggleState } from "@/utils/common"

import PasswordFieldView from "./PasswordFieldView"

interface NewPasswordFieldsProps extends Themeable {
  name?: string
  customValidity?: string
  onConfirm?(matched: boolean): void
}

function NewPasswordFields(props: NewPasswordFieldsProps) {
  const [firstPasswordHidden, setFirstPasswordHidden] = useState(true)
  const [secondPasswordHidden, setSecondPasswordHidden] = useState(true)

  const [firstPassword, setFirstPassword] = useState<string>("")
  const [secondPassword, setSecondPassword] = useState<string | null>(null)

  const passwordsMatched = useMemo(() => firstPassword === secondPassword, [firstPassword, secondPassword])
  useEffect(() => props.onConfirm?.(passwordsMatched), [passwordsMatched])

  return (
    <Fields>
      <PasswordFieldView
        theme={props.theme}
        autoComplete="new-password"

        name={props.name}
        placeholder="Enter new password"
        customValidity={props.customValidity}

        hidden={firstPasswordHidden}
        onHiddenToggle={toggleState(setFirstPasswordHidden)}

        onChange={inputValue(setFirstPassword)}
      />

      <PasswordFieldView
        theme={props.theme}
        autoComplete="new-password"

        placeholder="Confirm your password"
        customValidity={secondPassword && !passwordsMatched && "Passwords mismatch"}

        hidden={secondPasswordHidden}
        onHiddenToggle={toggleState(setSecondPasswordHidden)}

        onChange={inputValue(setSecondPassword)}
      />
    </Fields>
  )
}

export default NewPasswordFields
