import "./ProfileGeneralInfo.scss"

import { Company, CompanyStatus } from "@/entities/company/company.types"
import { UserRole } from "@/entities/user"
import CompanyStatusLabel from "@/shared/components/extrinsic/CompanyStatusLabel/CompanyStatusLabel"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"
import Table from "@/shared/components/intrinsic/Table/Table"
import { useAppSelector } from "@/store/hooks"


interface ProfileGeneralInfoProps {
  company: Company

  onUpdate?(): void
  onRestore?(): void
  onDelete?(): void
}

function ProfileGeneralInfo(props: ProfileGeneralInfoProps) {
  const user = useAppSelector((state) => state.user)
  const minEditingRole = user.role >= UserRole.CompanyOwner
  const minDeletingRole = user.role === UserRole.StreamOwner
  const minCompanyRestoreRole = user.role === UserRole.StreamOwner

  return (
    <Table className="profile-general-info">
      <thead>
        <tr>
          <th>Name</th>
          <th>Logo</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{props.company.title}</td>
          <td><img className="profile-general-info__logo" src={props.company.logo} alt="company logo" /></td>
          <td>
            <div className="profile-general-info__label">
              <CompanyStatusLabel deletedAt={props.company.deletedAt} status={props.company.status} />
              {props.company.status === CompanyStatus.Archived && minCompanyRestoreRole && (
                <button className="profile-general-info__restore" type="button" onClick={props.onRestore}>Restore</button>
              )}
            </div>
          </td>
          <td>
            <div className="profile-general-info__actions">
              {minEditingRole&&(
                <ButtonIcon
                  name="pen"
                  size="small"
                  color="transparent"
                  ariaLabel="Edit"
                  onClick={props.onUpdate}
                />)}
              {minDeletingRole &&(
                <ButtonIcon
                  name="trash-bin"
                  size="small"
                  color="transparent"
                  ariaLabel="Delete"
                  onClick={props.onDelete}
                />)}
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default ProfileGeneralInfo
