import "./PersonList.scss"

import { CompanyPerson } from "@/entities/company/people/company-people.types"

import PersonRole from "../PersonRole/PersonRole"

interface PersonListProps {
  people: CompanyPerson[]
}

function PersonList(props: PersonListProps) {
  return (
    <div className="person-list">
      {props.people.map((person, index) => (
        <div className="person-list__item" key={index}>
          <div className="person-list__name">{person.firstName}</div>
          <div className="person-list__surname">{person.lastName}</div>
          <div className="person-list__role">
            <PersonRole role={person.role} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default PersonList
