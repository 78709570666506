import { useEffect, useMemo, useState } from "react"
import { io, Socket } from "socket.io-client"

const sockets = new Map<string, Socket>()

function useSocket(url: string) {
  const socket = useMemo(() => {
    if (!sockets.has(url)) {
      sockets.set(url, io(url))
    }

    return sockets.get(url)!
  }, [url])

  const [status, setStatus] = useState<"pending" | "connected" | "disconnected">("pending")
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    socket.on("connect", () => setStatus("connected"))
    socket.on("disconnect", reason => {
      setError(new Error(reason))
      setStatus("disconnected")
    })

    return () => {
      socket.disconnect()
      sockets.delete(url)
    }
  }, [url])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function on(event: string, listener: (...args: any[]) => void) {
    socket.on(event, listener)
  }

  function emit(event: string, ...args: unknown[]) {
    socket.emit(event, ...args)
  }

  function connect() {
    if (socket.connected) return

    socket.connect()
  }

  return { on, emit, connect, status, error }
}

export default useSocket
