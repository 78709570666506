import { useModalWindow } from "react-modal-global"

import { Company } from "@/entities/company/company.types"
import PopupConfirmLayout from "@/services/Modal/layouts/PopupConfirmLayout/PopupConfirmLayout"
import CompanyList from "@/shared/components/extrinsic/CompanyList/CompanyList"
import Button from "@/shared/components/intrinsic/Button/Button"


interface PopupConfirmCompanyDeleteProps {
  companies: Company[]
  onSubmit?(): Promise<void> | void
}

function PopupConfirmCompanyDelete(props: PopupConfirmCompanyDeleteProps) {
  const modal = useModalWindow()

  function onSubmit() {
    props.onSubmit?.()
    modal.close()
  }
  return (
    <PopupConfirmLayout
      title="Deleting a company"
      description="When a company is deleted, its owner and manager(s) will no longer have access to company’s data.  You can restore the company account within 30 days after deletion. Continue?"
      button={<Button color="red" await onClick={onSubmit}>Confirm deleting</Button>}

      width="50em"
    >
      <CompanyList companies={props.companies} />
    </PopupConfirmLayout>
  )
}

export default PopupConfirmCompanyDelete
