import "./PersonStatus.scss"

import { addDays, differenceInDays } from "date-fns"
import { kebabCase, startCase } from "lodash"

import { UserStatus } from "@/entities/user"
import { classWithModifiers } from "@/utils/common"

interface PersonStatusProps {
  status: UserStatus
  deleted: boolean
  deletedAt?: string
}

function PersonStatus(props: PersonStatusProps) {
  const statusName = UserStatus[props.status]

  let daysLeft = null
  if (props.status === UserStatus.Archived && props.deletedAt) {
    const deletedAtDate = new Date(props.deletedAt)
    const deletionDate = addDays(deletedAtDate, 30)
    const now = new Date()
    daysLeft = differenceInDays(deletionDate, now)
  }

  if (daysLeft !== null) {
    return (
      <div className={classWithModifiers("person-status", kebabCase(statusName))}>
        {startCase(statusName)} ({daysLeft} days before deletion)
      </div>
    )
  }

  return (
    <div className={classWithModifiers("person-status", kebabCase(statusName))}>
      {startCase(statusName)}
    </div>
  )
}

export default PersonStatus
