import { generatePreservedRoutes, generateRegularRoutes } from "@generouted/react-router/core"
import { createContext, ReactElement, useContext, useEffect, useLayoutEffect, useState } from "react"
import type { RouteObject } from "react-router-dom"
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom"

type ReactComponent<Props = never> = (props: Props) => ReactElement

export interface PageComponentProps {
  Component?: PageComponent | null
}

interface PageComponent {
  (): ReactElement
  [x: string]: () => void
}

interface PageModule {
  default: PageComponent
}

const PRESERVED = import.meta.glob<PageModule>("/src/pages/(_base|404).tsx", { eager: true })
const ROUTES = import.meta.glob<PageModule>(["/src/pages/**/[\\w[-]*.tsx", "!**/(_base|404).*"], { eager: true })

const preservedRoutes = generatePreservedRoutes<PageModule>(PRESERVED) as Partial<Record<"_base" | "404", PageModule>>
const regularRoutes = generateRegularRoutes<RouteObject, Partial<PageModule>>(ROUTES, (module, path) => {
  const index: boolean = path.endsWith("index.tsx") && !path.includes("pages/index")

  const pathDirectory = path.slice(0, path.lastIndexOf("/"))
  const pathLayout = pathDirectory + "/_layout.tsx"

  const isLayout = path === pathLayout
  const hasLayout = pathLayout in ROUTES

  const layoutModule = ROUTES[pathLayout] as PageModule | undefined

  function Component() {
    const [, setComponent] = useContext(baseContext)
    useEffect(() => {
      if (hasLayout && !isLayout) {
        setComponent({ Component: layoutModule?.default })
        return
      }

      setComponent({ Component: module.default })
    }, [])

    if (module?.default == null) {
      return <Outlet />
    }

    return <module.default />
  }

  return { index, Component }
})


const _base = preservedRoutes?.["_base"] as { default: ReactComponent<{ Component?: ReactComponent }> }
const _404 = preservedRoutes?.["404"]


const baseContext = createContext<ReturnType<typeof useState<{ Component?: ReactComponent }>>>([undefined, () => { }])

function Base() {
  const [Component, setComponent] = useState<{ Component?: ReactComponent }>()

  return (
    <baseContext.Provider value={[Component, setComponent]}>
      {_base.default == null && <Outlet />}
      {_base.default && (
        <_base.default Component={Component?.Component} />
      )}
    </baseContext.Provider>
  )
}

const base = { Component: Base }

function Fallback() {
  const [, setComponent] = useContext(baseContext)
  useLayoutEffect(() => setComponent({ Component: _404?.default }), [])

  if (_404?.default == null) {
    return <Outlet />
  }

  return <_404.default />
}
const fallback: RouteObject = { path: "*", Component: Fallback }

const routes: RouteObject[] = [{ ...base, children: [...regularRoutes, fallback] }]
const AppRoutes = () => <RouterProvider router={createBrowserRouter(routes)} />

export default AppRoutes
