import { Outlet, useMatch } from "react-router-dom"

import AuthBoundary from "@/app/boundaries/AuthBoundary"
import UserBoundary from "@/app/boundaries/UserBoundary"
import HeaderCompany from "@/app/components/Header/HeaderCompany"
import { UserRole } from "@/entities/user"

function Layout() {
  return (
    <AuthBoundary>
      <UserBoundary minRole={UserRole.CompanyManager} fallback="Not enough permission">
        <Outlet />
      </UserBoundary>
    </AuthBoundary>
  )
}

export default Layout

Layout.header = () => {
  const match = useMatch("/companies/:id/*")
  const companyId = match?.params.id
  if (companyId == null) return null

  return <HeaderCompany id={companyId} />
}
