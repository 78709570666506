import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import PageLayout from "@/app/layouts/PageLayout/PageLayout"
import { AuditLogDetailsContainer } from "@/areas/audit-log"
import useParam from "@/utils/hooks/useParam"

function AuditLogDetailsPage() {
  const id = useParam("id")

  return (
    <PageLayout>
      <h2>Audit Log</h2>
      <DefaultBoundaries>
        <AuditLogDetailsContainer id={id} />
      </DefaultBoundaries>
    </PageLayout>
  )
}

export default AuditLogDetailsPage
