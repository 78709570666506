import { useState } from "react"

import DesignDAO from "@/entities/design/design.dao"

import MenuDesignPreview, { MenuDesignPreviewProps } from "./MenuDesignPreview"


const designDao = new DesignDAO


interface MenuDesignPreviewContainerProps extends Omit<MenuDesignPreviewProps, "onNameChange"> { }

function MenuDesignPreviewContainer(props: MenuDesignPreviewContainerProps) {
  const [name, setName] = useState(props.name)

  function onNameChange(newName: string) {
    setName(newName)
    designDao.updateNameById(props.id, newName)
  }

  return (
    <MenuDesignPreview {...props} name={name} onNameChange={onNameChange} />
  )
}

export default MenuDesignPreviewContainer
