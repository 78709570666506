import { useLayoutEffect } from "react"
import { Outlet } from "react-router-dom"

import { UserDao } from "@/entities/user"

function UserPasswordLayout() {
  // Log out before the first paint.
  useLayoutEffect(() => void UserDao.logOut(), [])

  return <Outlet />
}

export default UserPasswordLayout
