import { Observable } from "react-use/lib/useObservable"

import StreamAPISocket from "@/api/stable/APISocket"

import { MenuClient } from "./menu.types"

class OnlineMenuAppSource implements Observable<MenuClient[]>, Disposable {
  private readonly source = new StreamAPISocket("AdminPanel")

  constructor(readonly companyId: string) { }

  subscribe(callback: (onlineIds: MenuClient[]) => void) {
    this.source.socket.emitWithAck("CONNECTED_MENUS", { companyId: this.companyId }).then((instances: MenuClient[]) => {
      callback(instances)
    })

    const unsubscribe = this.source.on("CONNECTED_MENU_APPS", next => {
      if (this.companyId !== next.companyId) return

      callback(next.connected)
    })
    return { unsubscribe }
  }

  [Symbol.dispose]() {
    this.source[Symbol.dispose]()
  }
}

export default OnlineMenuAppSource
