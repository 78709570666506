import { Link } from "react-router-dom"

import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Notice from "@/shared/components/extrinsic/Notice/Notice"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

import FontsVariantsPNG from "./font-variants.png"
import FontsAddedPNG from "./fonts-added.png"
import FontsNewPNG from "./fonts-new.png"
import FontsUploadPNG from "./fonts-upload.png"
import FontsWarningPNG from "./fonts-warning.png"

function WikiFigmaFonts() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Figma Plugin | Fonts Page</h2>
      </TextBox>
      <Article>
        <p>Fonts page is made to contain your custom fonts and those that are not available publicly.</p>
        <h3>Upload new one</h3>
        <p>To upload a font, click (+) in the table</p>
        <img src={FontsNewPNG} />
        <p>Drag & Drop, paste or choose font files from your computer</p>
        <img src={FontsUploadPNG} />
        <p>Then you can go back and see the font is added</p>
        <img src={FontsAddedPNG} />
        <h3>See variants</h3>
        <p>Click on 4-dots icon to see all added variants related to the font</p>
        <img src={FontsVariantsPNG} />
        <Notice icon="info">If there are missing font variants, you can see exact missing ones in this list</Notice>
        <h3>Missing fonts</h3>
        <p>If you have fonts that are not installed on your machine, you will see a warning</p>
        <img src={FontsWarningPNG} />
        <p>See the guide <Link to="/wiki/figma/fonts/install">how to install fonts</Link></p>
      </Article>
    </ArticleLayout>
  )
}

export default WikiFigmaFonts
