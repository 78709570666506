import "./SettingsRow.scss"

import { ReactNode } from "react"

import { classWithModifiers } from "@/utils/common"

interface SettingsRowProps {
  title?: ReactNode
  children: ReactNode

  bordered?: boolean
}

function SettingsRow(props: SettingsRowProps) {
  return (
    <div className={classWithModifiers("settings-row", props.bordered && "bordered")}>
      {props.title && (
        <div className="settings-row__title">{props.title}</div>
      )}
      <div className="settings-row__container">{props.children}</div>
    </div>
  )
}

export default SettingsRow
