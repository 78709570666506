import "./AuditLogFilters.scss"

import { FormEvent } from "react"
import ZodForm from "react-zod-form"
import { coerce, z } from "zod"

import { AuditLogFiltersDTO } from "@/entities/audit-log/audit-log.dto"
import Button from "@/shared/components/intrinsic/Button/Button"
import Checkbox from "@/shared/components/intrinsic/Checkbox/Checkbox"
import Field from "@/shared/components/intrinsic/Field/Field"
import Time from "@/utils/transform/time"



const form = new ZodForm({
  fromDate: z.string().optional().transform(value => value ? new Date(value) : undefined),
  toDate: z.string().optional().transform(value => value ? new Date(value) : undefined),

  username: coerce.string().optional(),

  actions: z.array(z.enum(["create", "update", "delete", "restore", "refresh"])).optional(),
})

interface AuditLogFiltersProps {
  onSubmit?(filters: Partial<AuditLogFiltersDTO>): void
  default?: Partial<AuditLogFiltersDTO>
}

function AuditLogFilters(props: AuditLogFiltersProps) {
  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const fields = form.parseAllFields(event)
    if (fields == null) return

    props.onSubmit?.(fields)
  }

  const actions = props.default?.actions ?? []
  function dateValue(date?: Date | null): string | undefined {
    if (date == null) return

    return `${date.getFullYear()}-${Time.addZero(date.getMonth())}-${Time.addZero(date.getDate())}`
  }
  return (
    <form className="audit-log-filters" onSubmit={onSubmit}>
      <Field type="date" name="fromDate" defaultValue={dateValue(props.default?.fromDate)}>from</Field>
      <Field type="date" name="toDate" defaultValue={dateValue(props.default?.toDate)}>To</Field>
      <Field name="username" placeholder="Username" defaultValue={props.default?.username} />

      <Checkbox name="actions" value="create" defaultChecked={actions.includes("create")}>Create</Checkbox>
      <Checkbox name="actions" value="update" defaultChecked={actions.includes("update")}>Update</Checkbox>
      <Checkbox name="actions" value="delete" defaultChecked={actions.includes("delete")}>Delete</Checkbox>
      <Checkbox name="actions" value="restore" defaultChecked={actions.includes("restore")}>Restore</Checkbox>
      <Checkbox name="actions" value="refresh" defaultChecked={actions.includes("refresh")}>Refresh</Checkbox>

      <Button type="submit" size="smaller" color="dark" iconRight="loupe">Search</Button>
    </form>
  )
}

export default AuditLogFilters
