import { FormEvent, useState } from "react"
import { Link } from "react-router-dom"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { z } from "zod"

import Button from "@/shared/components/intrinsic/Button/Button"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import useFormStatus from "@/utils/hooks/useFormStatus"

import { passwordPattern } from "../../helpers"
import NewPasswordFields from "../PasswordField/NewPasswordFields"


const form = new ZodForm({
  password: z.coerce.string().regex(passwordPattern, "Password must contain at least 8 characters, including: upper case, lower case, number and a special character")
})


interface NewPasswordFormProps {
  onSubmit?(password: string): boolean | Promise<boolean>
}

function NewPasswordForm(props: NewPasswordFormProps) {
  const [passwordConfirmed, setPasswordConfirmed] = useState(false)
  const [operationSuccessful, setOperationSuccessful] = useState(false)

  const formStatus = useFormStatus(form, onSubmit, { disabled: !passwordConfirmed })
  const { fieldIssues } = useZodFormIssues(form)

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (fields == null) return

    const success = await props.onSubmit?.(fields.password)
    setOperationSuccessful(success??false)
  }

  return (
    <form onChange={form.parseAllFields.bind(form)} onSubmit={formStatus.submit}>
      <NewPasswordFields
        name={form.fields.password}
        customValidity={fieldIssues.password}
        theme="light"
        onConfirm={setPasswordConfirmed}
      />
      <Button size="big" type="submit" color={operationSuccessful ? "green" : undefined} {...formStatus} disabled={operationSuccessful}>
        {operationSuccessful ? (
          <>
           Password set successful <Icon name="check" />
          </>
        ) : (
          "Set password"
        )}
      </Button>

      {operationSuccessful && (
        <Link to="/user/login" style={{ position: "absolute", top: "200px", right: "101px", color: "#404043", textDecoration: "underline" }}>
          Redirecting to the login page in 5 sec...
        </Link>
      )}
    </form>
  )
}

export default NewPasswordForm
