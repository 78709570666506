import { FormEvent, useState } from "react"
import ZodForm, { useZodFormIssues } from "react-zod-form"

interface UseFormStatusOptions {
  disabled: boolean
}

function useFormStatus<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends ZodForm<any, any>,
  Callback extends (event: FormEvent<HTMLFormElement>) => void | Promise<void>
>(form: Form, callback?: Callback, options?: Partial<UseFormStatusOptions>) {
  const [pending, setPending] = useState(false)
  const { issues } = useZodFormIssues(form)

  const disabled = issues.length > 0 || !!options?.disabled

  async function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    setPending(true)

    try {
      await callback?.(event)
    } finally {
      setPending(false)
    }
  }

  return {
    pending,
    disabled,

    submit
  }
}

export default useFormStatus
