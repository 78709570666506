import "./Onboarding.scss"

import ButtonLink from "@/shared/components/intrinsic/Button/ButtonLink"


function Onboarding() {
  return (
    <div className="onboarding">
      <h2>New to OCB?</h2>
      <div className="onboarding__container">
        <p className="onboarding__text">
          Let’s add your first screen:
        </p>
        <div className="onboarding__list">
          <span>1. Login in OCB App</span>
          <span>2. Finish setup in OCB App</span>
        </div>
        <ButtonLink to="/user/login">Log in</ButtonLink>
      </div>
    </div>
  )
}

export default Onboarding
