import "./PersonRole.scss"

import { kebabCase, startCase } from "lodash"

import { UserRole } from "@/entities/user"
import { classWithModifiers } from "@/utils/common"

interface PersonRoleProps {
  role: UserRole
}

function PersonRole(props: PersonRoleProps) {
  const roleName = UserRole[props.role]

  return (
    <div className={classWithModifiers("person-role", kebabCase(roleName))}>
      {startCase(roleName)}
    </div>
  )
}

export default PersonRole
