import { Dispatch } from "react"

import useSettings from "./useSettings"

import { OCBViewCustomizations } from "../types"

function useTabSettings<Tab extends keyof OCBViewCustomizations>(tab: Tab): [Partial<OCBViewCustomizations[Tab]>, Dispatch<Partial<OCBViewCustomizations[Tab]>>] {
  const { settings, updateTabSettings } = useSettings()

  function setTabSettings(tabSettings: Partial<OCBViewCustomizations[Tab]>) {
    updateTabSettings(tab, tabSettings)
  }

  return [settings[tab] || {}, setTabSettings]
}

export default useTabSettings
