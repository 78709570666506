export interface CompanyBase {
  id: string
  title: string
  logo: string
}

export interface Company extends CompanyBase {
  status: CompanyStatus
  owner: {
    id: string
    firstName: string
    lastName: string
    email: string
  }
  deletedAt?: string
}

export enum CompanyStatus {
  Archived,
  Active,
  PendingOwnerInvite,
  PendingDeletionRequest,
  pendingRestorationRequest,
}
