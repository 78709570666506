import { ErrorInfo } from "react"

import { ErrorBoundaryError, ErrorBoundaryReset } from "@/app/boundaries/ErrorBoundary/ErrorBoundary.types"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Modal from "@/services/Modal/Modal"
import Button from "@/shared/components/intrinsic/Button/Button"
import Buttons from "@/shared/layouts/Buttons/Buttons"

import ClientErrorInline from "./ClientErrorInline"


function ClientErrorInlineFallback(reset: ErrorBoundaryReset, error?: ErrorBoundaryError, errorInfo?: ErrorInfo) {
  return (
    <ClientErrorInline title={error?.name} onReset={reset}>
      <p>{error?.message}</p>
      <Buttons>
        <Button size="smaller" onClick={() => Modal.open(() => (
          <PopupLayout>
            <pre>
              {errorInfo?.componentStack}
            </pre>
          </PopupLayout>
        ))}>Details</Button>
        <Button size="smaller" onClick={reset}>Retry</Button>
      </Buttons>
    </ClientErrorInline>
  )
}

export default ClientErrorInlineFallback
