import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Table from "@/shared/components/intrinsic/Table/Table"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"


function WikiFigmaShortcuts() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Figma Plugin | Keyboard Shortcuts</h2>
      </TextBox>
      <Article>
        <Table>
          <thead>
            <tr>
              <th>Command</th>
              <th>Keybinding</th>
              <th>When</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Go to Designs Page</td>
              <td><kbd>Alt</kbd> + <kbd>1</kbd></td>
              <td>Navigation bar is visible</td>
            </tr>
            <tr>
              <td>Go to Menu Items Page</td>
              <td><kbd>Alt</kbd> + <kbd>2</kbd></td>
              <td>Navigation bar is visible</td>
            </tr>
            <tr>
              <td>Go to Media Page</td>
              <td><kbd>Alt</kbd> + <kbd>3</kbd></td>
              <td>Navigation bar is visible</td>
            </tr>
            <tr>
              <td>Go to Fonts Page</td>
              <td><kbd>Alt</kbd> + <kbd>4</kbd></td>
              <td>Navigation bar is visible</td>
            </tr>
            <tr>
              <td>Enable Search bar</td>
              <td><kbd>Alt</kbd> + <kbd>F</kbd></td>
              <td>Navigation bar is visible</td>
            </tr>
            <tr>
              <td>Focus deeper elements</td>
              <td>Hold <kbd>Ctrl</kbd> + Hover</td>
              <td>Within Menu Items page</td>
            </tr>
            <tr>
              <td>Close/Stop active operation</td>
              <td><kbd>Esc</kbd></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Article>
    </ArticleLayout>
  )
}

export default WikiFigmaShortcuts
