import { Link } from "react-router-dom"

import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Table from "@/shared/components/intrinsic/Table/Table"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

import ActionsPlacePNG from "./actions-place.png"

function WikiFigmaActions() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Figma Plugin | Actions</h2>
      </TextBox>
      <Article>
        <p>There are pages that can be triggered from Figma UI itself, they are placed in the bottom of the right panel.</p>
        <img src={ActionsPlacePNG} />
        <Table>
          <thead>
            <tr>
              <th>Action</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><Link to="/wiki/figma/designs/publish">Publishing</Link></td>
              <td>Allows saving and publishing selected designs</td>
            </tr>
            <tr>
              <td><Link to="/wiki/figma/designs/refresh">Refreshing</Link></td>
              <td>Allows refreshing the elements for selected designs</td>
            </tr>
          </tbody>
        </Table>
      </Article>
    </ArticleLayout>
  )
}

export default WikiFigmaActions
