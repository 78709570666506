
import CompanyDao from "@/entities/company/company.dao"
import { CompanyCreateDTO } from "@/entities/company/company.dto"
import { CompanyBase } from "@/entities/company/company.types"

import CompanySelect from "./CompanySelect"

interface CompanySelectContainerProps {
  onChange?(company: CompanyBase): void
}

function CompanySelectContainer(props: CompanySelectContainerProps) {
  async function onCreate(dto: CompanyCreateDTO) {
    await CompanyDao.create(dto)
  }

  return <CompanySelect onCreate={onCreate} onChange={props.onChange} />
}

export default CompanySelectContainer
