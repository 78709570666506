import "./Buttons.scss"

import { ReactNode } from "react"

import { classWithModifiers } from "@/utils/common"

interface ButtonsProps {
  children: ReactNode

  spaced?: boolean
  centered?: boolean
}

function Buttons(props: ButtonsProps) {
  return (
    <div className={classWithModifiers("buttons", props.centered && "centered", props.spaced && "spaced")}>{props.children}</div>
  )
}

export default Buttons
