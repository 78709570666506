import "./Selector.scss"

import { ReactNode, useRef } from "react"
import { LoaderIcon } from "react-hot-toast"

import { Themeable } from "@/app/types"
import useTheme from "@/services/Theme/useTheme"
import { classWithModifiers, inputValue } from "@/utils/common"
import { triggerReactInput } from "@/utils/react"

import { HeadLessSelectorProps } from "./Selector.types"
import useHeadLessSelector from "./useHeadLessSelector"

import Button from "../Button/Button"
import DropDown from "../DropDown/DropDown"
import FieldAdaptive from "../Field/FieldAdaptive"
import Icon from "../Icon/Icon"


interface SelectorProps<V> extends HeadLessSelectorProps<V>, Themeable {
  name?: string
  label?: ReactNode
  placeholder?: string
  createPending?: boolean
}

function Selector<V = string | undefined>(props: SelectorProps<V>) {
  const theme = useTheme(props.theme)
  const namedInputRef = useRef<HTMLInputElement>(null)

  const {
    elementRef,

    expanded,
    setExpanded,
    collapse,

    selected,
    selectedOptions,
    filterPredicate,
    select,

    search,
    searchVisible,
    onSearch,

    shouldCreate,
    onCreate
  } = useHeadLessSelector(props)

  const createButton = (
    <Button size="smaller" color="white" disabled={props.createPending} await onClick={onCreate}>
      {`Create "${search}"`}
      {props.createPending && <LoaderIcon />}
    </Button>
  )

  const searchRef = useRef<HTMLInputElement>(null)

  function onClick() {
    setExpanded(state => !state)
    searchRef.current?.focus()
  }

  function onSelect(values: V[]) {
    select(values)
    collapse()

    props.onChange?.(values[0])

    if (namedInputRef.current == null) return
    triggerReactInput(namedInputRef.current, "value", values[0])
  }

  return (
    <div className="selector" ref={elementRef}>
      {props.label && (
        <div className="selector__label">{props.label}</div>
      )}
      <button className={classWithModifiers("selector__appearance", theme, expanded && "expanded")} type="button" onClick={onClick}>
        {selectedOptions.length <= 0 && (
          <div className="selector__placeholder">{props.placeholder}</div>
        )}
        {selectedOptions.length >= 1 && (
          <div className="selector__current">{selectedOptions[0]?.props.children}</div>
        )}
        {searchVisible && (
          <FieldAdaptive className="selector__search" autoFocus value={search} onChange={inputValue(onSearch)} onClick={event => event.stopPropagation()} />
        )}
        {!props.expanded && (
          <Icon className={classWithModifiers("selector__icon", expanded && "up")} name="chevron-down" />
        )}
      </button>
      <DropDown
        defaultValue={props.defaultValue}

        expanded={expanded}
        onSelect={onSelect}
        value={selected}


        filterPredicate={search.length > 0 ? filterPredicate : undefined}
        bottom={props.creatable && shouldCreate && createButton}
      >
        {props.children}
      </DropDown>
      {props.name && (
        <input readOnly style={{ display: "none" }} name={props.name} value={String(props.value ?? selected[0] ?? props.defaultValue ?? "")} ref={namedInputRef} />
      )}
    </div>
  )
}

export default Selector
