import "./AppEditLayout.scss"

import { ReactNode } from "react"

import Theme from "@/services/Theme/Theme"
import { classWithModifiers } from "@/utils/common"

interface AppEditLayoutProps {
  children: ReactNode

  sidebar: ReactNode
  sidebarActive?: boolean
}

function AppEditLayout(props: AppEditLayoutProps) {
  return (
    <div className="app-edit-layout">
      <div className="app-edit-layout__container">{props.children}</div>
      <div className="app-edit-layout__sticky">
        <div className={classWithModifiers("app-edit-layout__sidebar", props.sidebarActive && "active")}>
          <Theme scheme="dark">{props.sidebar}</Theme>
        </div>
      </div>
    </div>
  )
}

export default AppEditLayout
