import "./LocationView.scss"

import { ReactNode, useState } from "react"

import { PopupEditMenuIntegration } from "@/areas/company"
import { QLocation } from "@/entities/location/location.types"
import { POSIntegration } from "@/entities/pos/pos.types"
import { UserRole } from "@/entities/user"
import Modal from "@/services/Modal/Modal"
import Expander from "@/shared/components/extrinsic/Expander/Expander"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import { useAppSelector } from "@/store/hooks"
import { classWithModifiers, toggleState } from "@/utils/common"

import PopupConfirmLocationDelete from "../../modals/PopupConfirmLocationDelete"
import PopupLocationForm from "../../modals/PopupLocationForm"


export interface LocationViewProps {
  location: QLocation
  children?: ReactNode

  defaultExpanded?: boolean

  onPOSEdit?(integration: POSIntegration): void

  onUpdate?(title: string): void
  onDelete?(): void
}

function LocationView(props: LocationViewProps) {
  const [expanded, setExpanded] = useState(props.defaultExpanded ?? false)
  const user = useAppSelector(state => state.user)

  function onPOSEdit() {
    if (props.onPOSEdit == null) return

    Modal.open(PopupEditMenuIntegration, { onSubmit: props.onPOSEdit, location: props.location })
  }

  function onTitleChange() {
    if (props.onUpdate == null) return

    Modal.open(PopupLocationForm, { onSubmit: props.onUpdate, defaultValue: props.location.title })
  }
  function onDelete() {
    if (props.onDelete == null) return

    Modal.open(PopupConfirmLocationDelete, { onSubmit: props.onDelete, location: props.location })
  }

  return (
    <div className="location-view">
      <div className="location-view__header">
        <div className="location-view__title">{props.location.title}</div>
        <div className="location-view__icons">
          <ButtonIcon size="small" color="transparent" name="gear" onClick={onPOSEdit} ariaLabel="Edit POS Config" />
          {user.role >= UserRole.CompanyOwner && (
            <>
              <ButtonIcon size="small" color="transparent" name="pen" onClick={onTitleChange} ariaLabel="Edit POS Config" />
              <ButtonIcon size="small" color="transparent" name="trash-bin" onClick={onDelete} ariaLabel="Edit POS Config" />
            </>
          )}
        </div>
        {props.children && (
          <button type="button" onClick={toggleState(setExpanded)}>
            <Icon className={classWithModifiers("location-view__trigger", expanded && "up")} name="chevron-down" />
          </button>
        )}
      </div>
      {props.children && (
        <Expander expanded={expanded}>
          <div className="location-view__container">{props.children}</div>
        </Expander>
      )}
    </div>
  )
}

export default LocationView
