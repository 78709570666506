import { useSuspenseQuery } from "@tanstack/react-query"

import { Themeable } from "@/app/types"
import CompanyDao from "@/entities/company/company.dao"

import CompanyPreview from "./CompanyPreview"


interface CompanyPreviewContainerProps extends Themeable {
  id: string

  size?: "big"
}

function CompanyPreviewContainer(props: CompanyPreviewContainerProps) {
  const { data: company } = useSuspenseQuery({
    queryFn: () => CompanyDao.find(props.id),
    queryKey: [CompanyDao.name, props.id]
  })

  return <CompanyPreview {...props} {...company} />
}

export default CompanyPreviewContainer
