import { ReactNode } from "react"

import ErrorCover from "@/shared/components/extrinsic/ErrorCover/ErrorCover"

interface ClientErrorInlineProps {
  title: ReactNode
  children?: ReactNode

  onReset?(): void
}

function ClientErrorInline(props: ClientErrorInlineProps) {
  return (
    <ErrorCover size="small">
      {props.children}
    </ErrorCover>
  )
}

export default ClientErrorInline
