export interface User {
  id: string
  companyId: string

  signed: boolean
  role: UserRole
  status: UserStatus

  email: string
  firstName: string
  lastName: string

  avatar?: string
  // createdAt: Date
  deletedAt?: string
  deleted: boolean
}

/**
 * To help comparing user types, `SuperAdmin` is highest in rank for this enum.
 */
export enum UserRole {
  Guest = -1, CompanyManager, CompanyOwner, StreamManager, StreamOwner
}

export enum StreamOwnerRole { StreamManager, StreamOwner }
export enum StreamManagerRole { StreamManager }
export enum ComapanyUserRole {
  CompanyManager, CompanyOwner,
}
export enum ComapanyHasOwnerRole {
  CompanyManager,
}

export enum UserStatus {
  Active, Invited, Archived
}
