import { ReactNode } from "react"

import { UserRole } from "@/entities/user"
import { useAppSelector } from "@/store/hooks"


export interface UserBoundaryProps {
  /**
   * The least user privileges that user should have to get access.
   *
   * `undefined` means any user.
   */
  minRole?: UserRole
  /**
   * If not presented, default fallback will be used.
   */
  fallback?: ReactNode
  children: ReactNode
}

function UserBoundary(props: UserBoundaryProps) {
  const user = useAppSelector(state => state.user)

  if (props.minRole != null && user.role < props.minRole) {
    return <>{props.fallback}</>
  }

  return <>{props.children}</>
}

export default UserBoundary
