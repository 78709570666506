import { useLayoutEffect, useState } from "react"

function useBroadcastChannel(name: string) {
  const [channel, setChannel] = useState(() => new BroadcastChannel(name))

  useLayoutEffect(() => {
    setChannel(new BroadcastChannel(name))

    return () => channel.close()
  }, [name])

  return channel
}

export default useBroadcastChannel
