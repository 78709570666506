import { Link } from "react-router-dom"

import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Notice from "@/shared/components/extrinsic/Notice/Notice"
import OuterLink from "@/shared/components/intrinsic/OuterLink/OuterLink"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

import DesignsCreatePNG from "./designs-create.png"
import DesignsDropPNG from "./designs-drop.png"
import Example1PNG from "./example-1.png"
import Example2PNG from "./example-2.png"
import LocationsSelectPNG from "./locations-select.png"
import MenuItemsDropPNG from "./menu-items-drop.png"


function WikiFigmaExamples() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Figma Plugin | Examples</h2>
      </TextBox>
      <Article>
        <h3>Fantastic</h3>
        <p>First of all, {`let's`} prepare to create your first beautiful Menu design using the plugin</p>
        <ol>
          <li><OuterLink to="https://help.figma.com/hc/en-us/articles/4405328886935--Beginner-2-Create-designs">Create Figma Design</OuterLink></li>
          <li>Open the created design</li>
          <li>Open the Figma plugin and log in</li>
        </ol>
        <p>Now {`we're`} ready to start!</p>
        <li>Choose a location where you want your design to be at</li>
        <img src={LocationsSelectPNG} />
        <li>Create an empty menu design</li>
        <img src={DesignsCreatePNG} />
        <li>Drop the empty menu design into Figma Canvas</li>
        <img src={DesignsDropPNG} />

        <ol>
          <li>Reword <u>EMPTY</u> title to something like Menu</li>
          <li>Stylize it a bit by applying a cool looking font and playing with other properties</li>
          <li>Put something next to a the title to fill the space visually (e.g. your logo)</li>
        </ol>
        <img src={Example1PNG} />

        <p>Now we are going to place menu positions, in order to do that</p>
        <li>Go to Menu Items page</li>
        <li>Drop some menu items below the title</li>
        <img src={MenuItemsDropPNG} />
        <li>Resize, rearrange, align and <u>scale</u> the items to fit design the best</li>

        <h3>Finishing touches</h3>
        <p>Each design has decorations. In our case, it will be food images filling the gap on the left, but you can place them in a more stylistic way.</p>
        <li>Add your sign or slogan to the bottom to also fill the space a bit and make a good impression</li>
        <li>Last one is to put a background blur.</li>
        <img src={Example2PNG} />
        <Notice icon="check">
          This is how you can design menus, though you probably want this to be done by dedicated designers.
          Make sure to <Link to="/wiki/figma/designs/publish">publish the design</Link> to apply the changes.
        </Notice>
      </Article>
    </ArticleLayout>
  )
}

export default WikiFigmaExamples
