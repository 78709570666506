import { Primitive } from "type-fest"

import FileUtils from "./file"

import { isRecord } from "../common"

type Plain = Primitive | Record<keyof never, unknown> | (Primitive | Record<keyof never, unknown> | unknown[])[]

class FormDataEnhanced extends FormData {
  constructor(record: Record<keyof never, Plain | Blob | Blob[]>, options?: { form?: HTMLFormElement, submitter?: HTMLElement | null }) {
    super(options?.form, options?.submitter)

    Object.entries(record).forEach(([key, value]) => {
      if (value == null) return

      if (isRecord(value)) {
        super.set(key, JSON.stringify(value))
        return
      }

      if (value instanceof Blob) {
        super.set(key, value)
        return
      }
      if (value instanceof Array && value[0] instanceof Blob) {
        this.appendMany(key, value as Blob[])
        return
      }

      super.set(key, String(value))
    })
  }

  /**
   * Renames all attached files to their hashes.
   */
  async renameFilesToHashes(): Promise<void> {
    const fileEntryPromises = [...this.entries()]
      .filter(([, value]) => value instanceof Blob)
      .map(async ([key, value]) => [key, await FileUtils.renameToHash(value as Blob)] as const)
    const fileEntries = await Promise.all(fileEntryPromises)

    fileEntries.forEach(([key]) => super.delete(key))
    fileEntries.forEach(([key, file]) => super.append(key, file))
  }

  async appendHashNamedFile(key: string, file: Blob) {
    super.append(key, await FileUtils.renameToHash(file))
  }
  appendMany(key: string, values: (string | Blob)[]) {
    values.forEach(value => super.append(key, value))
  }
}

export default FormDataEnhanced
