import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

import SearchHighlightPNG from "./search-highlight.png"

function WikiFigmaSearchPage() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Figma Plugin | Search</h2>
      </TextBox>
      <Article>
        <p>The search bar can be used on any page where possible, it will work similarly.</p>
        <ul>
          <li>Type anything in the search field</li>
          <li>If there are any found entries, they will be highlighted</li>
        </ul>
        <img src={SearchHighlightPNG} />
      </Article>
    </ArticleLayout>
  )
}

export default WikiFigmaSearchPage
