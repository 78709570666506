import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { UserDao, UserLoginDTO } from "@/entities/user"
import useRedirection from "@/utils/hooks/useRedirection"

import LoginForm from "./LoginForm"

function LoginFormContainer() {
  const navigate = useNavigate()
  const redirection = useRedirection()

  const [validity, setValidity] = useState<string | undefined>(undefined)

  async function onSubmit(dto: UserLoginDTO) {
    try {
      await UserDao.logIn(dto)

      if (redirection != null) {
        navigate(redirection.path)
        return
      }

      navigate("/")
    } catch (error) {
      if (error instanceof Error) {
        setValidity(error.message)
        return
      }

      throw error
    }
  }

  function onChange() {
    setValidity(undefined)
  }

  return (
    <LoginForm validity={validity} onChange={onChange} onSubmit={onSubmit} />
  )
}

export default LoginFormContainer
