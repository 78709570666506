import "./DropDown.scss"

import { castArray } from "lodash"
import { ComponentProps, ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from "react"

import { addOrRemove, classWithModifiers, getReactNodeTextContent } from "@/utils/common"
import InfiniteScroll from "@/utils/components/InfiniteScroll"

import Icon from "../Icon/Icon"


export type DropDownOption<V = unknown> = ReactElement<ComponentProps<"option"> & { value?: V }>

interface DropDownProps<V> {
  /**
   * Open in up direction.
  */
  upwards?: boolean
  expanded: boolean
  /**
   * @default
   * true
   */
  selectable?: boolean
  multiple?: boolean

  optionClassName?: string

  value?: V | V[]
  defaultValue?: V | V[]
  onSelect(values: V[]): void

  /**
   * Filters options. Works the same as `Array.filter`.
   */
  filterPredicate?: (value: V, children: string) => unknown

  children: DropDownOption<V> | DropDownOption<V>[]
  /**
   * Placed in the end of the list.
   */
  bottom?: ReactNode
}

function DropDown<V>(props: DropDownProps<V>) {
  const options = useDropDownOptions(props)
  const elementRef = useRef<HTMLDivElement>(null)

  const [localSelected, setSelected] = useState<V[]>(() => castArray(props.defaultValue ?? []))
  // Allow controlling state from outside.
  const value = useMemo(() => props.value && castArray(props.value), [props.value])
  const selected = useMemo(() => value ?? localSelected, [value, localSelected])

  const pointer = useRef<number>(0)

  function onSelect(option: DropDownOption<V>, index: number) {
    if (option.props.disabled) return
    if (props.selectable === false) return

    pointer.current = index
    dispatchSelection(option)
  }

  function dispatchSelection(option: DropDownOption<V>) {
    if (!props.multiple) {
      props.onSelect([option.props.value as V])
      return
    }

    const newSelected = addOrRemove(selected, option.props.value)
    setSelected(newSelected)

    props.onSelect(newSelected)
  }


  /**
   * https://jsfiddle.net/cxe73c22/
   */
  function scrollIntoView(value: unknown) {
    if (elementRef.current == null) return

    const element = elementRef.current
    const parentElementRect = element.getBoundingClientRect()

    const choiceElement = element.children.namedItem(String(value))
    const choiceElementRect = choiceElement?.getBoundingClientRect()
    if (choiceElementRect == null) return

    const offsetTop = choiceElementRect.top - parentElementRect.top
    const middle = offsetTop - (parentElementRect.height / 2) + (choiceElementRect.height / 2)

    element.scrollBy(0, middle)
  }

  function loop(number: number, max: number): number {
    if (number > max) return 0
    if (number < 0) return max

    return number
  }

  function focusOption(optionIndex: number) {
    if (elementRef.current == null) return

    const choiceElement = elementRef.current.children.item(optionIndex)
    if (!(choiceElement instanceof HTMLElement)) return

    choiceElement.focus()
  }

  function onPointerChange(by: 1 | -1) {
    const newChoicePointer = pointer.current + by
    const newChoicePointerLooped = loop(newChoicePointer, options.length - 1)

    focusOption(newChoicePointerLooped)
    pointer.current = newChoicePointerLooped
  }

  function onKeyDown(event: KeyboardEvent) {
    if (!["ArrowUp", "ArrowDown"].includes(event.key)) return
    if (!props.expanded) return
    if (elementRef.current == null) return

    event.preventDefault()

    if (event.key === "ArrowUp") onPointerChange(-1)
    if (event.key === "ArrowDown") onPointerChange(+1)
  }

  function isSelected(option: DropDownOption<V>): boolean {
    return selected.includes(option.props.value as V)
  }

  useEffect(() => {
    if (!props.expanded) return

    const selectedFirst = selected.at(1)
    if (selectedFirst == null) return

    scrollIntoView(selectedFirst)
  }, [props.expanded]) // Should only be updated when `props.expanded` changes.

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown)
    return () => {
      window.removeEventListener("keydown", onKeyDown)
    }
  }, [onKeyDown])

  return (
    <div className={classWithModifiers("drop-down", props.expanded && "expanded", props.upwards && "upwards")} role="listbox" aria-expanded={props.expanded} ref={elementRef}>
      <InfiniteScroll pageSize={20} elementRef={elementRef}>
        {options.length <= 0 && (<div className="drop-down__empty"><Icon name="empty" /></div>)}
        {options.map((option, index) => !option.props.hidden && (
          <button
            className={classWithModifiers("drop-down__option", isSelected(option) && "selected")}
            onClick={() => onSelect(option, index)}
            role="option"
            type="button"
            disabled={!props.expanded}

            name={String(option.props.value)}
            key={index}
          >
            {option.props.children}
          </button>
        ))}
        {props.bottom}
      </InfiniteScroll>
    </div>
  )
}

export default DropDown

function useDropDownOptions<V>(props: DropDownProps<V>) {
  function filterOptions(options: DropDownOption<V>[]): DropDownOption<V>[] {
    if (props.filterPredicate == null) {
      return options
    }

    const filteredOptions = options.filter(option => {
      const optionValue = option.props.value as V
      const optionChildren = getReactNodeTextContent(option)

      return props.filterPredicate?.(optionValue, optionChildren)
    })
    return filteredOptions
  }

  return useMemo(() => {
    const options = castArray(props.children).flat(Infinity)
    const optionsFiltered = filterOptions(options)

    return optionsFiltered
  }, [props.children, filterOptions])
}
