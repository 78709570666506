import { FormEvent } from "react"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { z } from "zod"

import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import useFormStatus from "@/utils/hooks/useFormStatus"

import AuthLayout from "../AuthLayout/AuthLayout"


const form = new ZodForm({
  email: z.coerce.string().email("Enter correct email")
})


interface ResetPasswordFormProps {
  onSubmit?(email: string): void | Promise<void>
}

function ResetPasswordForm(props: ResetPasswordFormProps) {
  const formStatus = useFormStatus(form, onSubmit)
  const { fieldIssues } = useZodFormIssues(form)

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (fields == null) return

    await props.onSubmit?.(fields.email)
  }

  return (
    <AuthLayout title="Reset your password">
      <form onChange={form.parseAllFields.bind(form)} onSubmit={formStatus.submit}>
        <Field
          name={form.fields.email}
          customValidity={fieldIssues.email}
          placeholder="Enter your e-mail"
          theme="light"
        />
        <Button size="big" type="submit" {...formStatus}>Reset password</Button>
      </form>
    </AuthLayout>
  )
}

export default ResetPasswordForm
