import "./TabLink.scss"

import { ReactNode } from "react"

interface TabLinksProps {
  children: ReactNode
}

function TabLinks(props: TabLinksProps) {
  return (
    <div className="tab-links">{props.children}</div>
  )
}

export default TabLinks
