import "./PopupInvitesManagement.scss"

import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"

import ProfileInvitations, { ProfileInvitationsProps } from "../../components/ProfileInvitations/ProfileInvitations"

interface PopupInvitesManagementProps extends ProfileInvitationsProps { 
  isStreamSettings?: boolean
  companyId?: string
}

function PopupInvitesManagement(props: PopupInvitesManagementProps) {
  return (
    <PopupLayout title="Invite people to OCB">
      <ProfileInvitations {...props} />
    </PopupLayout>
  )
}

export default PopupInvitesManagement
