import "./Navigation.scss"

import { Link } from "react-router-dom"

import { UserRole } from "@/entities/user"
import { useAppSelector } from "@/store/hooks"

function Navigation() {
  const user = useAppSelector(state => state.user)

  return (
    <nav className="navigation">
      {<Link className="navigation__link" to={"/stream/settings"}>Open STREAM settings</Link>}
      {user.role >= UserRole.StreamManager && <Link className="navigation__link" to="/audit-log">Logs</Link>}
    </nav>
  )
}

export default Navigation
