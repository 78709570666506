import "./Header.scss"

import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import { UserWidgetContainer } from "@/areas/auth"

import Logo from "../Logo/Logo"
import Navigation from "../Navigation/Navigation"

function HeaderOwner() {
  return (
    <header className="header">
      <Logo />
      <Navigation />
      <DefaultBoundaries small><UserWidgetContainer /></DefaultBoundaries>
    </header>
  )
}

export default HeaderOwner
