import "./SettingsGroup.scss"

import { ReactNode } from "react"

interface SettingsGroupProps {
  title: ReactNode
  children: ReactNode
}

function SettingsGroup(props: SettingsGroupProps) {
  return (
    <div className="settings-group">
      <div className="settings-group__title">{props.title}</div>
      <div className="settings-group__container">{props.children}</div>
    </div>
  )
}

export default SettingsGroup
