import "./CompanyPreview.scss"

import { Highlightable, Themeable } from "@/app/types"
import { CompanyBase } from "@/entities/company/company.types"
import { classWithModifiers } from "@/utils/common"

interface CompanyPreviewProps extends CompanyBase, Partial<Highlightable>, Themeable {
  size?: "big"
  active?: boolean
}

function CompanyPreview(props: CompanyPreviewProps) {
  return (
    <div className={classWithModifiers("company-preview", props.active && "active", props.size, props.theme)}>
      <img className="company-preview__image" src={props.logo} />
      <div className="company-preview__title">{props.highlight?.(props.title) ?? props.title}</div>
    </div>
  )
}

export default CompanyPreview
