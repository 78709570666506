import "./SettingsTabsOrder.scss"

import { OrderView } from "@streamllc/shared/ocb"
import POS from "@streamllc/shared/pos"

import useTabSettings from "../../../../hooks/useTabSettings"

function SettingsTabsOrder() {
  const [orderSettings] = useTabSettings("order")

  const containerWidth = 530
  const scaleRatio = containerWidth / window.innerWidth
  return <OrderView customization={orderSettings} order={MOCK_ORDER} scaleRatio={scaleRatio} showVerticalImage />
}

export default SettingsTabsOrder

const MOCK_ORDER: POS.Order = {
  items: [
    {
      name: "Double cheese Burger",
      price: 20,
      quantity: 2,
      modifiers: [{ name: "onion rings" }]
    },
    {
      name: "Pizza",
      price: 23,
      quantity: 2,
      modifiers: [{ name: "with Olives" }, { name: "extra fries" }]
    },
    {
      name: "Double cheese Burger",
      price: 20,
      quantity: 2,
      modifiers: [{ name: "onion rings" }]
    }
  ],
  modifiers: [],
  subTotal: 0.5,

  finalPrice: 86,
  taxPrice: 10.53
}
