import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Notice from "@/shared/components/extrinsic/Notice/Notice"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

import ScreenshotFontsPNG from "./screenshot-fonts.png"
import ScreenshotFontsExistencePNG from "./screenshot-fonts-existence.png"
import ScreenshotFontsInstallPNG from "./screenshot-fonts-install.png"
import ScreenshotFontsSearchPNG from "./screenshot-fonts-search.png"
import ScreenshotTrayFigmaPNG from "./screenshot-tray-figma.png"



function FigmaInstallFontsPage() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>How to install fonts for Figma</h2>
      </TextBox>
      <Article>
        <p>In order to use custom/private fonts in Figma, you have to install them <b>locally</b>.</p>
        <li>Locate the fonts file (they will be named accordingly and have .ttf or .otf extension)</li>
        <img src={ScreenshotFontsPNG} />
        <li>Open those files and your OS (Windows, Mac or Linux) should prompt you to install fonts</li>
        <img src={ScreenshotFontsInstallPNG} />
        <li>Proceed to install</li>
        <li>To check if they are installed, search for {`"Fonts"`} in your OS search box</li>
        <img src={ScreenshotFontsSearchPNG} />
        <li>Find the right program and search for your font</li>
        <img src={ScreenshotFontsExistencePNG} />
        <TextBox>
          <h4>Using the fonts in Figma</h4>
        </TextBox>
        To allow Figma to {`"see"`} the fonts we just installed, you may need to entirely close the program that is using fonts.
        <h5><b>Browser</b></h5>
        <ul>
          <li>If you are using a browser, refreshing the tab should help</li>
          <li>If not, close the browser, and make sure {`it's`} not running in the background</li>
          <li>Open Figma in the browser again, the fonts should appear</li>
        </ul>
        <h5><b>Figma App</b></h5>
        <p>If you are using a desktop Figma App, the instructions are the same</p>
        <ul>
          <li>Close the tab or the whole Figma App and make sure {`it's`} not running in the background</li>
          <li>Open it again, the fonts should appear</li>
        </ul>
        <TextBox>
          <h4>Background Process</h4>
        </TextBox>
        <p>Usually background processes are hidden in the tray or displayed inline in the menu bar</p>
        <img src={ScreenshotTrayFigmaPNG} />
        <br />
        <Notice icon="info">In the MacOS or Linux, {`it's`} usually in the top menu bar on the right</Notice>
        <p>To close Figma App background process</p>
        <ul>
          <li>Locate it near or in the tray</li>
          <li>Left/Right click the Figma App and close/exit it</li>
        </ul>
      </Article>
    </ArticleLayout>
  )
}

export default FigmaInstallFontsPage
