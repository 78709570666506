import { useSuspenseQuery } from "@tanstack/react-query"
import { ReactElement } from "react"

import LocationDao from "@/entities/location/location.dao"
import { QLocation } from "@/entities/location/location.types"
import { UserRole } from "@/entities/user"
import Modal from "@/services/Modal/Modal"
import Button from "@/shared/components/intrinsic/Button/Button"
import { useAppSelector } from "@/store/hooks"

import LocationViews from "../components/LocationViews/LocationViews"
import PopupLocationForm from "../modals/PopupLocationForm"


export interface LocationGroupedComponentProps {
  companyId: string
  location: QLocation
}

interface LocationGroupedProps {
  companyId: string
  children(props: LocationGroupedComponentProps): ReactElement
}

function LocationGrouped(props: LocationGroupedProps) {
  const user = useAppSelector(state => state.user)

  const { data: locations, refetch } = useSuspenseQuery({
    queryFn: () => LocationDao.findAll({ companyId: props.companyId }),
    queryKey: [LocationDao.name, props.companyId],
  })

  async function onCreate(name: string) {
    await LocationDao.create(name, props.companyId)
    await refetch()
  }

  return (
    <LocationViews>
      {user.role >= UserRole.CompanyOwner && (
        <div>
          <Button size="smaller" iconLeft="plus" onClick={() => Modal.open(PopupLocationForm, { onSubmit: onCreate })}>Create location</Button>
        </div>
      )}
      {locations.toReversed().map(location => (
        <props.children companyId={props.companyId} location={location} key={location.id} />
      ))}
    </LocationViews>
  )
}

export default LocationGrouped
