import { ReactNode } from "react"

import ErrorCover from "@/shared/components/extrinsic/ErrorCover/ErrorCover"

interface ClientErrorProps {
  title: ReactNode
  children?: ReactNode

  onReset?(): void
}

function ClientError(props: ClientErrorProps) {
  return (
    <ErrorCover>
      <h3>{props.title}</h3>
      {props.children}
    </ErrorCover>
  )
}

export default ClientError
