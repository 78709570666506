import { Link } from "react-router-dom"

import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

import MenuItemDropPNG from "./menu-item-drop.png"
import MenuItemDropCtrlPNG from "./menu-item-drop-ctrl.png"
import MenuItemTagsPNG from "./menu-item-tags.png"
import MenuItemTagsNewPNG from "./menu-item-tags-new.png"

function WikiFigmaMenuItems() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Figma Plugin | Menu Items</h2>
      </TextBox>
      <Article>
        <h3>Description</h3>
        <p>Menu Items page allows you to browse the items of the connected POS to a chosen location.</p>
        <li>If you select <b><u>All</u></b> in location Selector, you will not be able to browse anything</li>
        <li>You can manage the items in the dashboard of the connected POS</li>
        <h3>Usage</h3>
        <p>To be able to apply dynamic updates to menu items, you should not copy them but Drag & Drop.</p>
        <img src={MenuItemDropPNG} />
        <li>Drop a menu item to a design frame that was dropped from <Link to="/wiki/figma/designs/page">Designs Page</Link> to make it dynamically updatable</li>
        <li>You can hold <kbd>{"ctrl (Windows)"}</kbd> or <kbd>{"Option (MacOs)"}</kbd> to select only specific part of a menu item for Drag & Drop</li>
        <img src={MenuItemDropCtrlPNG} />
        <p></p>
        <h3>Tags</h3>
        <p>Tags are inferred from the POS, but you can add your own as well.</p>
        <li>To set tags added using our UIs (e.g. the Figma Plugin), click the Tags icon</li>
        <li>You will be able to set/unset custom tags to menu items</li>
        <li>Custom tags will be marked with a Stream Logo</li>
        <img src={MenuItemTagsPNG} />
        <h3>Tags Creation</h3>
        <ul>
          <li>Enter the tag name in the selector search field and choose {`"Create"`}</li>
          <li>Select created option from the list</li>
        </ul>
        <p><Link to="/wiki/ui/selector">Learn more about Selector</Link></p>
        <img src={MenuItemTagsNewPNG} />
      </Article>
    </ArticleLayout>
  )
}

export default WikiFigmaMenuItems
