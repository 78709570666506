import "./PopupConfirmLayout.scss"

import { ReactNode } from "react"

import PopupLayout, { PopupLayoutProps } from "../PopupLayout/PopupLayout"

interface PopupConfirmLayoutProps extends PopupLayoutProps {
  button?: ReactNode
  description?: ReactNode
}

function PopupConfirmLayout(props: PopupConfirmLayoutProps) {
  return (
    <PopupLayout {...props}>
      <div className="popup-confirm-layout">
        <div className="popup-confirm-layout__description">{props.description}</div>
        <div className="popup-confirm-layout__container">{props.children}</div>
        <div className="popup-confirm-layout__button">{props.button}</div>
      </div>
    </PopupLayout>
  )
}

export default PopupConfirmLayout
