class BroadcastHandshake {
  static async ping(channel: BroadcastChannel) {
    channel.postMessage("PING")

    return await new Promise<boolean>(resolve => {
      const timeout = setTimeout(() => resolve(false), 15_000)

      channel.addEventListener("message", event => {
        if (event.data !== "PONG") return

        resolve(true)
        clearTimeout(timeout)
      })
    })
  }

  static pong(channel: BroadcastChannel) {
    channel.addEventListener("message", event => {
      if (event.data !== "PING") return
      if (event.target === window) return

      channel.postMessage("PONG")
    })
  }
}

export default BroadcastHandshake
