import { Outlet } from "react-router-dom"

import AuthBoundary from "@/app/boundaries/AuthBoundary"

function UserProfileLayout() {
  return (
    <AuthBoundary>
      <Outlet />
    </AuthBoundary>
  )
}

export default UserProfileLayout
