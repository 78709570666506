import "./ArticleLayout.scss"

import { ReactNode } from "react"

interface ArticleLayoutProps {
  children: ReactNode
}

function ArticleLayout(props: ArticleLayoutProps) {
  return (
    <div className="article-layout">{props.children}</div>
  )
}

export default ArticleLayout
