import "./AuthorizationForm.scss"

import { useState } from "react"
import { useSearchParam } from "react-use"

import useUserToken from "@/app/user/useUserToken"
import Loader from "@/shared/components/extrinsic/Loader/Loader"
import Button from "@/shared/components/intrinsic/Button/Button"
import Buttons from "@/shared/layouts/Buttons/Buttons"
import TextBox from "@/shared/layouts/TextBox/TextBox"
import { classWithModifiers } from "@/utils/common"
import useSocket from "@/utils/hooks/useSocket"


const SOCKET_AUTH_NAMESPACE = "/auth"
const SOCKET_AUTH_URL = new URL(SOCKET_AUTH_NAMESPACE, import.meta.env.VITE_API_HOST).toString()

function AuthorizationForm() {
  const uuid = useSearchParam("uuid")
  const userToken = useUserToken()

  const socket = useSocket(SOCKET_AUTH_URL)

  const [decision, setDecision] = useState<"accepted" | "declined" | null>(null)

  async function onAccept() {
    if (userToken == null) return

    socket.emit("auth/authorized", { uuid, token: userToken })
    await new Promise(resolve => setTimeout(resolve, 500))

    setDecision("accepted")
  }

  function onDecline() {
    socket.emit("auth/unauthorized", { uuid })

    setDecision("declined")
  }

  if (decision === "accepted") {
    window.location.href = "figma://"
    return (
      <div className="authorization-form">
        <TextBox>
          <h3>Figma Plugin Authorized</h3>
          <p>Access to the Plugin was <em>provided</em>, you can close the window</p>
        </TextBox>
      </div>
    )
  }

  if (decision === "declined") {
    return (
      <div className="authorization-form">
        <TextBox>
          <h3>Figma Plugin Unauthorized</h3>
          <p>Access to the Plugin was <em>declined</em>, you can close the window</p>
        </TextBox>
      </div>
    )
  }

  if (socket.status === "disconnected") {
    return (
      <div className="authorization-form">
        <TextBox>
          <h3>Disconnected from server</h3>
          <pre>{socket.error?.message ?? "Unknown Error"}</pre>
          <Button onClick={() => socket.connect()}>Reconnect</Button>
        </TextBox>
      </div>
    )
  }

  return (
    <div className={classWithModifiers("authorization-form", socket.status === "pending" && "pending")}>
      <TextBox>
        <h3>Figma Plugin Authorization</h3>
        <p>Figma Plugin is requesting access to your account</p>
      </TextBox>

      <div className="authorization-form__loader">
        <Loader />
      </div>
      <div className="authorization-form__buttons">
        <Buttons centered>
          <Button await onClick={onAccept}>Accept</Button>
          <Button color="red" onClick={onDecline}>Decline</Button>
        </Buttons>
      </div>
    </div>
  )
}

export default AuthorizationForm
