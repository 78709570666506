import { useEffect } from "react"

import { UserActions } from "@/entities/user/reducer"
import { useAppDispatch } from "@/store/hooks"

import useUser from "./useUser"
import useUserToken from "./useUserToken"


/**
 * Supplies redux store with fetched user.
 */
function UserAuthService() {
  const user = useUser()
  const userToken = useUserToken()
  const dispatch = useAppDispatch()


  useEffect(() => {
    if (user == null) return
    if (userToken == null) return

    dispatch(UserActions.update(user))
  }, [user, userToken])

  useEffect(() => {
    if (user != null) return
    if (userToken != null) return

    dispatch(UserActions.reset())
  }, [user, userToken])


  return null
}

export default UserAuthService
