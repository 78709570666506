import { useContext } from "react"

import themeContext, { ThemeScheme } from "./themeContext"


function useTheme(overrideContext?: ThemeScheme | null) {
  const theme = useContext(themeContext)

  return overrideContext ?? theme
}

export default useTheme
