import "./Notice.scss"

import { ReactNode } from "react"

import { Themeable } from "@/app/types"
import useTheme from "@/services/Theme/useTheme"
import { classWithModifiers } from "@/utils/common"

import Icon, { IconName } from "../../intrinsic/Icon/Icon"



interface NoticeProps extends Themeable {
  icon?: IconName
  children: ReactNode
}

function Notice(props: NoticeProps) {
  const theme = useTheme(props.theme)

  return (
    <div className={classWithModifiers("notice", theme)}>
      {props.icon && <Icon className="notice__icon" name={props.icon} />}
      <p>{props.children}</p>
    </div>
  )
}

export default Notice
