import { useSuspenseQuery } from "@tanstack/react-query"

import queryClient from "@/api/client"
import MenuAppDAO from "@/entities/app/menu/menu.dao"
import { MenuAppUpdateDTO } from "@/entities/app/menu/menu.dto"
import { MenuAppInstance } from "@/entities/app/menu/menu.types"

import MenuAppSettings from "./MenuAppSettings"



const menuAppDao = new MenuAppDAO

interface MenuAppSettingsContainerProps {
  id: string
  onChange?(): void
}

function MenuAppSettingsContainer(props: MenuAppSettingsContainerProps) {
  const { data: menuApp } = useSuspenseQuery({
    queryFn: () => menuAppDao.findById(props.id),
    queryKey: [menuAppDao, props.id]
  })

  async function onSubmit(dto: MenuAppUpdateDTO) {
    if (dto.designId != null) {
      await menuAppDao.assignDesign(props.id, dto.designId)
      queryClient.setQueryData<MenuAppInstance>([menuAppDao, props.id], app => app?.id === props.id ? ({ ...app, ...dto }) : app)
    }
  }

  return <MenuAppSettings app={menuApp} onSubmit={onSubmit} key={props.id} />
}

export default MenuAppSettingsContainer
