import "./DesignPreview.scss"

import { DesignViewer, MenuDesign } from "@streamllc/figma-design-viewer"
import { ReactNode, useEffect, useMemo } from "react"

import { Highlightable } from "@/app/types"
import { Design } from "@/entities/design"
import { loadGoogleFont, loadPrivateFonts } from "@/entities/fonts/fonts.helpers"
import Icon from "@/shared/components/intrinsic/Icon/Icon"
import { classWithModifiers } from "@/utils/common"





export interface MenuDesignPreviewProps extends Omit<Design, "locationId">, Partial<Highlightable> {
  label?: ReactNode

  selected?: boolean
  onSelect?(): void

  onNameChange(name: string): void
}

function MenuDesignPreview(props: MenuDesignPreviewProps) {
  const node = useMemo(() => JSON.parse(props.json), [props.json])
  const design = useMemo(() => new MenuDesign(node), [node])

  useEffect(() => {
    design.fonts.forEach(font => loadGoogleFont(font.family))
    void loadPrivateFonts(props.companyId)
  }, [design, props.companyId])


  function getMediaUrl(hash: string): string {
    const url = new URL(`designImage-${props.companyId}-${hash}`, import.meta.env.VITE_STORAGE_URL)
    return url.toString()
  }


  const viewer = (
    <DesignViewer
      design={design}
      resolvers={{
        imageHash: getMediaUrl,
        transformText: content => content,
        transformWidget() { }
      }}
      options={{ fit: "contain" }}
    />
  )


  return (
    <div className="design-preview">
      <div className="design-preview__header">
        <div className="design-preview__name">{props.highlight?.(props.name) ?? props.name}</div>
      </div>

      {props.onSelect == null && (
        <div className="design-preview__canvas">{viewer}</div>
      )}
      {props.onSelect != null && (
        <button className={classWithModifiers("design-preview__canvas", props.selected && "selected")} type="button" onClick={props.onSelect}>
          {viewer}
          {props.selected && <Icon className="design-preview__icon" name="check" />}
        </button>
      )}
    </div>
  )
}

export default MenuDesignPreview
