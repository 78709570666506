import "./PopupPersonEdit.scss"

import { FormEvent } from "react"
import { useModalWindow } from "react-modal-global"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { coerce } from "zod"

import { CompanyPeopleUpdateDTO } from "@/entities/company/people/company-people.dto"
import { CompanyPerson } from "@/entities/company/people/company-people.types"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Fields from "@/shared/layouts/Fields/Fields"
import useFormStatus from "@/utils/hooks/useFormStatus"

const form = new ZodForm({
  firstName: coerce.string().min(3, "Must contain at least 3 character"),
  lastName: coerce.string().min(3, "Must contain at least 3 character"),
})
form.object._type satisfies CompanyPeopleUpdateDTO

interface PopupPersonEditProps {
  person: CompanyPerson
  onSubmit?(dto: CompanyPeopleUpdateDTO): void | Promise<void>
}

function PopupPersonEdit(props: PopupPersonEditProps) {
  const modal = useModalWindow()
  const { fieldIssues } = useZodFormIssues(form)
  const formStatus = useFormStatus(form, onSubmit) // Form used to avoid multiple states.

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (fields == null) return

    await props.onSubmit?.(fields)
    modal.close()
  }

  return (
    <PopupLayout title="Edit info">
      <form className="popup-person-edit" onChange={form.parseCurrentField.bind(form)} onSubmit={formStatus.submit}>
        <Fields row>
          <Field placeholder="Enter name" name={form.fields.firstName} customValidity={fieldIssues.firstName} defaultValue={props.person.firstName} />
          <Field placeholder="Enter surname" name={form.fields.lastName} customValidity={fieldIssues.lastName} defaultValue={props.person.lastName} />
        </Fields>
        <div className="profile-invitations__submit">
          <Button size="big" color="white" type="submit" {...formStatus}>Save edits</Button>
        </div>
      </form>
    </PopupLayout>
  )
}

export default PopupPersonEdit
