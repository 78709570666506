import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query"

import { PopupConfirmCompanyDelete, PopupConfirmCompanyRestore, PopupEditCompany } from "@/areas/company"
import CompanyDao from "@/entities/company/company.dao"
import { CompanyUpdateDTO } from "@/entities/company/company.dto"
import Modal from "@/services/Modal/Modal"

import ProfileGeneralInfo from "../components/ProfileGeneralInfo/ProfileGeneralInfo"
import ProfileSettingsGroup from "../components/ProfileSettingsGroup/ProfileSettingsGroup"


interface ProfileGeneralContainerProps {
  id: string
}

function ProfileGeneralContainer(props: ProfileGeneralContainerProps) {
  const queryClient = useQueryClient()
  const { data: company } = useSuspenseQuery({
    queryFn: () => CompanyDao.find(props.id),
    queryKey: [CompanyDao.name, props.id]
  })


  async function onUpdate(dto: CompanyUpdateDTO) {
    await CompanyDao.update(props.id, dto)
    queryClient.invalidateQueries({ queryKey: [CompanyDao.name]})
  }
  async function onRestore() {
    await CompanyDao.restore(props.id)
    queryClient.invalidateQueries({ queryKey: [CompanyDao.name]})
  }
  async function onDelete() {
    await CompanyDao.delete(props.id)
    queryClient.invalidateQueries({ queryKey: [CompanyDao.name]})
  }


  return (
    <ProfileSettingsGroup heading={<h4>General information</h4>}>
      <ProfileGeneralInfo
        company={company}

        onUpdate={() => Modal.open(PopupEditCompany, { company, onSubmit: onUpdate })}
        onRestore={() => Modal.open(PopupConfirmCompanyRestore, { companies: [company], onSubmit: onRestore })}
        onDelete={() => Modal.open(PopupConfirmCompanyDelete, { companies: [company], onSubmit: onDelete })}
      />
    </ProfileSettingsGroup>
  )
}

export default ProfileGeneralContainer
