import "./Table.scss"

import { HTMLAttributes, ReactNode } from "react"

import { classMerge } from "@/utils/common"

interface TableProps extends HTMLAttributes<HTMLTableElement> {
  children: ReactNode
}

function Table(props: TableProps) {
  return (
    <div className="table-wrapper">
      <table {...props} className={classMerge("table", props.className)} />
    </div>
  )
}

export default Table
