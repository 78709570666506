import queryClient from "@/api/client"
import LocationDao from "@/entities/location/location.dao"
import POSDao from "@/entities/pos/pos.dao"
import { POSIntegration } from "@/entities/pos/pos.types"

import LocationView, { LocationViewProps } from "./LocationView"


interface LocationContainerProps extends Pick<LocationViewProps, "location" | "children" | "defaultExpanded"> {
  companyId: string
}

function LocationContainer(props: LocationContainerProps) {
  async function onPOSEdit(dto: POSIntegration) {
    const posProvider = { ...dto, locationId: props.location.id }
    const posAction = props.location?.pos?.name ? "UPDATE" : "CREATE"

    await POSDao.put(posProvider, posAction)
  }

  async function onUpdate(title: string) {
    await LocationDao.update(props.location.id, { title })
    queryClient.invalidateQueries({ queryKey: [LocationDao.name] })
  }

  async function onDelete() {
    await LocationDao.delete(props.location.id)
    queryClient.invalidateQueries({ queryKey: [LocationDao.name] })
  }

  return (
    <LocationView {...props} onPOSEdit={onPOSEdit} onUpdate={onUpdate} onDelete={onDelete} />
  )
}

export default LocationContainer
