import "./Field.scss"

import { omit } from "lodash"
import { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, ReactNode, Ref, useId, useMemo, useRef, useState } from "react"

import { Themeable } from "@/app/types"
import useTheme from "@/services/Theme/useTheme"
import { classWithModifiers } from "@/utils/common"
import PhoneNumber from "@/utils/transform/phonenumber"

import DataList from "../DataList/DataList"
import { DataListValues } from "../DataList/DataList.types"
import { normalizeIconLike } from "../Icon/Icon.helpers"
import { IconLike } from "../Icon/Icon.types"


const TYPING_THROTTLE_DELAY = 1 * 1000

export interface FieldProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, Themeable {
  width?: string
  theme?: "dark" | "light"

  iconLeft?: IconLike
  iconRight?: IconLike
  /**
   * Shows error.
   */
  customValidity?: string | null | false
  /**
   * Custom `datalist`.
   */
  dataListId?: string
  /**
   * Can be used for input suggestions.
   */
  dataListValues?: DataListValues

  /**
   * Sets label for this input.
   */
  children?: ReactNode

  /**
   * Should be changed to `ref` for React 19.0.0
   */
  _ref?: Ref<HTMLInputElement>
}

function Field(props: FieldProps) {
  const id = useId()
  const theme = useTheme(props.theme)

  const [filled, setFilled] = useState<boolean>(!!props.value || !!props.defaultValue)
  const [focused, setFocused] = useState(false)
  const [typing, setTyping] = useState(false)
  const typingTimeout = useRef<NodeJS.Timeout | null>(null)

  const prevValue = useRef<string>("")

  function sliceMaxLength(value: string) {
    if (props.type === "tel") {
      const phoneNumber = PhoneNumber.parse(value)
      const phoneNumberLength = PhoneNumber.getLength(phoneNumber)

      if (phoneNumberLength > (props.maxLength ?? Infinity)) {
        return prevValue.current
      }

      prevValue.current = value
      return PhoneNumber.format(phoneNumber)
    }

    return value.slice(0, props.maxLength)
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const target = event.currentTarget
    target.value = sliceMaxLength(target.value)
    setFilled(target.value.length > 0)

    watchTyping()

    props.onChange?.(event)
  }

  /**
   * Sets `typing` to `true` and keeps it, if `watchTyping` isn't called during 1 second, sets to `false`.
   *
   * Used to represent user's typing intention.
   */
  function watchTyping() {
    typingTimeout.current && clearTimeout(typingTimeout.current)
    typingTimeout.current = setTimeout(() => setTyping(false), TYPING_THROTTLE_DELAY)

    setTyping(true)
  }

  const customValidity = useMemo(() => !typing && props.customValidity, [props.customValidity, typing])
  const invalid = !!customValidity
  const dataListId = props.dataListId ?? `${id}-datalist`

  return (
    <label className="field" style={{ "--input-width": props.width }}>
      {props.children && (
        <div className="field__label">{props.children}{props.required && "*"}</div>
      )}
      <div className={classWithModifiers("field__appearance", filled && "filled", focused && "focused", invalid && "invalid", props.disabled && "disabled", theme)}>
        {props.iconLeft && (
          <div className="field__icon">{normalizeIconLike(props.iconLeft)}</div>
        )}
        <input
          {...omit(props, "iconRight", "iconLeft", "customValidity", "children", "dataListId", "dataListValues")}
          className={classWithModifiers("field__input", props.theme ?? "dark")}
          maxLength={props.type === "tel" ? undefined : props.maxLength}
          placeholder={props.placeholder}

          list={dataListId}

          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}

          onChange={onChange}
          ref={props._ref}
        />
        {props.iconRight && (
          <div className="field__icon">{normalizeIconLike(props.iconRight)}</div>
        )}
      </div>
      <span className={classWithModifiers("field__validity", !!customValidity && "active")} aria-hidden={!invalid}>
        {customValidity}
      </span>
      {props.dataListValues && (
        <DataList id={dataListId} values={props.dataListValues} />
      )}
    </label>
  )
}

export default Field
