import { useObservable } from "react-use"

function useBroadcastMessage<T>(channel: BroadcastChannel, name: string): T | undefined {
  return useObservable<T>({
    subscribe: callback => {
      function listener(event: MessageEvent) {
        if (event.data.type !== name) return
        if ("payload" in event.data === false) return

        callback(event.data.payload as T)
      }

      channel.addEventListener("message", listener)
      return {
        unsubscribe: () => channel.removeEventListener("message", listener)
      }
    }
  })
}

export default useBroadcastMessage
