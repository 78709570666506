import { useSuspenseQuery } from "@tanstack/react-query"
import { omit } from "lodash"
import { FormEvent } from "react"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { coerce, z } from "zod"

import CompanyDao from "@/entities/company/company.dao"
import { CompanyPeopleCreateDTO } from "@/entities/company/people/company-people.dto"
import { UserDao } from "@/entities/user"
import { ComapanyHasOwnerRole, ComapanyUserRole, StreamManagerRole, StreamOwnerRole, UserRole } from "@/entities/user"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Selector from "@/shared/components/intrinsic/Selector/Selector"
import { optionsFromEnum } from "@/shared/components/intrinsic/Selector/Selector.helpers"
import Fields from "@/shared/layouts/Fields/Fields"
import { useAppSelector } from "@/store/hooks"
import useFormStatus from "@/utils/hooks/useFormStatus"

const form = new ZodForm({
  firstName: coerce.string().min(1, "Must contain at least 1 character"),
  lastName: coerce.string().min(1, "Must contain at least 1 character"),
  email: coerce.string().email("Enter a correct email"),
  role: z.nativeEnum(UserRole).default(UserRole.CompanyManager),
})
form.object._type satisfies CompanyPeopleCreateDTO

interface ProfileInvitationsFormProps {
  onSubmit?(invite: CompanyPeopleCreateDTO): Promise<void> | void
  isStreamSettings?: boolean
  companyId?: string
  invites: CompanyPeopleCreateDTO[]
}

function ProfileInvitationsForm(props: ProfileInvitationsFormProps) {
  const { fieldIssues, issues } = useZodFormIssues(form)
  const formStatus = useFormStatus(form, handleSubmit)
  const user = useAppSelector((state) => state.user)

  const { data: people } = useSuspenseQuery({
    queryFn: () => CompanyDao.people.findAll(props.companyId ?? ""),
    queryKey: [CompanyDao.people.name, props.companyId ?? "", CompanyDao.people.findAll.name]
  })

  const { data: users } = useSuspenseQuery({
    queryFn: UserDao.findStreamMembers,
    queryKey: [UserDao.name, UserDao.findStreamMembers.name]
  })

  const streamOwnersCount = users?.filter(u => u.role === UserRole.StreamOwner).length ?? 0
  const companyOwnersCount = people?.filter(p => p.role === UserRole.CompanyOwner).length ?? 0

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (!fields) return
    const ROLE_INCREMENT = 2

    if (props.isStreamSettings) {
      fields.role = fields.role + ROLE_INCREMENT
    }

    event.currentTarget.reset()
    await props.onSubmit?.(fields)
  }

  function getCompanyRole (invites: CompanyPeopleCreateDTO[], companyOwnersCount: number) {
    const hasOwnerInvite = invites.some((invite) => invite.role === 1)
    if (hasOwnerInvite) return ComapanyHasOwnerRole
    return companyOwnersCount < UserRole.CompanyOwner ? ComapanyUserRole : ComapanyHasOwnerRole
  }

  const errorMessage = issues.length > 0 && `(${issues[0].message})`
  const StreamRole = streamOwnersCount < UserRole.StreamOwner ? StreamOwnerRole : StreamManagerRole
  const CompanyRole = getCompanyRole(props.invites, companyOwnersCount)

  const shouldDisplayRoleSelector = user.role === UserRole.StreamOwner || user.role === UserRole.StreamManager
  const roleOptions = omit(props.isStreamSettings ? StreamRole : CompanyRole, "Guest")


  return (
    <form className="profile-invitations__form" onChange={form.parseCurrentField.bind(form)} onSubmit={formStatus.submit}>
      <div className="profile-invitations__title">Invite by e-mail {errorMessage}</div>
      <Fields>
        <Fields row>
          <Field placeholder="Enter name" name={form.fields.firstName} customValidity={fieldIssues.firstName} />
          <Field placeholder="Enter surname" name={form.fields.lastName} customValidity={fieldIssues.lastName} />
        </Fields>
        <Field placeholder="Enter e-mail" name={form.fields.email} customValidity={fieldIssues.email} />
        {shouldDisplayRoleSelector && (
          <Selector 
            defaultValue={props.isStreamSettings ? StreamOwnerRole.StreamManager : ComapanyUserRole.CompanyManager}
            name={form.fields.role}>
            {optionsFromEnum(roleOptions)}
          </Selector>
        )}
      </Fields>
      <Button className="profile-invitations__add" type="submit" color="green" size="small" iconLeft="plus">Invite</Button>
    </form>
  )
}

export default ProfileInvitationsForm
