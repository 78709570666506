import { UserRole } from "@/entities/user"

export function hierarchySet(
  a: { role: UserRole, status: number, deleted: boolean },
  b: { role: UserRole, status: number, deleted: boolean }
) {
  if (a.role === 3 && b.role !== 3) return -1
  if (a.role !== 3 && b.role === 3) return 1
  if (a.role === 2 && b.role !== 2) return -1
  if (a.role !== 2 && b.role === 2) return 1

  if (a.status !== 1 && b.status === 1) return -1
  if (a.status === 1 && b.status !== 1) return 1

  if (a.deleted === false && b.deleted === true) return -1
  if (a.deleted === true && b.deleted === false) return 1

  return 0
}
