import "@streamllc/shared/views.css"

import { OCBView, OrderView, WelcomeView } from "@streamllc/shared/ocb"
import { useEffect } from "react"

import { OCBAppViews } from "@/entities/app/ocb/ocb.types"
import UnreachableCodeError from "@/shared/errors/UnreachableCodeError"
import useBroadcastChannel from "@/utils/hooks/useBroadcastChannel"
import useBroadcastMessage from "@/utils/hooks/useBroadcastMessage"
import useParam from "@/utils/hooks/useParam"
import BroadcastHandshake from "@/utils/transform/BroadcastHandshake"


function OCBPreview() {
  const appName = useParam("app")

  const channel = useBroadcastChannel(`OCB/${appName}/PREVIEW`)
  useEffect(() => channel.postMessage({ type: "CONNECT", payload: crypto.randomUUID() }), [channel])
  useEffect(() => BroadcastHandshake.pong(channel), [channel])

  const { view, views } = useBroadcastMessage<{ view: OCBView, views: OCBAppViews }>(channel, "UPDATE") || {}

  if (views == null) return

  if (view === OCBView.Order) {
    return <OrderView order={{ items: [], finalPrice: 0, modifiers: [], subTotal: 1, taxPrice: 2 }} customization={views.order} showVerticalImage />
  }

  if (view === OCBView.Welcome) {
    return <WelcomeView customization={views.welcome} />
  }

  throw new UnreachableCodeError
}

export default OCBPreview

OCBPreview.header = () => <></>
