import { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, Ref, useLayoutEffect, useRef } from "react"

import { combineRefs } from "@/utils/react"


export interface FieldAdaptiveProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  _ref?: Ref<HTMLInputElement>
}

function FieldAdaptive(props: FieldAdaptiveProps) {
  function onChange(event: ChangeEvent<HTMLInputElement>) {
    props.onChange?.(event)

    hugInputWidth()
  }

  const inputRef = useRef<HTMLInputElement>(null)

  /**
   * https://stackoverflow.com/a/17418138/12468111
   */
  function hugInputWidth() {
    if (inputRef.current == null) return

    inputRef.current.style.setProperty("width", "0px")
    inputRef.current.style.setProperty("width", inputRef.current.scrollWidth.toString() + "px")
  }

  useLayoutEffect(hugInputWidth, [props.value])

  return <input {...props} type="text" value={props.value} onChange={onChange} ref={combineRefs([inputRef, props._ref])} />
}

export default FieldAdaptive
