import { cloneInstance } from "@/utils/common"

abstract class DAO {
  public static readonly NO_TOASTS: unique symbol = Symbol("__NO_TOASTS__")

  public readonly name = this.constructor.name

  public disableToasts(): this {
    const clonedThis = cloneInstance(this)
    Object.defineProperty(clonedThis, DAO.NO_TOASTS, {})

    return clonedThis
  }
}

export default DAO
