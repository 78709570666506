import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import OuterLink from "@/shared/components/intrinsic/OuterLink/OuterLink"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

function WikiFigmaEditor() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Figma Editor</h2>
        <p>We use Figma for the best design experience</p>
      </TextBox>
      <Article>
        <p>
          To start creating designs, you need to <OuterLink to="https://www.figma.com/downloads/">download Figma App</OuterLink>.
        </p>
        <p>
          Then you will need to install our Figma Plugin (Stream Designer), follow these instructions:
          <ol>
            <li>Authorize to Figma</li>
            <li>Create new or open an existing design in Figma</li>
            <li>Open Assets (<kbd>Ctrl + K for Windows and Cmd + K for MacOs</kbd>)</li>
            <li>Select Plugins & Widgets tab</li>
            <li>Enter {`"Stream Designer"`} in search bar</li>
            <li>Choose it from the list and install it</li>
          </ol>
        </p>
      </Article>
    </ArticleLayout>
  )
}

export default WikiFigmaEditor
