import DOMPurify from "dompurify"
import { ReactNode } from "react"
import { FaSortDown, FaSortUp } from "react-icons/fa"

import { AuditLogEntry, AuditLogSortKey } from "@/entities/audit-log/audit-log.types"
import ButtonLink from "@/shared/components/intrinsic/Button/ButtonLink"
import Table from "@/shared/components/intrinsic/Table/Table"
import { humanizeDate } from "@/utils/transform/date"

import LogActionLabel from "../AuditLogActionLabel/AuditLogActionLabel"

interface AuditLogTableProps {
  entries: AuditLogEntry[];
  sortBy: AuditLogSortKey | null;
  sortOrder: "asc" | "desc";
  onSort: (key: AuditLogSortKey) => void;
}

function AuditLogTable(props: AuditLogTableProps) {
  interface SortableProps {
    by: AuditLogSortKey;
    children: ReactNode;
    sortBy: AuditLogSortKey | null;
    sortOrder: "asc" | "desc";
    onSort: (key: AuditLogSortKey) => void;
  }

  function Sortable(props: SortableProps) {
    const { by, children, sortBy, sortOrder, onSort } = props

    const handleSort = () => {
      if (sortBy === by) {
        onSort(by)
      } else {
        onSort(by)
      }
    }

    return (
      <button onClick={handleSort}>
        {children}
        {sortBy === by && (
          <>
            {sortOrder === "asc" ? <FaSortUp /> : <FaSortDown />}
          </>
        )}
      </button>
    )
  }

  return (
    <Table className="logs-table">
      <thead>
        <tr>
          <th>
            <Sortable by="timestamp" onSort={props.onSort} sortBy={props.sortBy} sortOrder={props.sortOrder}>
              Date
            </Sortable>
          </th>
          <th>
            <Sortable by="action" onSort={props.onSort} sortBy={props.sortBy} sortOrder={props.sortOrder}>
              Action
            </Sortable>
          </th>
          <th>
            <Sortable by="username" onSort={props.onSort} sortBy={props.sortBy} sortOrder={props.sortOrder}>
              User
            </Sortable>
          </th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.entries.map((log, index) => {
          const sanitizedDescription = DOMPurify.sanitize(log.description)
          return (
            <tr key={index}>
              <td>{humanizeDate(log.timestamp)}</td>
              <td>
                <LogActionLabel action={log.action} />
              </td>
              <td>{log.username}</td>
              <td>
                <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
              </td>
              <td>
                <ButtonLink size="smaller" to={log.id}>
                  Details
                </ButtonLink>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default AuditLogTable
