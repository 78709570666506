import "./SettingsTabsWelcome.scss"

import { WelcomeView } from "@streamllc/shared/ocb"

import useTabSettings from "../../../../hooks/useTabSettings"


function SettingsTabsWelcome() {
  const [welcomeSettings] = useTabSettings("welcome")
  const containerWidth = 280
  const scaleRatio = containerWidth / window.innerWidth

  return (
    <div className="settings-tabs-welcome">
      <WelcomeView customization={welcomeSettings} scaleRatio={scaleRatio} />
    </div>
  )
}

export default SettingsTabsWelcome
