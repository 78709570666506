import { FormEvent } from "react"
import { useModalWindow } from "react-modal-global"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { coerce } from "zod"

import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Fields from "@/shared/layouts/Fields/Fields"
import useFormStatus from "@/utils/hooks/useFormStatus"

const form = new ZodForm({
  title: (
    coerce
      .string()
      .trim()
      .min(1, "Location name must contain at least 1 character")
      .max(50, "Location name must contain at most 50 characters")
  )
})

interface PopupLocationFormProps {
  defaultValue?: string
  onSubmit?(value: string): void | Promise<void>
}

function PopupLocationForm(props: PopupLocationFormProps) {
  const modal = useModalWindow()
  const formStatus = useFormStatus(form, onSubmit)
  const { fieldIssues } = useZodFormIssues(form)

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    const fields = form.parseAllFields(event)
    if (fields == null) return

    await props.onSubmit?.(fields.title)
    modal.close()
  }

  function onChange(event: FormEvent<HTMLFormElement>) {
    form.parseAllFields(event)
  }

  return (
    <PopupLayout title="Location">
      <form onSubmit={formStatus.submit} onChange={onChange}>
        <Fields>
          <Field name={form.fields.title} customValidity={fieldIssues.title} defaultValue={props.defaultValue} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button type="submit" color="white" {...formStatus}>{props.defaultValue == null ? "Create" : "Update"}</Button>
          </div>
        </Fields>
      </form>
    </PopupLayout>
  )
}

export default PopupLocationForm
