import { useQueryClient } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"

import { UserRole } from "@/entities/user"
import { useAppSelector } from "@/store/hooks"
import useRedirect from "@/utils/hooks/useRedirect"


const LOGIN_ROUTE = "/user/login"

interface AuthBoundaryProps {
  children: ReactNode
  redirect?: boolean
}

function AuthBoundary(props: AuthBoundaryProps) {
  const user = useAppSelector(state => state.user)
  if (!user.signed || user.role <= UserRole.Guest) {
    return <LoginRedirect redirect={ props.redirect ?? false } />
  }

  return <>{props.children}</>
}

export default AuthBoundary

function LoginRedirect(props: { redirect: boolean }) {
  const redirect = useRedirect({ withLastLocation: props.redirect })
  const queryClient = useQueryClient()

  useEffect(() => {
    redirect(LOGIN_ROUTE)
    queryClient.clear()
  }, [])

  return null
}
