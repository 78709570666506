import "./AuditLogDetails.scss"

import DOMPurify from "dompurify"

import { AuditLogEntry } from "@/entities/audit-log/audit-log.types"
import Table from "@/shared/components/intrinsic/Table/Table"
import { humanizeDate } from "@/utils/transform/date"

import LogActionLabel from "../AuditLogActionLabel/AuditLogActionLabel"

interface AuditLogDetailsProps extends AuditLogEntry {}

function AuditLogDetails(props: AuditLogDetailsProps) {
  const sanitizedDescription = DOMPurify.sanitize(props.description)

  return (
    <div className="log-details">
      <Table>
        <tr>
          <td>Date</td>
          <td>Action</td>
          <td>Username</td>
          <td>Description</td>
        </tr>
        <tr>
          <td>{humanizeDate(props.timestamp)}</td>
          <td><LogActionLabel action={props.action} /></td>
          <td>{props.username}</td>
          <td>
            <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
          </td>
        </tr>
      </Table>
      <div className="log-details__comparison">
        {props.oldData && (
          <section>
            <h3>Before</h3>
            {Object.entries(props.oldData).map(([key, value]) => (
              <pre key={key}>{key}: {JSON.stringify(value, null, 2)}</pre>
            ))}
          </section>
        )}
        {props.newData && (
          <section>
            <h3>After</h3>
            {Object.entries(props.newData).map(([key, value]) => (
              <pre key={key}>{key}: {JSON.stringify(value, null, 2)}</pre>
            ))}
          </section>
        )}
      </div>
    </div>
  )
}

export default AuditLogDetails
