import "./AuthLayout.scss"

import { ReactNode } from "react"

interface AuthLayoutProps {
  title: ReactNode
  children: ReactNode
}

function AuthLayout(props: AuthLayoutProps) {
  return (
    <div className="auth-layout">
      <h2 className="auth-layout__title">{props.title}</h2>
      <div className="auth-layout__container">{props.children}</div>
    </div>
  )
}

export default AuthLayout
