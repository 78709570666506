import { DesignViewer, MenuDesign } from "@streamllc/figma-design-viewer"
import { useSuspenseQuery } from "@tanstack/react-query"

import DesignDAO from "@/entities/design/design.dao"



const designDao = new DesignDAO

interface MenuAppPreviewProps {
  id: string
  companyId: string
}

function MenuAppView(props: MenuAppPreviewProps) {
  const { data: design } = useSuspenseQuery({
    queryFn: () => designDao.findById(props.id),
    queryKey: [designDao, props.id]
  })

  function getMediaUrl(hash: string): string {
    const url = new URL(`designImage-${props.companyId}-${hash}`, import.meta.env.VITE_STORAGE_URL)
    return url.toString()
  }

  return (
    <DesignViewer
      design={new MenuDesign(JSON.parse(design.json))}
      resolvers={{
        imageHash: getMediaUrl,
        transformText: content => content,
        transformWidget() { }
      }}
      options={{ fit: "contain" }}
    />
  )
}

export default MenuAppView
