export function objectFromSearchParams() {
  const searchParams = new URLSearchParams(window.location.search)

  const object: Record<string, unknown> = {}
  for (const [key, value] of searchParams) {
    object[key] = JSON.parse(value)
  }
  return object
}

export function assignSearchParams(searchParams: URLSearchParams, object: object) {
  for (const [key, value] of Object.entries(object)) {
    if (value == null || value === "" || (value instanceof Array && value.length === 0)) {
      searchParams.delete(key)
      continue
    }

    searchParams.set(key, JSON.stringify(value))
  }
}
