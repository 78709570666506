import { ModalPortal } from "react-modal-global"
import { useNavigate } from "react-router-dom"

import { PopupPersonalSettings } from "@/areas/profile"
import { UserDao, UserUpdateDTO } from "@/entities/user"
import Modal from "@/services/Modal/Modal"


function UserProfileSettings() {
  const navigate = useNavigate()

  async function onSubmit(dto: UserUpdateDTO) {
    await UserDao.update(dto)
  }

  async function onAvatarChange(avatar: File) {
    await UserDao.updateAvatar(avatar)
  }

  return (
    <ModalPortal controller={Modal} onUserClose={() => navigate(-1)}>
      <PopupPersonalSettings onSubmit={onSubmit} onAvatarChange={onAvatarChange} />
    </ModalPortal>
  )
}

export default UserProfileSettings
