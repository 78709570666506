import "./ProfileInvitations.scss"

import { useState } from "react"
import { useModalWindow } from "react-modal-global"

import { CompanyPeopleCreateDTO } from "@/entities/company/people/company-people.dto"
import PersonRole from "@/shared/components/extrinsic/PersonRole/PersonRole"
import Button from "@/shared/components/intrinsic/Button/Button"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"

import ProfileInvitationsForm from "./ProfileInvitationsForm"


export interface ProfileInvitationsProps {
  /**
   * This will be set to invited users.
   *
   * Can only invite people with lower role.
   */
  onSubmit?(invites: CompanyPeopleCreateDTO[]): void | Promise<void>
  isStreamSettings?: boolean
  companyId?: string
}

function ProfileInvitations(props: ProfileInvitationsProps) {
  const modal = useModalWindow()
  const [invites, setInvites] = useState<CompanyPeopleCreateDTO[]>([])

  async function onSubmit() {
    await props.onSubmit?.(invites)

    setInvites([])
    modal.close()
  }

  function addInvite(invite: CompanyPeopleCreateDTO) {
    const isDuplicate = invites.some(prevInvite => prevInvite.email === invite.email)
    if (isDuplicate) return

    setInvites(invites => [...invites, invite])
  }
  function removeInvite(invite: CompanyPeopleCreateDTO) {
    setInvites(invites => invites.filter(oldInvite => oldInvite !== invite))
  }

  return (
    <div className="profile-invitations">
      <ProfileInvitationsForm invites={invites} onSubmit={addInvite} isStreamSettings={props.isStreamSettings} companyId={props.companyId} />
      <div className="profile-invitations__list">
        {invites.map((invite, index) => (
          <div className="profile-invitations__invitation" key={index}>
            <ButtonIcon name="cross-circle" size="small" color="transparent" ariaLabel="remove invitation" onClick={() => removeInvite(invite)} />
            <span>{invite.email}</span>
            <PersonRole role={invite.role} />
          </div>
        ))}
      </div>
      <div className="profile-invitations__submit">
        <Button size="big" color="white" disabled={invites.length === 0} await onClick={onSubmit}>Send invite</Button>
      </div>
    </div>
  )
}

export default ProfileInvitations
