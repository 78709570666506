
import CompanyNavLayout from "@/app/layouts/CompanyNavLayout/CompanyNavLayout"
import { CompanyNavigation } from "@/areas/company"
import useParam from "@/utils/hooks/useParam"

function CompanyPage() {
  const companyId = useParam("id")

  return (
    <CompanyNavLayout>
      <CompanyNavigation companyId={companyId} />
    </CompanyNavLayout>
  )
}

export default CompanyPage
