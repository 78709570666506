import { ModalPortal } from "react-modal-global"
import { useNavigate } from "react-router-dom"

import { PopupPersonalChangePassword } from "@/areas/profile"
import { UserDao, UserPasswordUpdateDTO } from "@/entities/user"
import Modal from "@/services/Modal/Modal"

function UserProfileChangePassword() {
  const navigate = useNavigate()

  async function onSubmit(dto: UserPasswordUpdateDTO) {
    await UserDao.updatePassword(dto)
    await UserDao.logOut()
  }

  return (
    <ModalPortal controller={Modal} onUserClose={() => navigate(-1)}>
      <PopupPersonalChangePassword onSubmit={onSubmit} />
    </ModalPortal>
  )
}

export default UserProfileChangePassword
