import { range } from "lodash"

import Selector from "@/shared/components/intrinsic/Selector/Selector"

interface FontSizeSelectorProps {
  start: number
  step: number
  end: number

  name?: string
  value?: number

  defaultValue?: number
  onChange?(value: number): void
}

function FontSizeSelector(props: FontSizeSelectorProps) {
  return (
    <Selector label="Size" placeholder="Size" name={props.name} value={props.value} defaultValue={props.defaultValue} onChange={props.onChange}>
      {range(props.start, props.end + 1, props.step).map((size) => (
        <option value={size} key={size}>{size}px</option>
      ))}
    </Selector>
  )
}

export default FontSizeSelector
