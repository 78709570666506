import { useModalWindow } from "react-modal-global"

import { QLocation } from "@/entities/location/location.types"
import PopupConfirmLayout from "@/services/Modal/layouts/PopupConfirmLayout/PopupConfirmLayout"
import Button from "@/shared/components/intrinsic/Button/Button"


interface PopupConfirmLocationDeleteProps {
  location: QLocation
  onSubmit(id:string): Promise<void> | void
}

function PopupConfirmLocationDelete(props: PopupConfirmLocationDeleteProps) {
  const modal = useModalWindow()

  async function onConfirm(){
    await props?.onSubmit(props.location.id)
    modal.close()
  }

  return (
    <PopupConfirmLayout
      title="Deleting a Location"
      description="Are you sure you want to delete the location"
      button={<Button color="red" onClick={onConfirm}>Confirm deleting</Button>}
    >
      <div>
        {props.location.title}
      </div>
    </PopupConfirmLayout>
  )
}

export default PopupConfirmLocationDelete
