import "./ColorPicker.scss"

import { ChangeEvent, ReactNode, useMemo, useRef, useState } from "react"
import { HexAlphaColorPicker } from "react-colorful"
import { useClickAway } from "react-use"

import { classWithModifiers, toggleState } from "@/utils/common"
import { triggerReactInput } from "@/utils/react"

import Field from "../../intrinsic/Field/Field"

interface ColorPickerProps {
  /**
   * HEX Color (with or without `#`).
   */
  value?: string
  /**
   * HEX Color (with or without `#`).
   */
  defaultValue?: string
  onChange?(value: string): void

  name?: string
  label?: ReactNode

  upwards?: boolean
}

function ColorPicker(props: ColorPickerProps) {
  const fieldRef = useRef<HTMLInputElement>(null)

  const [color, setColor] = useState(props.defaultValue ?? "")
  const [expanded, setExpanded] = useState(false)
  const colorOverridden = useMemo(() => props.value ?? color, [props.value, color])

  function onPick(value: string) {
    setColor(value)
    props.onChange?.(value)

    if (fieldRef.current == null) return
    triggerReactInput(fieldRef.current, "value", value)
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setColor(event.currentTarget.value)
    props.onChange?.(event.currentTarget.value)
  }


  const elementRef = useRef<HTMLDivElement>(null)
  useClickAway(elementRef, () => setExpanded(false))

  return (
    <div className="color-picker" ref={elementRef}>
      {props.label && (
        <div className="color-picker__label">{props.label}</div>
      )}
      <button type="button" onClick={toggleState(setExpanded)}>
        <div className="color-picker__preview" style={{ background: colorOverridden }} />
      </button>
      <div className={classWithModifiers("color-picker__popup", expanded && "expanded", props.upwards && "upwards")}>
        <HexAlphaColorPicker color={colorOverridden} onChange={onPick} />
        <Field name={props.name} value={colorOverridden} onChange={onChange} _ref={fieldRef} />
      </div>
    </div>
  )
}

export default ColorPicker
