import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Notice from "@/shared/components/extrinsic/Notice/Notice"
import ButtonLink from "@/shared/components/intrinsic/Button/ButtonLink"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

function CompaniesLocations() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Companies & Locations</h2>
      </TextBox>
      <Article>
        <h3>Description</h3>
        <p>Figma Plugin scopes your visibility and actions to a chosen Company and Location.</p>
        <p>This is made to ease your interaction and enhance the experience.</p>
        <h3>Selecting</h3>
        <p>
          While you are at main pages (e.g. Designs, Menu Items, ...), you can select location scope at your own pace.
          If you have more privileges, you can also select company. If not, it will be read only.
        </p>
        <ButtonLink size="smaller" to="/wiki/ui/selector">Selector Usage Tips</ButtonLink>
        <Notice icon="info">You can also create a new location by typing in the selector and choosing {`"Create"`}</Notice>
        <Notice icon="cross-circle">You can not delete locations or companies, this can only be done in the StreamEditor</Notice>
      </Article>
    </ArticleLayout>
  )
}

export default CompaniesLocations
