import { useLocation, useNavigate } from "react-router-dom"

type RedirectOptions = {
  withLastLocation?: boolean
}

function useRedirect(options?: RedirectOptions) {
  const location = useLocation()
  const navigate = useNavigate()

  const navigateOptions = options?.withLastLocation
    ? { state: { redirect: location } }
    : undefined

  function redirect(to: string) {
    navigate(to, navigateOptions)
  }
  return redirect
}

export default useRedirect
