import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"

import { User, UserDao } from "@/entities/user"
import LocalStorageKey from "@/services/LocalStorage/LocalStorageKey"
import useObservableLocalStorage from "@/utils/hooks/useObservableLocalStorage"
import JWT from "@/utils/transform/jwt"


function useUser() {
  const [userToken] = useObservableLocalStorage<string>(LocalStorageKey.UserToken)
  const id = useMemo<string | null>(() => {
    if (userToken == null) return null

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const jwt = new JWT<any>(userToken)
      return jwt.payload.userData.id
    } catch (error) {
      console.error(error)
      toast.error("JWT Token Parsing has failed")

      void UserDao.logOut()

      return null
    }
  }, [userToken])

  const [user, setUser] = useState<User | null>(null)
  async function onUser() {
    if (id == null) return

    const user = await UserDao.findById(id)
    setUser(user)
  }
  useEffect(() => void onUser(), [id])

  return user
}

export default useUser
