import { OrderViewLayout } from "@streamllc/shared/ocb"
import { useSuspenseQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"

import OCBAppDAO from "@/entities/app/ocb/ocb.dao"
import { OCBAppInstance, OCBAppViews } from "@/entities/app/ocb/ocb.types"
import { confirmAction } from "@/services/Modal/components/PopupConfirm"

import OCBSettings from "./OCBSettings"

import ocbViewsContext from "../../contexts/ocbViewsContext"



const ocbAppDao = new OCBAppDAO

interface OCBSettingsContainerProps {
  name: string
  onChange?(): void
}

function OCBSettingsContainer(props: OCBSettingsContainerProps) {
  const { data: ocb, isRefetching } = useSuspenseQuery({
    queryFn: () => ocbAppDao.findByName(props.name),
    queryKey: [ocbAppDao.name, props.name]
  })

  const [views, setViews] = useState<Partial<OCBAppViews>>(() => getInitialViews(ocb))
  useEffect(() => setViews(getInitialViews(ocb)), [ocb.name])

  async function onDelete() {
    await confirmAction()
    await ocbAppDao.delete(props.name)
  }
  async function onPublish() {
    await ocbAppDao.update(props.name, { views })
  }

  function setViewsOverridden(...args: Parameters<typeof setViews>) {
    setViews(...args)
    props.onChange?.()
  }

  return (
    <ocbViewsContext.Provider value={[views, setViewsOverridden]}>
      <OCBSettings name={props.name} onDelete={onDelete} onPublish={onPublish} />
      <div style={{ background: "white", opacity: Number(isRefetching), transition: "200ms ease opacity" }} />
    </ocbViewsContext.Provider>
  )
}

export default OCBSettingsContainer


function getInitialViews(ocb: OCBAppInstance) {
  if (ocb.empty) return DEFAULT_SETTINGS

  return ocb.views
}

const DEFAULT_SETTINGS: Partial<OCBAppViews> = {
  welcome: {
    title: "OCB Settings",
    backgrounds: [],
    fontSize: 42,
    fontFamily: "Verdana",
    fontColor: "white",

    totalScreenEnabled: false
  },
  order: {
    primaryTextSize: 34,
    secondaryTextSize: 28,

    fontFamily: "Helvetica",

    backgrounds: [],
    layout: OrderViewLayout.Layout1,

    primaryTextColor: "white",
    secondaryTextColor: "#bcbcbc",

    primaryColor: "#575757",
    secondaryColor: "#777777"
  }
} as const

Object.seal(DEFAULT_SETTINGS)
Object.freeze(DEFAULT_SETTINGS)
