import { useQueryClient } from "@tanstack/react-query"
import { FormEvent, useEffect, useState } from "react"
import { useModalWindow } from "react-modal-global"
import ZodForm, { useZodFormIssues } from "react-zod-form"
import { coerce, z } from "zod"

import CompanyDao from "@/entities/company/company.dao"
import { CompanyCreateDTO } from "@/entities/company/company.dto"
import { UserRole } from "@/entities/user"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import EditableAvatar from "@/shared/components/extrinsic/EditableAvatar/EditableAvatar"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Fields from "@/shared/layouts/Fields/Fields"
import Bytes from "@/utils/transform/Bytes"

const form = new ZodForm({
  companyName: coerce.string().min(1, "Must contain at least 1 character"),
  companyLogo: z.instanceof(File).optional(),

  owner: z.object({
    firstName: coerce.string().min(3, "Must contain at least 3 character"),
    lastName: coerce.string().min(3, "Must contain at least 3 character"),
    email: coerce.string().email(),
    role: z.nativeEnum(UserRole).default(UserRole.CompanyOwner),
  }).optional()
})
form.object._type satisfies CompanyCreateDTO


interface PopupNewCompanyProps {
  onSubmit?(dto: CompanyCreateDTO): void | Promise<void>
}

function PopupNewCompany(props: PopupNewCompanyProps) {
  const [formStatus, setFormStatus] = useState({pending:false, disabled:false})
  const [showOwnerFields, setShowOwnerFields] = useState(false)
  const queryClient = useQueryClient()
  const { fieldIssues, issues, clearError } = useZodFormIssues(form)
  const modal = useModalWindow()

  useEffect(() => {
    setFormStatus(pevState=>({...pevState,
      disabled: issues.length > 0}))
  }, [issues])

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    setFormStatus(prevState=>({...prevState, pending:true}))
    try {
      const fields = form.parseAllFields(event)
      if (fields == null) return

      await props.onSubmit?.(fields)
      queryClient.invalidateQueries({ queryKey: [CompanyDao.name]})
      modal.close()
    } finally {
      setFormStatus(prevState=>({...prevState, pending:false}))
    }
  }
  

  return (
    <PopupLayout title="Creating a company" width="50em">
      <form onChange={event => form.parseCurrentField(event)} onBlur={event => form.parseCurrentField(event)} onSubmit={onSubmit}>
        <Fields>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <EditableAvatar contain name={form.fields.companyLogo} maxSize={Bytes.MB(10)} />
          </div>
          <Field name={form.fields.companyName} placeholder="Company Name" customValidity={fieldIssues.companyName} />
          {showOwnerFields && (
            <>
              <Fields row>
                <Field name={form.fields.owner.firstName} placeholder="Company's Owner Name" customValidity={fieldIssues.owner?.firstName} />
                <Field name={form.fields.owner.lastName} placeholder="Company's Owner Surname" customValidity={fieldIssues.owner?.lastName} />
              </Fields>
              <Field name={form.fields.owner.email} placeholder="Email" customValidity={fieldIssues.owner?.email} />
            </>)}
          {!showOwnerFields && 
          <Button onClick={() => setShowOwnerFields(!showOwnerFields)}>Add Owner</Button>
          }
          
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {showOwnerFields && 
          <Button onClick={() => {
            setShowOwnerFields(!showOwnerFields)
            clearError()
          }}>Remove Owner</Button>
            }
            <Button type="submit" color="green" {...formStatus}>Create a company</Button>
          </div>    
        </Fields>
      </form>
    </PopupLayout>
  )
}

export default PopupNewCompany
