import { FormEvent } from "react"
import { useModalWindow } from "react-modal-global"
import ZodForm from "react-zod-form"
import { coerce } from "zod"

import queryClient from "@/api/client"
import { AppInstance } from "@/entities/app/app.types"
import MenuAppDAO from "@/entities/app/menu/menu.dao"
import PopupLayout from "@/services/Modal/layouts/PopupLayout/PopupLayout"
import Button from "@/shared/components/intrinsic/Button/Button"
import Field from "@/shared/components/intrinsic/Field/Field"
import Fields from "@/shared/layouts/Fields/Fields"
import useFormStatus from "@/utils/hooks/useFormStatus"




interface PopupCreateMenuAppProps {
  companyId: string
  location: {
    id: string
  }

  onCreate?(menuAppId: string): void
}

const form = new ZodForm({ name: coerce.string().min(1, "Enter at least 1 character") })
const menuAppDao = new MenuAppDAO

function PopupCreateMenuApp(props: PopupCreateMenuAppProps) {
  const modal = useModalWindow()
  const formStatus = useFormStatus(form, onSubmit)

  async function createMenuApp(name: string) {
    const menuApp = await menuAppDao.create({
      name,

      companyId: props.companyId,
      locationId: props.location.id
    })

    const queryKey = [menuAppDao.name, "location", props.location.id]
    queryClient.setQueriesData<AppInstance[]>({ queryKey, exact: false }, apps => apps && [...apps, menuApp])

    return menuApp
  }

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const name = form.parseField(event, "name")
    if (name == null) return

    const menuApp = await createMenuApp(name.value)
    props.onCreate?.(menuApp.id)

    modal.close()
  }

  return (
    <PopupLayout title="Create Menu APP Instance">
      <form onSubmit={formStatus.submit}>
        <Fields>
          <Field name={form.fields.name} placeholder="App ID" />
          <Button type="submit" {...formStatus}>Create</Button>
        </Fields>
      </form>
    </PopupLayout>
  )
}

export default PopupCreateMenuApp
