import ArticleLayout from "@/app/layouts/ArticleLayout/ArticleLayout"
import Article from "@/shared/layouts/Article/Article"
import TextBox from "@/shared/layouts/TextBox/TextBox"

import DesignsRefreshPNG from "./designs-refresh.png"


function WikiFigmaDesignPublish() {
  return (
    <ArticleLayout>
      <TextBox>
        <h2>Figma Plugin | Design Refreshing</h2>
      </TextBox>
      <Article>
        <p>Just for your convenience you can pull actual values for i.e. Menu Items presented in the designs.</p>
        <p>This is useful if you want to preview how new title/prices will look like in the designs.</p>
        <img src={DesignsRefreshPNG} />
        <p>You can see a table of refreshable elements presented in the selected designs, those values will replaced with the actual ones.</p>
      </Article>
    </ArticleLayout>
  )
}

export default WikiFigmaDesignPublish
