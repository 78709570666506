import "./ProfileSettingsGroup.scss"

import { ReactNode } from "react"

import { UserRole } from "@/entities/user"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"
import Checkbox from "@/shared/components/intrinsic/Checkbox/Checkbox"
import { useAppSelector } from "@/store/hooks"

interface ProfileSettingsGroupProps {
  heading: ReactNode
  children: ReactNode


  selectedAll?: boolean
  onSelectAll?(): void
  onDeleteSelected?(): void
}

function ProfileSettingsGroup(props: ProfileSettingsGroupProps) {
  const user = useAppSelector(state => state.user)
  return (
    <div className="profile-settings-group">
      <div className="profile-settings-group__header">
        <div className="profile-settings-group__heading">{props.heading}</div>
        <div className="profile-settings-group__tools">
          {(user.role === UserRole.StreamOwner || user.role === UserRole.CompanyOwner) && props.onDeleteSelected && (
            <ButtonIcon name="trash-bin" size="small" color="transparent" ariaLabel="Delete all" onClick={props.onDeleteSelected} />
          )}
          {user.role === UserRole.StreamOwner && props.onSelectAll && (
            <Checkbox checked={props.selectedAll} onClick={props.onSelectAll} />
          )}
        </div>
      </div>
      <div className="profile-settings-group__container">
        {props.children}
      </div>
    </div>
  )
}

export default ProfileSettingsGroup
