import "./MenuDesignSelector.scss"

import { useState } from "react"

import DefaultBoundaries from "@/app/boundaries/DefaultBoundaries"
import { MenuAppInstance } from "@/entities/app/menu/menu.types"
import { Design } from "@/entities/design"
import Field from "@/shared/components/intrinsic/Field/Field"
import { inputValue } from "@/utils/common"

import MenuDesignPreviewsContainer from "../MenuDesignPreviews/MenuDesignPreviewsContainer"




export interface MenuDesignSelectorProps {
  app: MenuAppInstance

  current?: Design | null
  onChange?(design: Design): void | Promise<void>
}

function MenuDesignSelector(props: MenuDesignSelectorProps) {
  const [name, setName] = useState("")

  return (
    <div className="menu-design-selector">
      <Field placeholder="Search for designs..." value={name} onChange={inputValue(setName)} />
      <DefaultBoundaries>
        <MenuDesignPreviewsContainer app={props.app} filters={{ name }} selected={props.current} onSelect={props.onChange} />
      </DefaultBoundaries>
    </div>
  )
}

export default MenuDesignSelector
