import "./ProfilePeopleTable.scss"

import { Selectable } from "@/app/types"
import CompanyDao from "@/entities/company/company.dao"
import { CompanyPeopleUpdateDTO } from "@/entities/company/people/company-people.dto"
import { CompanyPerson } from "@/entities/company/people/company-people.types"
import { UserRole, UserStatus } from "@/entities/user"
import Modal from "@/services/Modal/Modal"
import PersonRole from "@/shared/components/extrinsic/PersonRole/PersonRole"
import PersonStatus from "@/shared/components/extrinsic/PersonStatus/PersonStatus"
import ButtonIcon from "@/shared/components/intrinsic/Button/ButtonIcon"
import Checkbox from "@/shared/components/intrinsic/Checkbox/Checkbox"
import Table from "@/shared/components/intrinsic/Table/Table"
import { useAppSelector } from "@/store/hooks"
import { addOrRemove } from "@/utils/common"

import PopupPersonEdit from "../../modals/PopupPersonEdit/PopupPersonEdit"


interface ProfilePeopleTableProps extends Selectable<CompanyPerson["id"]> {
  people: CompanyPerson[]
  onUpdate?(userId: string, dto: CompanyPeopleUpdateDTO): void
  onRestore?(userId: string): void
}

function ProfilePeopleTable(props: ProfilePeopleTableProps) {
  const user = useAppSelector((state) => state.user)
  function onSelect(id: CompanyPerson["id"]) {
    const selectedIds = addOrRemove(props.selectedIds, id)
    props.onSelect(selectedIds)
  }

  function onUpdate(person: CompanyPerson) {
    Modal.open(PopupPersonEdit, {
      person,
      onSubmit: dto => props.onUpdate?.(person.id, dto)
    })
  }

  async function onReInvite(person: CompanyPerson){
    await CompanyDao.people.resendInvitation(person)
  }

  function mayResendInvitation(person: CompanyPerson){
    return (user.role > person.role
      && user.role >= UserRole.CompanyOwner
      && person.status === UserStatus.Invited)
  }
  
  return (
    <Table className="profile-people-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.people.map(person => (
          (<tr key={person.id}>
            <td>{person.firstName} {person.lastName}</td>
            <td>{person.email}</td>
            <td><PersonRole role={person.role} /></td>
            <td>
              <div className="profile-people-table__label">
                <PersonStatus deleted={person.deleted} deletedAt={person.deletedAt} status={person.status} />
                {person.status === UserStatus.Archived && (user.role === UserRole.StreamOwner || (user.role === UserRole.CompanyOwner && person.role === UserRole.CompanyManager)) && (
                  <button className="profile-people-table__restore" type="button" onClick={() => props.onRestore?.(person.id)}>Restore</button>
                )}
                {mayResendInvitation(person) && (
                  <button className="profile-people-table__reinvite" type="button" onClick={() => onReInvite(person)}>Re-invite</button>
                )}
              </div>
            </td>

            <td>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {(user.role > person.role || person.email === user.email) && <ButtonIcon name="pen" size="small" color="transparent" ariaLabel="Edit current person" onClick={() => onUpdate(person)} />}
                {(user.role > person.role && user.role != UserRole.StreamManager && person.email !== user.email) && <Checkbox checked={props.selectedIds.includes(person.id)} onClick={() => onSelect(person.id)} />}
              </div>
            </td>
          </tr>
          )
        ))}
      </tbody>
    </Table>
  )
}

export default ProfilePeopleTable
