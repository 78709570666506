import { User, UserRole, UserStatus } from "./user.types"

export const USER_GUEST: User = {
  id: "-1",
  companyId: "-1",

  signed: false,
  role: UserRole.Guest,
  status: UserStatus.Active,
  avatar: "/static/images/guest-avatar.jpg",
  email: "yourmail@mail.com",
  firstName: "Mr.",
  lastName: "Guest"
}
