import "./CompanyStatusLabel.scss"

import { addDays, differenceInDays } from "date-fns"
import { kebabCase, startCase } from "lodash"

import { CompanyStatus } from "@/entities/company/company.types"
import { classWithModifiers } from "@/utils/common"

interface CompanyStatusLabelProps {
  status: CompanyStatus
  deletedAt?: string
}

function CompanyStatusLabel(props: CompanyStatusLabelProps) {
  const statusName = CompanyStatus[props.status]
  let daysLeft = null
  if (props.status === CompanyStatus.Archived && props.deletedAt) {
    const deletedAtDate = new Date(props.deletedAt)
    const deletionDate = addDays(deletedAtDate, 30)
    const now = new Date()
    daysLeft = differenceInDays(deletionDate, now)
  }

  if (daysLeft !== null) {
    return (
      <div className={classWithModifiers("company-status", kebabCase(statusName))}>
        {startCase(statusName)} ({daysLeft} days before deletion)
      </div>
    )
  }

  return (
    <div className={classWithModifiers("company-status", kebabCase(statusName))}>
      {startCase(statusName)}
    </div>
  )
}

export default CompanyStatusLabel
