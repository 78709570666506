import "./ErrorCover.scss"

import { ReactNode } from "react"

import { classWithModifiers } from "@/utils/common"

interface ErrorCoverProps {
  children: ReactNode

  size?: "small"
}

function ErrorCover(props: ErrorCoverProps) {
  return (
    <div className={classWithModifiers("error-cover", props.size)}>
      <div className="error-cover__container">
        {props.children}
      </div>
    </div>
  )
}

export default ErrorCover
